import React, {useContext} from "react";
import {ConfigContext} from "../../utils/config_context";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCog} from "@fortawesome/free-solid-svg-icons";

// Deliver the intended for size above whether its animated or not
// If not available, pick the largest animation available
// If no animation is available, pick the largest image available

const ExerciseAnimatedImage = (props) => {
    const {animate, classes, exercise, onClick, size} = props;
    const config = useContext(ConfigContext);

    const cog = <FontAwesomeIcon icon={faCog} size="2x" spin/>;

    if (!exercise?.preferred_medium_id || (['yt_clip', 'yt_short', 'yt_video'].includes(exercise?.preferred_medium_type))) return null;
    let image = null;

    if (size === 'sm' && !animate) {
        image = exercise.thumb_image;

    } else if (size === 'sm' && (exercise.thumb_animation || exercise.thumb_image)) {
        image = exercise.thumb_animation || exercise.thumb_image;

    } else if (size === 'md' && (exercise.modal_animation || exercise.modal_image)) {
        image = exercise.modal_animation || exercise.modal_image;

    } else if (size === 'lg' && (exercise.standard_animation || exercise.standard_image)) {
        image = exercise.standard_animation || exercise.standard_image;

    } else if (exercise.thumb_animation || exercise.modal_animation || exercise.standard_animation) {
        image = exercise.standard_animation || exercise.modal_animation || exercise.thumb_animation;

    } else if (exercise.thumb_image || exercise.modal_image || exercise.standard_image) {
        image = exercise.standard_image || exercise.modal_image || exercise.thumb_image;
    }

    if (image && config.s3Basepath) {
        const imagePath = image.match(/http/) ? image : `${config.s3Basepath}/${image}`;

        return (
            <img
                alt="animated exercise"
                className={`w-100 img-responsive ${classes}`}
                onClick={onClick}
                src={imagePath}
            />
        )
    } else {
        const yPadding = (size === 'sm') ? 'py-2' : 'py-5';
        return (
            <div className="d-flex justify-content-center align-self-center text-center"
                 onClick={onClick}>
                <div className={`${yPadding} loading-wrapper`}>
                    {cog}
                    <div>
                        Converting
                    </div>
                </div>
            </div>
        )
    }
};

export default ExerciseAnimatedImage;