import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons";

const QuestionIcon = (props) => {
    const {classes, onClick} = props;

    const questionIcon = <FontAwesomeIcon icon={faQuestionCircle} className="pe-1"/>;
    const allClasses = classes ? classes : 'ps-1 pt-1';

    return (
        <span className={allClasses} style={{color: 'blue', fontSize: '1.5rem'}} onClick={onClick}>
            {questionIcon}
        </span>
    )
};

export default QuestionIcon;
