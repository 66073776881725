import React, {useEffect, useState} from 'react';
import NumericFormInput from "../common/numeric_form_input";
import PerformControls from "../perform_controls";
import PerformUtils from "../../utils/perform_utils";
//import FitText from '@kennethormandy/react-fittext';
import 'react-alice-carousel/lib/alice-carousel.css';
import ExerciseGroupImageCarousel from "../exercises/exercise_group_image_carousel";
import {Button, Row} from "react-bootstrap";

export default function GroupExerciseModalRenderer(props) {
    const {
        active, currentWorkout, exercise, exerciseIndex, exercisePerform,
        mode, saveMode, setExercisePerform, workoutPerform
    } = props;

    // When invoked by ExerciseList (part of a workout), props.workoutPerform are manipulated and used as is.
    // They may be saved later when the whole workout is saved.

    // When invoked by TopList, props.workoutPerform are copied to state.localWorkoutPerform where
    // they may be manipulated and saved.

    const [isCustomizeActive, setIsCustomizeActive] = useState(false);
    const [groupSliderActiveIndex, setGroupSliderActiveIndex] = useState(0);
    const [sets, setSets] = useState(null);
    const [groupPerform, setGroupPerform] = useState(null);

    useEffect(() => {
        // Group exercise is customized if it has a rest or round property set
        if (exercisePerform?.exercises?.[groupSliderActiveIndex]) {
            const numSegmentTimingKeys = Object.keys(exercisePerform?.exercises?.[groupSliderActiveIndex])
                .map(k => ['rest', 'round'].includes(k)).length;
            setIsCustomizeActive(numSegmentTimingKeys > 0);
        } else {
            setIsCustomizeActive(false);
        }

        setSets(exercisePerform?.sets || (workoutPerform && PerformUtils.calcDuration('sets', workoutPerform)));

        if (workoutPerform) {
            setGroupPerform({
                sets: PerformUtils.calcDuration('sets', workoutPerform, exerciseIndex, groupSliderActiveIndex),
                round: PerformUtils.calcDuration('round', workoutPerform, exerciseIndex, groupSliderActiveIndex),
                rest: PerformUtils.calcDuration('rest', workoutPerform, exerciseIndex, groupSliderActiveIndex)
            });
        }

        return (() => {
            setSets(null);
            setGroupPerform(null);
        })

    }, [exerciseIndex, exercisePerform, groupSliderActiveIndex, setIsCustomizeActive, workoutPerform])

    const handleSetsChange = (value) => {
        handleUpdateGroupPerform({sets: value});
    };

    const handleUpdateGroupPerform = (updates) => {
        let newPerform = Object.assign({}, exercisePerform, updates);
        setExercisePerform({...newPerform});
    }

    const handleUpdateGroupSegmentPerform = (updates) => {
        let newPerform = Object.assign({}, exercisePerform);
        newPerform.exercises[groupSliderActiveIndex] = Object.assign({}, exercisePerform.exercises[groupSliderActiveIndex], updates);
        setExercisePerform({...newPerform})
    }

    const handleClearCustomize = () => {
        const newExercisePerform = Object.assign({}, exercisePerform,
            {exercises: Array(exercise.segments.length).fill({})});

        setExercisePerform(newExercisePerform);
    }

    const handleCustomize = () => {
        const defaultDurations = {
            round: PerformUtils.calcGroupSegmentDuration('round', workoutPerform, exercisePerform, groupSliderActiveIndex),
            rest: PerformUtils.calcGroupSegmentDuration('rest', workoutPerform, exercisePerform, groupSliderActiveIndex)
        }

        const newExercisePerform = Object.assign({}, exercisePerform,
            {exercises: Array(exercise.segments.length).fill(defaultDurations)});

        setExercisePerform(newExercisePerform);
    }

    const customizeButton = <div className="pt-1">{
        isCustomizeActive ?
            <Button size="sm" variant="primary" onClick={handleClearCustomize}>Clear
                Custom</Button>
            :
            <Button size="sm" variant="primary"
                    onClick={handleCustomize}>Customize</Button>
    }
    </div>

    if (exercise === null || exercise === undefined) return null;

    const showGroupTiming = currentWorkout?.timed && !isCustomizeActive;
    const readOnly = (mode === 'show') || (saveMode === 'none');

    return (
        <>
            {sets &&
                <Row>
                    <NumericFormInput
                        attribute='sets'
                        label='Group Sets'
                        readOnly={readOnly}
                        value={sets}
                        onChange={handleSetsChange}
                    />
                </Row>
            }
            {showGroupTiming &&
                <PerformControls
                    defaults={workoutPerform}
                    perform={exercisePerform}
                    readOnly={readOnly}
                    showRoundRest={true}
                    updatePerform={handleUpdateGroupPerform}
                />}
            <ExerciseGroupImageCarousel
                {...props}
                active={active}
                groupSliderActiveIndex={groupSliderActiveIndex}
                setGroupSliderActiveIndex={setGroupSliderActiveIndex}
                isGiphyView={true}
                showControls={true}
                customizeButton={customizeButton}
            />
            {isCustomizeActive &&
                <PerformControls
                    defaults={groupPerform}
                    perform={exercisePerform?.exercises?.[groupSliderActiveIndex]}
                    readOnly={readOnly}
                    showRoundRest={true}
                    updatePerform={handleUpdateGroupSegmentPerform}
                />}
        </>
    );
}
