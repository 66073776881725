import {Config} from '../utils/config';
import ReactGA from "react-ga4";

export const SwsAnalytics = {
    event,
    initialize,
    modalView
};

function event(params) {
    if (Config.get().gaDebug) {
        Config.debug(`SwsAnalytics: event: ${JSON.stringify(params)}`)
    }
    ReactGA.event(params);
}

function initialize(user) {
    ReactGA.initialize(Config.get().gaCode);
}

// ToDo:? Bring back modalView, or should the U/X just replace the page, or should we update the URL?
function modalView(name) {
    SwsAnalytics.event({
        category: `${name}`,
        action: 'Open'
    });
}