import React from 'react';
import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import Formatter from "../../utils/formatter";

const ProfileSection = (props) => {
    const {children, colParams, fieldName, onEdit} = props;

    const myColParams = colParams ? colParams : {span: 10, offset: 2};
    const pencil = <FontAwesomeIcon icon={faPencilAlt} className="pe-1"/>;

    const handleEditClick = (event) => {
        event.preventDefault();
        onEdit(fieldName);
    }

    const renderSubtitleEditable = () => {
        return (
            <Row className="mb-0">
                <Col xs={3}>
                    <h5>{Formatter.capitalizeFirst(fieldName)}</h5>
                </Col>
                <Col xs={{span: 3, offset: 6}}>
                    <Link href="#" onClick={handleEditClick}>
                        {pencil} Edit
                    </Link>
                </Col>
            </Row>
        )
    }

    return (
        <>
            {renderSubtitleEditable()}
            <Row className="mb-4" style={{background: 'white'}}>
                <Col xs={myColParams} style={{fontSize: '1rem'}}>
                    <div>
                        {children}
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default ProfileSection;