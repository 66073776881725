import React, { useCallback, useRef } from "react";
import { Col, Form } from "react-bootstrap";
import TextareaAutosize from 'react-textarea-autosize';

const WorkoutDescription = (props) => {
    const { handleChange, mode, myKey, readOnly, workout } = props;
    const style = { width: '100%', border: 'none' };
    const textareaRef = useRef(null);

    return (
        <Col>
            <Form.Group controlId="formBasicText1">
                {['edit', 'new'].includes(mode) &&
                    <Form.Label>
                        <div className="form-section">Description</div>
                    </Form.Label>
                }
                <TextareaAutosize
                    key={myKey}
                    className="textarea"
                    placeholder="Describe your workout here"
                    defaultValue={workout.description || ''}
                    onChange={handleChange}
                    name="description"
                    maxLength="5000"
                    style={style}
                    readOnly={readOnly}
                    ref={textareaRef}
                    wrap="soft"
                />
            </Form.Group>
        </Col>
    );
};

export default WorkoutDescription;