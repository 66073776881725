import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import React, {useContext} from "react";
import {Link} from 'react-router-dom';
import {ConfigContext} from "../../utils/config_context";
import ExerciseClipMedia from "./exercise_clip_media";
import {faCircleXmark} from '@fortawesome/free-solid-svg-icons';

const GroupExerciseList = (props) => {
    const {mode, segments, setSegments} = props
    const config = useContext(ConfigContext);

    const handleDeleteExercise = (index) => {
        const newSegments = [...segments];
        newSegments.splice(index, 1);

        setSegments(newSegments);
    };

    //ToDo: bring back FitText to the exercise title

    if (!segments) return null;
    const spinner = <div className="d-flex w-100 justify-content-center"><FontAwesomeIcon icon={faSpinner}
                                                                                          size="3x"></FontAwesomeIcon>
    </div>;

    const mapped_items = segments.map((e, index) => {
        let imageSrc = e.standard_animation || e.standard_image;
        const showOpengraph = e.clip?.id && config.isClipsEnabled;
        let image = null;

        if (showOpengraph) {
            image = <ExerciseClipMedia {...props}
                                       classes="w-100 img-responsive"
                                       clipMode="video"
                                       exercise={e}
                                       mode="show"
                                       playerShouldBePlaying={false}
            />

        } else if (imageSrc) {
            imageSrc = imageSrc.match(/http/) ? imageSrc : `${config.s3Basepath}/${imageSrc}`;
            image = <div className="mb-1">
                <img src={imageSrc}
                     className="img-responsive w-100"
                     loader={spinner}
                     alt="Exercise"/>
            </div>
        }

        let textLine = <Link to={`/exercises/${e.id}`}>{e.title}</Link>;

        if (mode === 'edit') {
            textLine =
                <>
                    {e.title}
                    <span className="ms-2">
                            <FontAwesomeIcon icon={faCircleXmark} color="red"
                                             onClick={() => handleDeleteExercise(index)}/>
                            </span>
                </>;
        }

        return (
            <div key={`${e.title}-group-item`}>
                {image}
                <div className="text-center mb-2">
                    <h5>
                        <div>
                            {textLine}
                        </div>
                    </h5>
                </div>
            </div>
        );
    });

    return (
        <>
            {mapped_items}
        </>
    );
}

export default GroupExerciseList;
