import React from 'react';
import {Link} from 'react-router-dom';

const AddExerciseListItem = (props) => {

    const handleClick = (event) => {
        event.preventDefault();
        const {handleWorkoutAddExercise} = props;
        handleWorkoutAddExercise();
    };

    let ts = Date.now();

    return (
        <Link key={`exercise-add-${ts}`} to='#' onClick={(event) => {
            handleClick(event)
        }}
              className="list-group-item flex-column align-middle add-exercise">
            <div className="d-flex w-100 py-2">
                    <span className="w-100 text-center">
                        Add Exercises
                    </span>
            </div>
        </Link>
    );
}

export default AddExerciseListItem;
