import React, { createContext, useContext, useEffect, useState } from "react";
import { ConfigContext } from "../utils/config_context";
import { Config } from "../utils/config";
import { UserService } from "../services/user_service";
import useSWR from "swr";
import { SwrUtils } from "../utils/swr_utils";
import { set } from "lodash";

export const UserContext = createContext("userContext");

const UserProvider = ({ children }) => {

    const config = useContext(ConfigContext);

    const defaultUser = {
        id: null,
        slug: null,
        is_admin: false,
        username: null,
        exercises: [],
        workouts: [],
        server: config.apiHost
    }

    const [user, setUser] = useState(defaultUser);
    const [currentWorkout, setCurrentWorkout] = useState(null);

    useEffect(() => {
        const userObject = localStorage.getItem('currentUser');
        if (userObject) {
            setUser(JSON.parse(userObject));
        }
    }, [setUser]);

    const { error: userError, isLoading: userIsLoading, mutate } =
        useSWR((config.apiBase && user?.id) ? `${config.apiBase}/users/${user.id}` : null,
            SwrUtils.authFetcher, {
            ...SwrUtils.stdOptions,
            onSuccess: (data) => {
                Config.debug(data?.user);
                setUser(data?.user);
            }
        });

    const currentOrLastWorkout = () => {
        if (currentWorkout?.id) {
            return currentWorkout;
        } else if (user?.lastWorkouts?.[0]?.id) {
            return user.lastWorkouts?.[0];
        } else {
            return null;
        }
    }

    const handleSetCurrentWorkout = (workout) => {
        if (workout) {
            // If workout doesn't exist in user.lastWorkouts, add it
            if (!user.lastWorkouts.find(w => w.id === workout.id)) {
                user.lastWorkouts.unshift(workout);
            }
        }

        setCurrentWorkout(workout);
    }

    const handleSetUser = (user) => {
        setUser(user);
        UserService.setUserValue(user); 
    }

    const resetUser = () => {
        setUser(localStorage.getItem('currentUser') || defaultUser);
    }

    /*
    useEffect(() => {
        if (user?.id) {
            console.log("Setting user in local storage");
            UserService.setUserValue(user);
        }
    }, [user.id]);
    */

    /* ToDo: load the current workout based on a local storage value */
    /* probably afer we move away from loading the big fat user object with attached workouts */

    /*
    const {} = useSWR((config.apiBase && currentWorkoutCoreId) ? `${config.apiBase}/workouts/${currentWorkoutCoreId}` : null,
    SwrUtils.authFetcher, {
        ...SwrUtils.stdOptions, onSuccess: (data) => {
            setCurrentWorkoutCore(data);
        }
    });
    */

    const value = {
        currentOrLastWorkout,
        currentWorkout,
        mutate,
        resetUser,
        setCurrentWorkout: handleSetCurrentWorkout,
        setUser: handleSetUser,
        user,
        userError,
        userIsLoading
    };

    return (
        <UserContext.Provider value={value}>
            {children}
        </UserContext.Provider>
    )
}

export default UserProvider;