import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons";

const DeleteIcon = (props) => {
    const {classes, onClick} = props;

    const deleteIcon = <FontAwesomeIcon icon={faTimesCircle}/>;

    const allClasses = classes ? classes : 'ps-2 pt-1';

    return (
        <span className={allClasses} style={{color: 'black', fontSize: '1.5rem'}} onClick={onClick}>
            {deleteIcon}
        </span>
    )
};

export default DeleteIcon;
