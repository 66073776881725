let FormDataUtils = {
    formDataToJson(formData)  {
        var object = {};
        formData.forEach((value, key) => {
            if (!object.hasOwnProperty(key)) {
                object[key] = value;
                return;
            }
            if (!Array.isArray(object[key])) {
                object[key] = [object[key]];
                return;
            }
            object[key].push(value);
        });
        return JSON.stringify(object);
    },

    formDataToObject(formData)  {
        var object = {};
        formData.forEach((value, key) => {
            if (!object.hasOwnProperty(key)) {
                object[key] = value;
                return;
            }
            if (!Array.isArray(object[key])) {
                object[key] = [object[key]];
                return;
            }
            object[key].push(value);
        });
        return object;
    }
};

export default FormDataUtils;

