import React, {useContext, useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import Workout from './workout';
import WorkoutPerform from './workout_perform';
import API from "../../utils/api";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStar} from "@fortawesome/free-solid-svg-icons";
import ObjectDefaults from "../../utils/object_defaults";
import * as Sentry from "@sentry/browser";
import {ConfigContext} from "../../utils/config_context";
import PerformUtils from "../../utils/perform_utils";
import {NavContext} from "../../providers/nav_provider";
import _ from "lodash";
import { UserContext } from '../../providers/user_provider';

var ExerciseIndexException = {};

export default function WorkoutWrapper(props) {

    const {mode, setUserWorkouts, userWorkouts} = props;
    const {user} = useContext(UserContext);

    const config = useContext(ConfigContext);
    let navigate = useNavigate();
    let {workoutId} = useParams();

    const [error, setError] = useState(false);
    // Exercises may be modified by workout.edit
    // they are kept separate from workout.exercises, so that the API doesn't need to be re-queried
    // if the user edits the exercise list, then cancels
    const [exercises, setExercises] = useState([]);
    const [isPerform, setIsPerform] = useState(false);
    // Workout object contains the read-only content of the workout, used for
    // viewing or performing of the workout
    const [workout, setWorkout] = useState(ObjectDefaults.workout());
    const [workoutImage, setWorkoutImage] = useState(ObjectDefaults.image());
    // WorkoutPerform is separate from workout.workoutPerform in case the user
    // makes localized modifications and wishes to discard them.
    // Should this instead be done with localStorage in case the user reloads the page but doesn't
    // want to lose their changes?
    //
    // For instance, if they are at the gym and lose network (a very common occurrence), you don't
    // want them to lose their changes.
    const [workoutPerform, setWorkoutPerform] = useState(ObjectDefaults.workoutPerform());
    const {dirty, setAlert, setDirty} = useContext(NavContext);

    // Todo: switch this to use useSwr
    useEffect(() => {
        const handleWorkoutData = (data) => {
            if (data.error) {
                return setError(data.error);
            } else {
                let newWorkoutPerform = data.perform;
                const newExercises = data.exercises;

                if (data.workout_type === 'timed') {
                    data.timed = true;
                }

                setDirty(false);
                setError(false);
                setWorkout(data);

                while (newExercises.length > newWorkoutPerform.exercises.length) {
                    newWorkoutPerform.exercises.push({});
                }

                if (data.workout_image) {
                    setWorkoutImage({
                        animationState: (data.workout_image.id) ? 'complete' : 'empty',
                        id: data.workout_image.id,
                        image: `${config.s3Basepath}/${data.workout_image.image}`,
                        src: `${config.s3Basepath}/${data.workout_image.src}`,
                        thumb: `${config.s3Basepath}/${data.workout_image.thumb}`
                    });
                }

                PerformUtils.sortSynced(newExercises, newWorkoutPerform);

                setExercises(newExercises);
                setWorkoutPerform(newWorkoutPerform);
            }
        };
        if (workoutId !== "new" && !_.isEmpty(workoutId)) {
            setError(false);
            API.get({path: `/workouts/${workoutId}`}).then(handleWorkoutData, handleWorkoutError);
        }
    }, [workoutId])

    const handleCopy = (event) => {
        event.preventDefault();

        if (!workout || (workout.id === null)) {
            return
        }

        API.post(`${config.apiBase}/workouts/${workout.id}/copy`, '')
            .then(result => {
                let newUserWorkouts = JSON.parse(JSON.stringify(userWorkouts));
                newUserWorkouts.push(result);

                setUserWorkouts(newUserWorkouts);

                setDirty(false);
                setWorkout(result);
                navigate(`/workouts/${result.id}/edit`);

            })
            .catch(error => {
                setError(error.message);
            });
    };

    const handleFeature = (event) => {
        event.preventDefault();

        const newIsFeatured = !workout.is_featured;

        // ToDo: make this able to handle workouts too
        API.post(`${config.apiBase}/workouts/${workout.id}/feature`,
            JSON.stringify({featured: newIsFeatured}))
            .then(result => {
                    const newWorkout = Object.assign({}, workout);
                    newWorkout.is_featured = newIsFeatured;
                    setWorkout(newWorkout);
                }
            )
            .catch(error => {
                setError(error.message);
            });
        return;
    };

    const handlePerformInit = () => {
        setIsPerform(true);
    };

    const handleReload = () => {
        navigate(-1);
        window.location.reload();
    }

    const handleSetExercises = (newExercises) => {
        setExercises(newExercises);
        setDirty(true);
    }

    const handleUpdateWorkoutImageState = (update) => {
        const newImageState = Object.assign({}, workoutImage, update);
        setWorkoutImage(newImageState);
    };


    const handleWorkoutError = (error) => {
        if (error) {
            return setError(error.message);
        }
    };

    const reorderSyncedExercises = () => {
        let newExercises = [];
        let newExercisePerforms = [];

        workoutPerform.exercises.forEach((exercisePerform, i) => {
            if (i >= exercises.length) throw ExerciseIndexException;
            if ((exercisePerform !== undefined) && (exercisePerform.start !== undefined) && (!exercises[i].is_group)) {
                newExercises.push(workout.exercises[i]);
                newExercisePerforms.push(exercisePerform);
            }
        });
        const newWorkoutPerform = Object.assign({}, workoutPerform);
        newWorkoutPerform['exercises'] = newExercisePerforms;

        setExercises(newExercises);
        setWorkoutPerform(newWorkoutPerform);
    }

    const renderPerformView = () => {
        if (!workoutPerform || !workout) return null;

        let myExercises = [];
        let myExercisePerforms = [];

        try {
            if (workout && exercises && workout.synced && workoutPerform) {
                workoutPerform.exercises.forEach((exercisePerform, i) => {
                    if (i >= exercises.length) throw ExerciseIndexException;
                    if ((exercisePerform !== undefined) && (exercisePerform.start !== undefined) && (!exercises[i].is_group)) {
                        myExercises.push(workout.exercises[i]);
                        myExercisePerforms.push(exercisePerform);
                    }
                });
            } else {
                myExercises = exercises;
                myExercisePerforms = workoutPerform.exercises;
            }
        } catch (e) {
            if (e !== ExerciseIndexException) throw e;
            Sentry.captureEvent(`workoutPerform mismatch for workout ${workout.id}`);
        }

        return (
            <WorkoutPerform {...props}
                            exercises={myExercises}
                            exercisePerforms={myExercisePerforms}
                            increment={1}
                            mode='perform'
                            navigate={navigate}
                            setAlert={setAlert}
                            setIsPerform={setIsPerform}
                            workout={workout}
                            workoutImage={workoutImage}
                            workoutPerform={workoutPerform}
            />
        );
    };

    const renderFeatureWorkout = () => {
        if (!workout) return null;

        const starClasses = workout.is_featured ? 'warning' : 'secondary';
        const star = <div className='icon-margin'><FontAwesomeIcon icon={faStar}/></div>;

        return (
            <Button variant={starClasses}
                    className="btn-sm"
                    onClick={(event) => handleFeature(event)}>
                {star}
            </Button>
        );
    };


    if ((!user) || (!workout)) {
        return null;
    }

    let innerMode = isPerform ? 'perform' : mode;

    return (
        <>
            {['show', 'new', 'edit'].includes(innerMode) &&
                <Workout
                    {...props}
                    dirty={dirty}
                    exercises={exercises}
                    handleCopy={handleCopy}
                    handlePerformInit={handlePerformInit}
                    reload={handleReload}
                    setDirty={setDirty}
                    setExercises={handleSetExercises}
                    setIsPerform={setIsPerform}
                    setWorkout={setWorkout}
                    setWorkoutPerform={setWorkoutPerform}
                    updateWorkoutImageState={handleUpdateWorkoutImageState}
                    workout={workout}
                    workoutImage={workoutImage}
                    workoutPerform={workoutPerform}
                />
            }
            {(innerMode === 'perform') && renderPerformView()}
        </>
    );

}