import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faList } from '@fortawesome/free-solid-svg-icons';

export default function GiphyButton(props) {
    const { classes, clickHandler, isGiphyView, showLabel } = props;

    const label = showLabel ? (isGiphyView ? 'List view' : 'Image view') : null;

    const icon = isGiphyView ? faList : faImage;

    return (
        <div className={classes} onClick={clickHandler}>
            {showLabel && <span>{label}</span>}
            <span><FontAwesomeIcon icon={icon}/></span>
        </div>
    )
};