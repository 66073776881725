import React, {useEffect, useState} from 'react';
import {Button, Modal} from 'react-bootstrap';
import API from "../../utils/api";
import {useLocation, useNavigate} from "react-router-dom";

export default function DynamicModal(props) {
    const [dynamicModalType, setDynamicModalType] = useState(null);
    const [loadingState, setLoadingState] = useState('init');
    const [content, setContent] = useState(null);
    const [path, setPath] = useState(null);
    const [title, setTitle] = useState(null);

    let navigate = useNavigate();
    let location = useLocation();

    // The use effect chain goes:
    //    location.hash => dynamicModalType (title, path)  => load

    useEffect(() => {
        switch (location.hash) {
            case "#youtube":
                setDynamicModalType('youtube');
                break;
            case "#terms":
                setDynamicModalType('terms');
                break;
            case /#.*/:
                setDynamicModalType('close')
                break;
            default:
                setDynamicModalType(null);
        }
    }, [location.hash])

    useEffect(() => {
        switch (dynamicModalType) {
            case 'terms':
                setTitle('Terms of Service');
                setPath('/tos');
                break;
            case 'youtube':
                setTitle('YouTubers: Welcome to FastfitGX');
                setPath('/youtube');
                break;
            case 'close':
                navigate({ pathname: location.pathname});
                setDynamicModalType(null);
                setPath(null);
                break;
            default:
                setContent(null);
                setLoadingState('init');
        }
    }, [dynamicModalType]);

    useEffect(() => {
        if (path) {
            setLoadingState('loading');
            API.getHtml({path: path})
                .then((data) => {
                    setContent(data);
                    setLoadingState('loaded');
                }).catch(err => {
                    setLoadingState('error');
                })
        }
    }, [path]);

    if (!content) return null;

    return (
        <Modal show={loadingState !== 'init'}
               animation={false}
               onClick={() => setDynamicModalType('close')}
               onHide={() => setDynamicModalType('close')}>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body id="tos-body">
                {(loadingState === 'error') && <div>Something went wrong ...</div>}

                {(loadingState === 'loading') && <div>Retrieving {title}...</div>}
                {(loadingState === 'loaded') &&
                <div dangerouslySetInnerHTML={{__html: content}}/>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => setDynamicModalType('close')}>Done</Button>
            </Modal.Footer>
        </Modal>
    );
}