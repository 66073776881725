import React from "react";
import {Col} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faYoutube} from "@fortawesome/free-brands-svg-icons";
import {faClipboardList, faLink, faStopwatch} from "@fortawesome/free-solid-svg-icons";

const WorkoutIcon = (props) => {
    const {classes, workout, workoutPerform} = props;

    const ytIcon = <FontAwesomeIcon icon={faYoutube} color="red" className="fa-lg"/>;
    const stopwatchIcon = <FontAwesomeIcon icon={faStopwatch} color="black" className="fa-lg"/>;
    const clipboardIcon = <FontAwesomeIcon icon={faClipboardList} color="#666" className="fa-lg"/>;
    const linkedIcon = <FontAwesomeIcon icon={faLink} className="fa-lg"/>;

    const showVideoIcon = ((workout.workout_type === 'you_tube_video') ||
        (workout.workout_type === 'you_tube_playlist') ||
        workout.precursor_link || workout.main_link ||
        workout.precursor_link || workout.warmup_link || workout.cooldown_link);
    const showLinkedVideoIcon = (workout.workout_type === 'you_tube_video_synced' || workout.workout_type === 'you_tube_playlist_synced');

    return (
        <Col xs={2} className="align-self-center">
            {(workout.workout_type === 'manual') && <span className={classes}> {clipboardIcon} </span> }
            {(workout.workout_type === 'timed') && <span className={classes}> {stopwatchIcon} </span> }
            {showVideoIcon && <span className={classes}>{ytIcon}</span> }
            {showLinkedVideoIcon && <span className={classes}> {ytIcon}{linkedIcon} </span> }
        </Col>
    );
};

export default WorkoutIcon;