import React, {useEffect, useState} from 'react';
import {Row, Col, Form} from 'react-bootstrap';
import Formatter from '../utils/formatter';
import NumericFormInput from './common/numeric_form_input';

const renderOption = (val) => {
    const formattedVal = Formatter.toHHMMSS(val);

    return (
        <option key={`option-${val}`} value={val}>{formattedVal}</option>
    );
};


// The desired behavior for when there are no default workout controls is to display nothing.

const PerformControls = (props) => {
    const {defaults, perform, readOnly, showRoundRest, showSets, updatePerform} = props;

    const [sets, setSets] = useState(1);
    const [round, setRound] = useState(1);
    const [rest, setRest] = useState(0);

    useEffect(() => {
        setSets(perform?.sets || defaults?.sets);
        setRound(perform?.round || defaults?.round);
        setRest((perform?.rest !== undefined) ? perform?.rest : defaults?.rest);

        return (() => {
            setSets(1);
            setRound(1);
            setRest(0);
        })

    }, [perform, defaults])

    const handleRestChange = (event) => {
        event.preventDefault();
        if (updatePerform) updatePerform({rest: Number(event.target.value)});
    };

    const handleRoundChange = (event) => {
        event.preventDefault();
        if (updatePerform) updatePerform({round: Number(event.target.value)});
    };

    const handleSetsChange = (value) => {
        if (updatePerform) updatePerform({sets: value});
    };

    // Since this is for APPENDING exercises to a workout, we don't need to be able to
    // edit ANYTHING and EVERYTHING, just the next thing that will be inserted at the
    // end.

    // In fact, we don't need to be able to manipulate the perform object here either,
    // just insert overrides where appropriate

    const labelClasses = `mt-2 h6 ${readOnly ? 'text-muted' : ''}`;

    if (!perform?.sets) return null;

    return (
        <div className="pt-2">
            {showSets &&
                <Row>
                    <NumericFormInput className="form-control"
                                      label="Sets"
                                      style={{fontSize: '1rem'}}
                                      readOnly={readOnly}
                                      min={1}
                                      max={100}
                                      value={sets}
                                      onChange={(value) => {
                                          handleSetsChange(value)
                                      }}/>
                </Row>
            }

            {showRoundRest &&
                <Row>
                    <Col xs={2}>
                        <Form.Label className={labelClasses}>
                            Round
                        </Form.Label>
                    </Col>
                    <Col xs={4} sm={3}>
                        <Form.Control as="select"
                                      disabled={readOnly}
                                      readOnly={readOnly}
                                      value={round}
                                      onChange={(event) => {
                                          handleRoundChange(event);
                                      }}>
                            {
                                [5, 10, 15, 20, 30, 45, 60, 75, 90, 120, 180].map((val) => {
                                    return renderOption(val);
                                })
                            }
                        </Form.Control>
                    </Col>
                    <Col xs={2}>
                        <Form.Label className={`${labelClasses} float-right`}>Rest</Form.Label>
                    </Col>
                    <Col xs={4} sm={3}>
                        <Form.Control as="select"
                                      disabled={readOnly}
                                      readOnly={readOnly}
                                      className="py-1"
                                      value={rest}
                                      onChange={(event) => {
                                          handleRestChange(event);
                                      }}>
                            {[0, 5, 10, 15, 30, 45, 60].map((val) => {
                                return renderOption(val);
                            })}
                        </Form.Control>
                    </Col>
                </Row>
            }
        </div>
    );
}

export default PerformControls;
