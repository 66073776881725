import React, {useContext, useEffect, useState} from 'react';
import {ConfigContext} from "../../utils/config_context";
import {useForm, useFormState} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {Container, Form} from 'react-bootstrap';
import * as Yup from 'yup';

import {UserService} from '../../services/user_service';
import {Button} from "react-bootstrap";
import {yupResolver} from "@hookform/resolvers/yup";
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import ForgotPasswordModal from "../modals/forgot_password_modal";
import HeartbeatImg from "../common/heartbeat_img";
import {NavContext} from "../../providers/nav_provider";
import {UserContext} from "../../providers/user_provider";

export default function LoginPage(props) {
    const {setUser, user} = useContext(UserContext);
    const {afterLoginUrl} = useContext(NavContext);

    const config = useContext(ConfigContext);
    let navigate = useNavigate();

    useEffect(() => {
        if (user.id && UserService.authToken) {
            if (afterLoginUrl) {
                navigate(afterLoginUrl);
            } else {
                UserService.handleUserLoggedIn(user, navigate);
            }
        }
    });

    const [serverError, setServerError] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);

    const {executeRecaptcha} = useGoogleReCaptcha();

    const handleRegister = () => {
        navigate('/register');
    }

    const handleReCaptchaVerify = async (data) => {
        if (!config.recaptchaEnabled || !config.recaptchaSiteKey) {
            let newData = {
                user: data,
                'recaptchaToken': ''
            }
            submitForm(newData);
        } else if (!executeRecaptcha) {
            setServerError('Recaptcha failure');
        } else {
            const token = await executeRecaptcha('login');

            let newData = {
                user: data,
                'recaptchaToken': token
            }
            submitForm(newData);
        }
    };

    const submitForm = (data) => {
        setSubmitting(true);
        setServerError(null);
        UserService.login(data)
            .then(
                (data) => {
                    if (data.errors) {
                        setSubmitting(false);
                        setServerError(data.errors.join('. '));
                    } else if (data.user) {
                        setUser(data.user);
                        setSubmitting(false);
                        // The useEffect will handle forwarding after the appState is updated
                    }
                }
            )
            .catch(err => {
                    setSubmitting(false);
                    setServerError(err.message)
                }
            );
    }

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required('Email is required')
            .max(255)
            .email('Must be a valid email'),
        password: Yup.string()
            .required('Password is required')
            .min(6, 'Password must be at least 6 characters')
            .max(128, 'Password must not exceed 128 characters')
    });

    const {
        control,
        register,
        handleSubmit,
        formState: {errors}
    } = useForm({
        defaultValues: {
            email: '',
            password: ''
        },
        mode: 'onBlur',
        resolver: yupResolver(validationSchema),
        reValidateMode: 'onChange'
    });

    const {dirtyFields} = useFormState({control});

    return (
        <>
            <Container className="vw-100 vh-100 d-flex justify-content-center align-items-center">
                <div className="d-flex justify-content-center">
                    <div className="login-form">
                        <div className="text-center mb-4">
                            <img alt="fastfit_logo" style={{width: '10rem'}}
                                 src="/assets/images/fastfit_gx_watermark_source1.png"/>
                        </div>
                        <Form id="login-form" onSubmit={handleSubmit(handleReCaptchaVerify)}>
                            <Form.Group>
                                <label>Email</label>
                                <input
                                    autoCapitalize='off'
                                    name="email"
                                    type="text"
                                    {...register('email')}
                                    className={`form-control ${(errors.email && dirtyFields.email) ? 'is-invalid' : ''}`}
                                />
                                <div className="invalid-feedback">{dirtyFields.email ? errors.email?.message : ''}</div>
                            </Form.Group>
                            <Form.Group>
                                <label>Password</label>
                                <input
                                    autoCapitalize='off'
                                    name="password"
                                    type="text"
                                    {...register('password')}
                                    className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                                />
                                <div className="invalid-feedback">{errors.password?.message}</div>
                            </Form.Group>
                            {serverError &&
                            <Form.Group className="mt-2">
                                <div className={'alert alert-danger'}>{serverError}</div>
                            </Form.Group>
                            }
                            {submitting &&
                            <Form.Group className="d-flex my-0 w-100 mt-2">
                                <HeartbeatImg/>
                            </Form.Group>
                            }
                            <div className="form-group d-flex my-0 w-100 justify-content-between mt-4">
                                <Button onClick={handleRegister}
                                        className="btn btn-secondary float-right">
                                    Register
                                </Button>
                                <Button type="submit" className="btn btn-primary">
                                    Login
                                </Button>
                            </div>
                            <Form.Group className="d-flex my-0 w-100 mt-2">
                                <div onClick={() => setShowForgotPasswordModal(true)}>Forgot your password?</div>
                            </Form.Group>
                        </Form>
                    </div>
                </div>
            </Container>
            <ForgotPasswordModal hide={() => setShowForgotPasswordModal(false)}
                                 show={showForgotPasswordModal}
                                 {...props}/>
        </>
    )
};