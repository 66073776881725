import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCloudUploadAlt} from "@fortawesome/free-solid-svg-icons";

const ImageUploadObject = (props) => {
    const {imageObject, mode} = props;
    let showDisclaimer = false;

    let imageSource = imageObject.src ? imageObject.src : '';

    if (imageObject.tempUploadFile) {
        imageSource = imageObject.tempUploadFile;
        showDisclaimer = (mode === 'edit');
    }

    const cloudUpload = <FontAwesomeIcon icon={faCloudUploadAlt} size="4x" className="h-100"/>;

    return (
        <>
            {imageObject.animationState === 'empty' &&
            <img className="animated-image" style={{}} alt="animated exercise" src={imageSource}/>
            }
            {imageObject.animationState === 'uploading' &&
            <div className="fadeInAndOut text-center w-100 upload-image">
                {cloudUpload}
            </div>
            }
            {(imageObject.animationState === 'complete') &&
            <div className="d-flex justify-content-center bg-dark">
                <img className="image-preview" alt="animated exercise" src={imageSource}/>
            </div>
            }
            {showDisclaimer &&
            <div>If image is upside-down, it will be fixed upon conversion</div>
            }
        </>
    )
};

export default ImageUploadObject;
