import {UserService} from '../services/user_service';
import {Config} from "./config";
import AuthenticationError from "../errors/authentication_error";

let HttpTools = {
    async handleResponse(response, saveJwt) {
        if ([401, 403].indexOf(response.status) !== -1) {
            throw new AuthenticationError(response);
        }

        const data = await response.json();

        if (response.ok && !data.errors) {
            if (saveJwt) {
                let headers = response.headers;
                let token = headers.get('Authorization');
                if (token) {
                    localStorage.setItem('jwt_token', token);
                }
            }

            if (data.user) {
                const userObject = {
                    id: data?.user?.id,
                    slug: data?.user?.slug,
                    server: Config.get().apiHost
                }
                UserService.setUserValue(userObject);
            }
        }

        return data;
    }
}

export default HttpTools;