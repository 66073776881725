import React from 'react';
import Formatter from "../../utils/formatter";

const ExternalMediaItem = (props) => {
    const { handleMediaClick, index, item } = props;

    let medThumb = item.thumb;

    if (item.og_data?.youtube_video?.video_id) {
        medThumb = `https://i.ytimg.com/vi/${item.og_data?.youtube_video?.video_id}/mqdefault.jpg`;
    }

    const handleImageError = (event) => {
        event.target.src = "/assets/images/gears.gif";
        event.onerror = null
    }

    return (
        <div className="cursor-pointer" onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            handleMediaClick(index);
        }}>
            <div className="card">
                <img className='card-img-top' alt="animated exercise" src={medThumb}
                    onError={handleImageError} />
                <div className="card_body">
                        <div className="card-text px-2">
                        {item.alias || item.og_data?.title}
                    </div>
                        <div className="card-text px-2 float-end">
                        {item.live && <span>Live Stream</span>}
                        {!item.live && !item.is_playlist && Formatter.toHHMMSS(item.duration_sec)}
                        {item.is_playlist && item.length &&
                            <span>Playlist: {item.length} videos</span>
                        }
                    </div>
                </div>
            </div>
        </div>
    );

    /*
    return (
        <a href="#" className="list-group-item list-group-item-action workout-list-item"
           onClick={(event) => {
               event.preventDefault();
               handleMediaClick(index);
           }}>
            <Col xs={12} sm={6} md={4}>
                <img className='media-img img-fluid' alt="animated exercise" src={item.thumb}/>
                <div>
                    {item.title || item.og_data?.title}
                </div>
            </Col>
        </a>
    );

     */
}

export default ExternalMediaItem;
