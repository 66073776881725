import {UserService} from "../services/user_service";
import RecordNotFoundError from "../errors/record_not_found_error";
import {Config} from "./config";
import AuthenticationError from "../errors/authentication_error";

const stdOptions = {
    refreshWhenOffline: Config.get().swrRetryOffline === true,
    revalidateIfStale: true,
    revalidateOnFocus: false,
    use: [logger],
    shouldRetryOnError: (err) => {
        if (err instanceof RecordNotFoundError) {
            return false;
        } else if (err instanceof AuthenticationError) {
            return false;
        } else {
            return true;
        }
    }
};

// use: [logger]
//onError: (err, key) => console.log(`received onError: ${err.message}, ${key}`)

export const SwrUtils = {
    authFetcher,
    stdOptions
};


function logger(useSWRNext) {
    return (key, fetcher, config) => {
        // Add logger to the original fetcher.
        const extendedFetcher = (...args) => {
            //Config.debug('SWR Request:', key)
            return fetcher(...args)
        }

        // Execute the hook with the new fetcher.
        return useSWRNext(key, extendedFetcher, config)
    }
}

async function authFetcher(url) {
    const authToken = UserService.authToken;

    const response = await fetch(url, {
        headers: {authorization: authToken}
    });

    if (response.status === 401) {
        throw new Error("Unauthorized");
    }

    // How to cancel the auto-retry of these request types?
    if (response.status === 404) {
        throw new RecordNotFoundError();
    }

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
}
