import React, { useContext } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import ExerciseListItem from './exercise_list_item';
import AddExerciseListItem from './add_exercise_list_item';
import { UserContext } from "../../providers/user_provider";

export default function ExerciseList(props) {
    const {
        classes, handleWorkoutAddExercise, highlightExerciseIndex,
        isGiphyView, exercises, listMode, mainPlayerSecs, mode, 
        showUnsyncedOnly, workout, workoutPerform
    } = props;

    const { user } = useContext(UserContext);

    const loadItems = () => {
        return exercises;
    };

    let myClasses = classes;
    let start = undefined;
    let hidden = undefined;

    const extraStyles = (listMode === 'link') ? { maxHeight: '50vh', overflowY: 'scroll' } : {};

    const mapped_items = exercises.map((item, i) => {
        if (workout.synced) {
            start = (workoutPerform && workoutPerform.exercises && workoutPerform.exercises[i]
                && workoutPerform.exercises[i].start !== undefined) ? parseFloat(workoutPerform.exercises[i].start) : undefined;
            myClasses = '';

            if ((start !== undefined) && isNaN(start) && ((start === 0) || (mainPlayerSecs >= start))) {

                const nextStart = ((i < (exercises.length - 1)) && workoutPerform.exercises[i + 1] && workoutPerform.exercises[i + 1].start) ?
                    parseFloat(workoutPerform.exercises[i + 1].start) : undefined;
                if ((mainPlayerSecs < nextStart) || !nextStart) {
                    myClasses = 'highlighted';
                }
            }

            hidden = showUnsyncedOnly && (start !== undefined) && (listMode === 'link');
            myClasses = (i === highlightExerciseIndex) ? 'highlighted' : '';
        }

        return (
            <ExerciseListItem
                {...props}
                classes={myClasses}
                exercise={item}
                hidden={workout?.synced && hidden}
                index={i}
                isSynced={workout?.synced && (start !== undefined)}
                key={`eli-${i}`}
                showAuthor={true}
                showWorkoutAttrs={true}
                showSetSyncButton={workout?.synced && (mode !== 'perform')} />
        )
    });

    const innerScrollClasses = isGiphyView ? 'row no-gutters' : 'items';

    if (user && user.id && (user.is_admin || (workout.username === user.username)) && (handleWorkoutAddExercise !== undefined)) {
        mapped_items.push(
            <AddExerciseListItem {...props} />
        )
    }

    return (
        <div className={`${classes} exercises`} style={extraStyles}>
            <InfiniteScroll
                className="list-scrollable pb-sm-3"
                dataLength={mapped_items.length}
                hasMore={false}
                loader={<div className="loader" key="elist-loader">Loading ...</div>}
                next={loadItems}
            >
                <div className={innerScrollClasses}>
                    {mapped_items}
                </div>
            </InfiniteScroll>
        </div>
    );
}
