import {Button, Col, Form, Row} from "react-bootstrap";
import React from "react";

const NumericFormInput = (props) => {
    const {label, onChange, max, min, readOnly, value} = props;
    const myMax = max || 100;
    const myMin = (min === undefined) ? 0 : min;

    const labelClasses = `mt-2 ${readOnly ? 'text-muted' : ''}`;
    const buttonClasses = readOnly ? 'btn-secondary py-1' : 'success py-1';

    return (
        <Col xs="auto">
            <Form.Group controlId={label}>
                <Row>
                    <Col xs={6}>
                        <Form.Label className={`${labelClasses} float-right`}>
                            {label}
                        </Form.Label>
                    </Col>

                    <Col xs={6}>
                        <div className="input-group">
                            <div className={readOnly ? 'px-4' : ''}>
                                {!readOnly &&
                                    <Button className={buttonClasses}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                if (!readOnly && (value > min)) {
                                                    onChange(value - 1);
                                                }
                                            }}>-</Button>
                                }
                            </div>
                            <input className="form-control text-center py-1"
                                   readOnly={readOnly}
                                   min={myMin}
                                   max={myMax}
                                   pattern="[0-9]*"
                                   value={value}
                                   onChange={(e) => {
                                       e.preventDefault();
                                       if (e.target.validity.valid) {
                                           onChange(e.target.value);
                                       }
                                   }}/>
                            <div>
                                {!readOnly &&
                                    <Button className={buttonClasses}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                if (!readOnly && (value < max)) {
                                                    onChange(value + 1);
                                                }
                                            }}>+</Button>
                                }
                            </div>
                        </div>
                    </Col>
                </Row>
            </Form.Group>
        </Col>
    );
};

export default NumericFormInput;
