import React from 'react';
import {Row, Col, Form} from 'react-bootstrap';
import Formatter from '../../utils/formatter';

class ConfigTimedWorkoutControls extends React.Component {

    handleRestDurationChange = (event) => {
        const {updateConfigWorkoutModalState, workoutPerform} = this.props;

        let newWorkoutPerform = Object.assign({}, workoutPerform);
        newWorkoutPerform.rest = Number(event.target.value);
        updateConfigWorkoutModalState({workoutPerform: newWorkoutPerform, dirty: true})
    };

    handleRoundDurationChange = (event) => {
        const {updateConfigWorkoutModalState, workoutPerform} = this.props;

        let newWorkoutPerform = Object.assign({}, workoutPerform);
        newWorkoutPerform.round = Number(event.target.value);
        updateConfigWorkoutModalState({workoutPerform: newWorkoutPerform, dirty: true})
    };

    handleToggleShowTitle = () => {
        const {updateConfigWorkoutModalState, workoutPerform} = this.props;

        let newWorkoutPerform = Object.assign({}, workoutPerform);
        newWorkoutPerform.showTitle = !workoutPerform.showTitle;
        updateConfigWorkoutModalState({workoutPerform: newWorkoutPerform, dirty: true})
    };

    handleToggleShowCountdown = () => {
        const {updateConfigWorkoutModalState, workoutPerform} = this.props;

        let newWorkoutPerform = Object.assign({}, workoutPerform);
        newWorkoutPerform.showCountdown = !workoutPerform.showCountdown;
        updateConfigWorkoutModalState({workoutPerform: newWorkoutPerform, dirty: true})
    };

    handleTogglePlaySounds = () => {
        const {playSounds, updateConfigWorkoutModalState} = this.props;

        updateConfigWorkoutModalState({playSounds: !playSounds});
    };

    handleTogglePlayMidpoint = () => {
        const {updateConfigWorkoutModalState, workoutPerform} = this.props;

        let newWorkoutPerform = Object.assign({}, workoutPerform);
        newWorkoutPerform.playMidpoint = !workoutPerform.playMidpoint;
        updateConfigWorkoutModalState({workoutPerform: newWorkoutPerform, dirty: true})
    };

    render = () => {
        const {playSounds, workoutPerform} = this.props;

        if (!workoutPerform) return null;

        return (
            <>
                {false &&
                <>
                    <Form.Group controlId="formBasicCheckbox1">
                        <Form.Check type="checkbox"
                                    label="Show Countdown"
                                    onChange={() => {
                                        this.handleToggleShowCountdown()
                                    }}
                                    checked={workoutPerform.showCountdown}/>
                    </Form.Group>
                    <Form.Group controlId="formBasicCheckbox2">
                        <Form.Check
                            type="checkbox"
                            label="Show Title"
                            onChange={() => {
                                this.handleToggleShowTitle()
                            }}
                            checked={workoutPerform.showTitle}
                        />
                    </Form.Group>
                </>
                }
                <Form.Group controlId="formBasicCheckbox3">
                    <div className="custom-control custom-switch">
                        <input type="checkbox" checked={playSounds}
                               onChange={() => {
                                   this.handleTogglePlaySounds()
                               }}
                               className="custom-control-input"
                               id="customSwitch1"/>
                        <label className="custom-control-label" htmlFor="customSwitch1">
                            Play Bells
                        </label>
                    </div>
                </Form.Group>
                {false && workoutPerform.playSounds &&
                <Form.Group controlId="formBasicCheckbox4">
                    <Form.Check
                        type="checkbox"
                        label="Play midpoint"
                        onChange={() => {
                            this.handleTogglePlayMidpoint()
                        }}
                        checked={workoutPerform.playMidpoint}
                    />
                </Form.Group>
                }
                {false && workoutPerform.timed &&
                <>
                    <Form.Group
                        controlId="formBasicText1">
                        <Row>
                            <Col xs={8}>
                                <Form.Label>Round Duration</Form.Label>
                            </Col>
                            <Col xs={4}>
                                <Form.Control as="select"
                                              defaultValue={workoutPerform.round || ''}
                                              onChange={(event) => {
                                                  this.handleRoundDurationChange(event)
                                              }}>
                                    {
                                        [5, 10, 15, 20, 30, 45, 60, 75, 90].map((val) => {
                                            return this.renderOptions(val);
                                        })
                                    }
                                </Form.Control>
                            </Col>
                        </Row>
                    </Form.Group>
                    <Form.Group controlId="formBasicText2">
                        <Row>
                            <Col xs={8}>
                                <Form.Label>Rest Duration</Form.Label>
                            </Col>
                            <Col xs={4}>
                                <Form.Control as="select"
                                              defaultValue={workoutPerform.rest || ''}
                                              onChange={(event) => {
                                                  this.handleRestDurationChange(event)
                                              }}>
                                    {[0, 5, 10, 15, 30, 45].map((val) => {
                                        return this.renderOptions(val);
                                    })}
                                </Form.Control>
                            </Col>
                        </Row>
                    </Form.Group>
                </>
                }
            </>
        );
    };

    renderOptions = (val) => {
        const formattedVal = Formatter.toHHMMSS(val);
        return (
            <option key={`option-${val}`} value={val}>{formattedVal}</option>
        );
    }
};

export default ConfigTimedWorkoutControls;
