import React, { useContext } from 'react';
import { Button, NavDropdown } from 'react-bootstrap';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle, faClipboardList, faPersonRunning } from "@fortawesome/free-solid-svg-icons";
import Icon from '@mdi/react';
import { mdiMultimedia } from '@mdi/js';
//import VitruManIcon from '../common/vitru_man_icon';
import { UserService } from '../../services/user_service';
import { ConfigContext } from "../../utils/config_context";
import { NavContext } from "../../providers/nav_provider";
import DynamicModal from "../modals/dynamic_modal";
import TopNavLinkWrapper from './top_nav_link_wrapper';
import NavWrapper from './nav_wrapper';
import { UserContext } from '../../providers/user_provider';

const TopNavbar = (props) => {
    const { renderOption } = props;
    const {user, resetUser} = useContext(UserContext);
    const config = useContext(ConfigContext);
    let navigate = useNavigate();
    let location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const userSlug = queryParams.get('user_slug');

    const { dirty, setAlert } = useContext(NavContext);

    const handleAlertConfirm = () => {
        navigate(-1);
    }

    const handleBrandClick = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (dirty) {
            setAlert({
                title: null, message: 'Unsaved changes will be lost', show: true,
                confirmFn: () => handleAlertConfirm, confirmLabel: 'Yes', cancelLabel: 'No'
            });
        } else {
            navigate('/');
        }
    };

    function handleNavSelected(event) {
        switch (event) {
            case "my-exercises":
                navigate(`/exercises/search?user_slug=${user.slug}`)
                break;
            case "my-workouts":
                navigate(`/workouts/search?user_slug=${user.slug}`)
                break;
            case "my-media":
                navigate(`/external_media/search?user_slug=${user.slug}`)
                break;
            case "register":
                window.location.href = '/register';
                break;
            case "profile":
                navigate(`/users/${user.slug}`);
                break;
            case "logout":
                UserService.logout();
                resetUser();
                navigate('/');
                break;
            case "login":
                window.location.href = '/login';
                break;
            case "userManual":
                window.open(config.userManualLink, '_blank');
                break;
            case "admin":
                navigate('/admin');
                break;
            case "tos":
                navigate(`${location.pathname}#terms`);
                break;
            default:
                break;
        }
    }

    function renderNavItem(label, link, symbol) {
        let fullLink = (userSlug ? `${link}?user_slug=${userSlug}` : link);

        return (
            <TopNavLinkWrapper link={fullLink}>
                <div>
                    <div className="d-flex justify-content-center"
                        style={{ lineHeight: '1.5rem', fontSize: '1.5rem' }}>
                        <FontAwesomeIcon icon={symbol} className="card-img-top fa-fw" />
                    </div>
                    <div className="d-flex justify-content-center"
                        style={{ lineHeight: '1rem' }}>
                        <span style={{ fontSize: '.7rem' }}>
                            {label}
                        </span>
                    </div>
                </div>
            </TopNavLinkWrapper>
        );
    }

    function renderMediaNavItem(label, link, symbol) {
        let fullLink = (userSlug ? `${link}?user_slug=${userSlug}` : link);

        return (
            <TopNavLinkWrapper link={fullLink}>
                <div style={{ lineHeight: '1.5rem', fontSize: '1.5rem' }}>
                    <Icon path={symbol}
                        title="media"
                        size={1}
                        className="card-img-top" />
                </div>
                <div className="d-flex justify-content-center"
                    style={{ lineHeight: '1rem' }}>
                    <span style={{ fontSize: '.7rem' }}>
                        {label}
                    </span>
                </div>
            </TopNavLinkWrapper>
        );
    }

    function renderProfileMenu() {
        const profile = <div style={{ fontSize: '1.5rem', lineHeight: '1rem' }}><FontAwesomeIcon icon={faUserCircle} />
        </div>;

        let menuItems = '';

        if (user && user.id) {
            menuItems = profileMenuLoggedIn();
        } else {
            menuItems = profileMenuLoggedOut();
        }

        return (
            <li className="nav-item" style={{ marginRight: '-.5rem' }}>
                <NavDropdown title={profile} id="profile-dropdown">
                    {menuItems}
                </NavDropdown>
            </li>
        );
    }

    function profileMenuLoggedOut() {
        return (
            <>
                <NavDropdown.Item eventKey="register">
                    Register
                </NavDropdown.Item>
                <NavDropdown.Item eventKey="login">
                    Login
                </NavDropdown.Item>
            </>
        );
    }

    function profileMenuLoggedIn() {
        return (
            <>
                <NavDropdown.Item className="font-weight-bold text-center">
                    {user.username}
                </NavDropdown.Item>
                <NavDropdown.Item eventKey="profile">
                    My Profile
                </NavDropdown.Item>
                <NavDropdown.Item eventKey="my-workouts">
                    My Workouts
                </NavDropdown.Item>
                <NavDropdown.Item eventKey="my-exercises">
                    My Exercises
                </NavDropdown.Item>
                <NavDropdown.Item eventKey="my-media">
                    My Media
                </NavDropdown.Item>
                <NavDropdown.Item eventKey="userManual">
                    User Manual
                </NavDropdown.Item>
                {user?.is_admin &&
                    <NavDropdown.Item eventKey="admin">
                        Admin
                    </NavDropdown.Item>
                }
                <NavDropdown.Item eventKey="tos">
                    Terms of Service
                </NavDropdown.Item>
                <NavDropdown.Item eventKey="logout">
                    Logout
                </NavDropdown.Item>
            </>
        );
    }

    return (
        <>
            <NavWrapper onSelect={handleNavSelected}>
                <Button className="navbar-toggler" type="button" data-toggle="collapse"
                    data-target="#top-navbar" aria-controls="top-navbar" aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </Button>
                <div className="collapse navbar-collapse" id="top-navbar">
                    <ul className="navbar-nav me-auto w-100 justify-content-between">
                        <TopNavLinkWrapper link='/'>
                            <button className="ff-brand pb-2 btn-borderless" onClick={handleBrandClick}>
                                <div className="logo">
                                    <img alt="fastfit_logo" src="/assets/images/fastfit_gx_logo_black.png" />
                                </div>
                            </button>
                        </TopNavLinkWrapper>
                        {renderNavItem('Workouts', '/workouts/search', faClipboardList)}
                        {renderOption?.()}
                        {renderNavItem('Exercises', '/exercises/search', faPersonRunning)}
                        {renderMediaNavItem('Media', '/external_media/search', mdiMultimedia)}
                        {renderProfileMenu()}
                    </ul>
                </div>
            </NavWrapper>
            <DynamicModal />
            <Outlet />
        </>
    )
}

export default TopNavbar;
