import React, { useContext, useEffect, useRef, useState } from 'react';
import SwsVideoPlayer from "../players/sws_video_player";
import API from "../../utils/api";
import { ConfigContext } from "../../utils/config_context";
import { Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import _ from "lodash";

export default function ExerciseClipMedia(props) {
    const { classes, clipMode, exercise, handleClearExerciseMedium, handleExerciseClick, handleExerciseData, index, mode,
        preloadedComponent} = props;
    const myClasses = classes ? classes : 'image-preview';

    const config = useContext(ConfigContext);

    const [opengraphImageUrl, setOpengraphImageUrl] = useState("");
    const [imageLoaded, setImageLoaded] = useState(false);
    const [opengraphError, setOpengraphError] = useState(null);
    const [opengraphLoadingMode, setOpengraphLoadingMode] = useState(null); // null, loading, loaded, error

    useEffect(() => {
        if (exercise?.clip?.id && exercise?.clip?.video_url) {
            setOpengraphImageUrl(exercise.clip.video_url);
            setOpengraphLoadingMode('loaded');
        }

        return (() => {
            setOpengraphImageUrl("");
            setImageLoaded(false);
            setOpengraphError(null);
            setOpengraphLoadingMode(null);
        })
    }, [exercise?.clip])

    useEffect(() => {
        if (opengraphLoadingMode === 'loading') {
            const og_data = {
                og_url: opengraphImageUrl,
                check_yt: true
            }

            API.post(`${config.apiBase}/opengraph`, JSON.stringify(og_data))
                .then((data) => {
                    if (handleExerciseData) handleExerciseData(data);
                    setOpengraphLoadingMode('loaded');
                }).catch(error => {
                    // ToDo: expand this to include a retry
                    if (typeof error === 'string') {
                        setOpengraphError(error);
                    } else {
                        setOpengraphError('Opengraph error');
                    }
                    setOpengraphLoadingMode('error');
                });
        }
    }, [opengraphLoadingMode, handleExerciseData, opengraphImageUrl])

    const handleImageLoaded = () => {
        setImageLoaded(true);
    };

    const handleLinkFormCleared = () => {
        setOpengraphImageUrl("");
        setOpengraphLoadingMode(null);
        setOpengraphError(null);
        handleClearExerciseMedium();
        handleExerciseData({});
    };

    const renderLinkFormInputPrepend = () => {
        return (
            <>
                {(opengraphLoadingMode === null) &&
                    <InputGroup.Text id="basic-addon">Paste</InputGroup.Text>
                }
                {(opengraphLoadingMode === 'error') &&
                    <InputGroup.Text className="text-danger" id="basic-addon">{opengraphError}</InputGroup.Text>
                }
                {(opengraphLoadingMode === 'loading') &&
                    <InputGroup.Text className="text-warning" id="basic-addon">Loading</InputGroup.Text>
                }
                {(opengraphLoadingMode === 'loaded') &&
                    <InputGroup.Text className="text-secondary" id="basic-addon">Loaded</InputGroup.Text>
                }
            </>
        )
    }

    const renderLinkFormInputAppend = () => {
        return (
            <>
                {['loaded', 'error'].includes(opengraphLoadingMode) &&
                    <InputGroup.Text id="clear-addon" onClick={handleLinkFormCleared}>
                        <FontAwesomeIcon icon={faCircleXmark} />
                    </InputGroup.Text>
                }
            </>
        )
    }

    let ogComponent = '';

    if (exercise?.clip?.video_url && clipMode === 'video') {
        ogComponent =
            <div className="clip-player">
                <SwsVideoPlayer
                    {...props}
                    controls={true}
                    playerShouldBePlaying={true}
                    playerShouldBeMuted={true}
                    url={exercise.clip.video_url}
                />
            </div>

    } else if (exercise?.clip?.image_url && clipMode === 'image') {
        ogComponent =
            <div className="card">
                <div className="card-img">
                    <img className={myClasses} alt="animated exercise"
                        onLoad={handleImageLoaded}
                        onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            handleExerciseClick(index);
                        }}
                        src={exercise.clip.image_url} />
                </div>
            </div>
    } else if (['new', 'edit'].includes(mode)) {
        ogComponent = preloadedComponent || '';
    }

    return (
        <>
            {ogComponent}
            {['new', 'edit'].includes(mode) &&
                <>
                    <Form.Group className="my-2">
                        <InputGroup className='mb-3'>
                            {renderLinkFormInputPrepend()}
                            <Form.Control
                                type="text"
                                className={opengraphLoadingMode === 'error' ? 'invalid-input' : ''}
                                placeholder="YouTube Link"
                                aria-label="YouTube Link"
                                aria-describedby="basic-addon"
                                value={opengraphImageUrl}
                                onChange={(event) => {
                                    event.preventDefault();
                                    setOpengraphError(null);
                                    setOpengraphImageUrl(event.target.value);
                                    if (event.target.value.length > 0) {
                                        setOpengraphLoadingMode('loading');
                                    } else {
                                        setOpengraphLoadingMode(null);
                                    }
                                }} />
                            {renderLinkFormInputAppend()}
                        </InputGroup>
                    </Form.Group>
                </>
            }
        </>
    )
}
