import React, {useEffect, useState} from "react";
import HeartbeatImg from "../common/heartbeat_img";
import API from "../../utils/api";
import Formatter from "../../utils/formatter";

const SearchCount = (props) => {
    const {category, searchQuery} = props;

    // initial, loading, loaded, error
    const [loadingState, setLoadingState] = useState('initial')
    const [serverError, setServerError] = useState(null);
    const [count, setCount] = useState(null);
    const countLabel = (category === 'external_media') ? 'Media' : Formatter.capitalizeFirst(category);

    useEffect(() => {
        setLoadingState('loading');
        API.get({path: `/${category}`, query: `${searchQuery}&count=1`})
            .then((data) => {
                setLoadingState('loaded');
                if (data?.state?.total_entries !== null) {
                    setCount(data.state.total_entries);
                }
            }).catch(err => {
                setLoadingState('error');
                setServerError(err.message.toString());
            });
    }, [category, searchQuery]);

    return (
        <>
            <div className="my-0 w-100 mt-2 text-center">
                {loadingState === 'loading' &&
                <HeartbeatImg/>
                }
                {loadingState === 'loaded' && category &&
                <h6>{`${count} ${countLabel}`}</h6>
                }
                {loadingState === 'error' && serverError &&
                <div className="alert alert-danger" role="alert">{serverError}</div>
                }
            </div>
        </>
    )
};

export default SearchCount;
