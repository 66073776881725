import React, {useContext} from 'react';
import {ConfigContext} from "../../utils/config_context";
import SwsVideoPlayer from "../players/sws_video_player";

export default function ExercisePerform(props) {

    const {children, exercise, performState, renderExerciseHeader,
        renderExerciseFooter, roundState, showTitle} = props;

    const config = useContext(ConfigContext);

    const renderClipPlayer = () => {

        return (
            <div className={'player-wrapper'}>
                <SwsVideoPlayer
                    classes="react-player"
                    controls={true}
                    url={exercise.clip.video_url}
                    playerShouldBePlaying={performState === 'performing'}
                    playerShouldBeMuted={true}
                />
            </div>
        )
    }

    let imageSrc = "/assets/images/gears-sm.gif";

    if (exercise.standard_animation !== null) {
        imageSrc = `${config.s3Basepath}/${exercise.standard_animation}`;
    } else if (exercise.standard_image !== null) {
        imageSrc = `${config.s3Basepath}/${exercise.standard_image}`;
    }

    return (
        <div className="card bg-dark">
            {exercise?.clip && renderClipPlayer()}
            {!exercise?.clip &&
            <img src={imageSrc} className="img-responsive w-100 card-img" alt="Exercise"/>
            }
            <div className="card-img-overlay">
                <div className="d-none d-sm-block">
                    {renderExerciseHeader('card-title text-white text-shadow')}
                </div>
                {(roundState === 'rest') &&
                <div className="text-center">
                    <h3 className="card-title text-red text-shadow">
                        {showTitle && (roundState === 'rest') && 'Rest'}
                    </h3>
                </div>
                }
            </div>
            <span className="d-none d-sm-block text-center text-white align-bottom card-text-bottom">
                    {renderExerciseFooter()}
                </span>
            {children}
        </div>
    );
}