import {Button, Modal} from 'react-bootstrap';
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";

export default function ModalFooter(props) {
    const {handleDetail, renderPrefixButtons, renderSaveDoneButton, saveMode} = props;

    const chevron = <span className='pe-1'><FontAwesomeIcon icon={faChevronRight}/></span>;
    const showPrefixButtons = (typeof(renderPrefixButtons) === 'function');

    const justify = (showPrefixButtons) ? 'justify-content-between' : 'justify-content-end';

    return (
        <Modal.Footer>
            <div className={`my-0 d-flex w-100 ${justify}`}>
                {showPrefixButtons && renderPrefixButtons()}

                {(saveMode === 'none') && 
                    <Button variant="primary" size="sm" onClick={handleDetail}>{chevron}</Button>
                }

                {renderSaveDoneButton()}
            </div>
        </Modal.Footer>
    );
}