import React, {useContext, useState} from 'react';
import ImageUploadObject from "../common/image_upload_object";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCamera, faQuestionCircle, faMusic} from '@fortawesome/free-solid-svg-icons'
import UploadImage from "../common/upload_image";
import {ConfigContext} from "../../utils/config_context";
import {NavContext} from "../../providers/nav_provider";

export default function WorkoutVideoSelector(props) {
    const {
        mode,
        updateWorkoutImageState,
        workoutImage
    } = props;

    const {setAlert} = useContext(NavContext);

    const config = useContext(ConfigContext);
    const [uploadError, setUploadError] = useState('');
    const uploadImageRef = React.createRef();

    const alertTitleText = () => {
        return (
            <>
                <FontAwesomeIcon icon={faQuestionCircle} className="pe-1"/>
                About Workout Media
            </>
        );
    };

    const alertBodyText = () => {
        const cameraIcon = <FontAwesomeIcon icon={faCamera}/>;
        const musicIcon = <FontAwesomeIcon icon={faMusic}/>;
        return (
            <div className="pt-2">
                <p>
                    In recording your workout, you can pick a starting {cameraIcon} image. It's an ideal
                    place for a selfie, a picture of gym you're at, or your heart-rate curve.
                </p>
                <p>
                    You can pick an <b>Intro</b> video to be watched before starting the workout.
                    This is a good place to give an overview of the workout including what equipment
                    will be used, as well as what the goals are of the workout.
                </p>
                <p>
                    <b>Warmup</b> and <b>Cooldown</b> videos are useful for reuse across multiple
                    workouts.
                </p>
                <p>
                    If a manual or timed workout was chosen, then a music video playlist {musicIcon} can be added
                    to the workout.
                </p>
                <p>
                    In a linked video workout, selecting <b>Main</b> will show the linked exercises. When another
                    video type is selected, it is not.
                </p>
            </div>
        )
    };

    const renderWrappedImageObject = (classes) => {

        return (
            <div className={classes}>
                <ImageUploadObject imageObject={workoutImage} mode={mode}/>
            </div>
        );
    };

    if ((mode !== 'edit') && !workoutImage?.src) return null;

    return (
        <>
            <UploadImage ref={uploadImageRef}
                         analyticsCategory="WorkoutImageUpload"
                         handleNewImage={updateWorkoutImageState}
                         maxMb={config.maxFileSizes.workoutImageMb}
                         setUploadError={setUploadError}
                         uploadUrl={`${config.apiBase}/workouts/images`}>
                {workoutImage && renderWrappedImageObject('')}
            </UploadImage>
        </>
    )
}