import React from "react";

const YouTubeLogo = (props) => {
    const {children, classes} = props;
    const myClasses = `text-center ${classes}`;

    return (
        <div className={myClasses}>
            <img style={{height: '1.3rem'}} src="/assets/images/yt/yt_logo_rgb_light_sm.png"/>
            {children &&
                <span style={{verticalAlign: 'middle', marginLeft: '.5rem'}}>
                    {children}
                </span>
            }
        </div>
    )
};

export default YouTubeLogo;
