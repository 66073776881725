import React, { useContext, useEffect, useRef, useState } from 'react';
import { Col, InputGroup, Form, Row } from 'react-bootstrap';
import { ConfigContext } from '../../utils/config_context';
import SwsVideoPlayer from '../players/sws_video_player';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import TextareaAutosize from 'react-textarea-autosize';
import { UserContext } from '../../providers/user_provider';

export default function PlayerTest(props) {
    const { system } = props;

    const config = useContext(ConfigContext);
    const {user} = useContext(UserContext);
    const [autoStart, setAutoStart] = useState(true);
    const [eventLines, setEventLines] = useState([]);
    const [embedCode, setEmbedCode] = useState(null);
    const [embedCodeError, setEmbedCodeError] = useState(null);
    const [enablePlayerOnProgressEvent, setEnablePlayerOnProgressEvent] = useState(null);
    const [isLoop, setIsLoop] = useState(false);
    const [playerUrl, setPlayerUrl] = useState(null);
    const [startSeconds, setStartSeconds] = useState(null);
    const playerUrlForm = useRef(null);
    const embedCodeRef = useRef(null);

    useEffect(() => {
        if (!user?.is_admin) {
            navigate('/');
        }
    }, [user]);

    useEffect(() => {
        if (embedCode) {
            if (embedCode.match(/^<iframe/)) {
                const captureRegex = /src="(?<link>[^"]*)"/;
                let link = embedCode.match(captureRegex)?.groups?.link;
                if (link) {
                    link = link.replace(/&amp;/g, '&');
                    setPlayerUrl(link);
                    setEmbedCodeError(null);
                }
            } else {
                setEmbedCodeError("Invalid embed code");
            }
        }

    }, [embedCode, setPlayerUrl]);

    const handlePlayerOnProgress = (event) => {
        let newEventLines = eventLines ? [...eventLines] : [];
        newEventLines.unshift(JSON.stringify(event));
        setEventLines(newEventLines.slice(0, 100));
    }

    const handleUrlChange = (event) => {
        event.preventDefault();
        setPlayerUrl(event.target.value);
    };

    // Print event
    const printEvent = () => {
    };

    const renderUrlClearButton = () => {
        return (
            <InputGroup.Text id="clear-addon" onClick={() => {
                setPlayerUrl(null);
                setEmbedCode(null);
            }}>
                <FontAwesomeIcon icon={faCircleXmark} />
            </InputGroup.Text>
        )
    }

    const renderPlayerForm = () => {
        return (
            <Form id="external-media-modal-form" noValidate ref={playerUrlForm} className="w-100 pt-2 px-2">
                <Form.Group className="w-100">
                    <Row>
                        <Col xs={1}>
                            <Form.Label>
                                <div className="text-center" style={{ zIndex: "1", position: "relative", fontSize: '1.6rem' }}>
                                    <FontAwesomeIcon icon={faYoutube} color="red" size="2x" className="fa-lg" />
                                </div>
                            </Form.Label>
                        </Col>
                        <Col xs={11}>
                            <InputGroup>
                                <Form.Control required
                                    type="text"
                                    name="link"
                                    placeholder="YouTube Link"
                                    onChange={handleUrlChange}
                                    value={playerUrl || ''} />
                                {playerUrl && renderUrlClearButton()}
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={4}>
                            <Form.Label>
                                <h5>Start Time</h5>
                            </Form.Label>
                        </Col>
                        <Col xs={8}>
                            <InputGroup>
                                <Form.Control required
                                    type="text"
                                    name="link"
                                    onChange={(event) => setStartSeconds(event.target.value)}
                                    value={startSeconds} />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row className="public-row rx-2">
                        <div className="custom-control custom-switch">
                            <input type="checkbox"
                                onChange={() => setAutoStart(!autoStart)}
                                className="custom-control-input"
                                checked={autoStart}
                                id="switchAutoStart"
                            />
                            <label className="custom-control-label" htmlFor="switchAutoStart">
                                <h5 className="mx-2">AutoStart</h5>
                            </label>
                        </div>
                    </Row>
                    <Row className="public-row rx-2">
                        <div className="custom-control custom-switch">
                            <input type="checkbox"
                                onChange={() => setIsLoop(!isLoop)}
                                className="custom-control-input"
                                checked={isLoop}
                                id="customSwitchLoop"
                            />
                            <label className="custom-control-label" htmlFor="customSwitchLoop">
                                <h5 className="mx-2">Loop Video</h5>
                            </label>
                        </div>
                    </Row>
                    <Row className="public-row rx-2">
                        <div className="custom-control custom-switch">
                            <input type="checkbox"
                                onChange={() => setEnablePlayerOnProgressEvent(!enablePlayerOnProgressEvent)}
                                className="custom-control-input"
                                checked={enablePlayerOnProgressEvent}
                                id="switchPlayerOnProgressEvent"
                            />
                            <label className="custom-control-label" htmlFor="switchPlayerOnProgressEvent">
                                <h5 className="mx-2">Player On Progress</h5>
                            </label>
                        </div>
                    </Row>
                    <Row>
                        <TextareaAutosize
                            key="prompt-ta"
                            className="textarea"
                            onChange={(event) => setEmbedCode(event.target.value)}
                            placeholder="YouTube Embed"
                            value={embedCode}
                            name="prompt"
                            maxLength="512"
                            readOnly={false}
                            style={{ width: '100%', border: 'none' }}
                            ref={embedCodeRef}
                            wrap="soft"
                        />
                    </Row>
                    {embedCodeError &&
                        <Row>
                            <div className="text-red">
                                {embedCodeError}
                            </div>
                        </Row>
                    }
                </Form.Group>
            </Form>
        )
    };

    const renderTestPlayer = () => {

        if (!playerUrl) return null;

        return (
            <Row className="px-2">
                <Col lg={12}>
                    <div className="clip-player">
                        <SwsVideoPlayer
                            {...props}
                            controls={true}
                            loop={isLoop}
                            playerShouldBePlaying={autoStart}
                            playerOnProgress={(event) => {
                                if (enablePlayerOnProgressEvent) {
                                    handlePlayerOnProgress(event);
                                }
                            }}
                            startSeconds={startSeconds}
                            url={playerUrl}
                        />
                    </div>
                </Col>
            </Row>
        );
    };
    /*
                                    playerOnError={handleOnError}
                                    playerOnEnded={handleOnEnded}
                                    playerOnPlay={handleOnPlay}
                                    playerOnProgress={handleOnProgress}
                                    playerShouldBePlaying={true}
                                    playerShouldBeMuted={false}
                                    */

    return (
        <div className='top-component'>
            <Row>
                <h3>Test Player Tool</h3>
            </Row>
            {renderTestPlayer()}
            {renderPlayerForm()}
            <Row>
                <div className="mx-auto ms-2">
                    <h5>Events</h5>
                </div>
            </Row>
            <Row>
                {eventLines?.map((eventLine) => <div>{eventLine}</div>)}
            </Row>
        </div>
    );
}