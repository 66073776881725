import React, { useCallback, useContext, useDebugValue, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SwsAnalytics } from '../../services/sws_analytics';
import { Col, Form, Row, Button, Modal } from 'react-bootstrap';
import Formatter from '../../utils/formatter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faClipboardList,
    faGripHorizontal,
    faSlidersH,
    faStopwatch,
} from '@fortawesome/free-solid-svg-icons';
import TagWrapper from '../common/tag_wrapper';
import MediaTypeWrapper from '../common/media_type_wrapper';
import { useLocation, useNavigate } from 'react-router-dom';
import { ConfigContext } from '../../utils/config_context';
import SearchCount from '../common/search_count';
import queryString from 'query-string';
import YouTubeLogo from '../common/you_tube_logo';
import MultiRangeSlider, { ChangeResult } from 'multi-range-slider-react';
import { search } from 'superagent';
import { UserContext } from "../../providers/user_provider";
import { setUser } from '@sentry/browser';


/**
 *
 * When the modal is initialized, settings are set as true or false based on the query string.
 *
 * When the new settings are applied, they are reset to undefined if they are all set for the category.
 */

const useStateWithLabel = (initialValue, label) => {
    const [value, setValue] = useState(initialValue);
    useDebugValue(`${label}: ${value}`);
    return [value, setValue];
};

export default function FiltersModal(props) {
    const { category, categoryTitle, system } = props;

    let location = useLocation();
    let navigate = useNavigate();
    let { groupExerciseId } = useParams();

    // These should apply to both exercises and workouts
    const [tagIds, setTagIds] = useState([]);

    const [exerciseGroup, setExerciseGroup] = useStateWithLabel(false, 'exerciseGroup');
    const [exerciseMediaTypes, setExerciseMediaTypes] = useStateWithLabel([], 'exerciseMediaTypes');

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useStateWithLabel(location.search, 'searchQuery');
    // null, clip, group
    const [userSlug, setUserSlug] = useState(undefined);
    const [userSlugList, setUserSlugList] = useState([]);
    const [videoAudioOnly, setVideoAudioOnly] = useState(false);
    const [videoLive, setVideoLive] = useState(true);
    const [videoOther, setVideoOther] = useState(true);
    const [videoPlaylist, setVideoPlaylist] = useState(true);

    const [workoutEmpty, setWorkoutEmpty] = useState(true);
    const [workoutExternalMedia, setWorkoutExternalMedia] = useStateWithLabel(undefined, 'workoutExternalMedia');
    const [workoutManual, setWorkoutManual] = useState(true);
    const [workoutTimed, setWorkoutTimed] = useState(true);
    const [workoutYouTube, setWorkoutYouTube] = useState(true);

    const [youTubeOptions, setYouTubeOptions] = useState({
        video: true,
        videoPlaylist: true,
        syncedVideo: true,
        syncedVideoPlaylist: true,
        media: true,
    });

    const [anyDuration, setAnyDuration] = useState(true);
    const [durationMinValue, setDurationMinValue] = useState(null);
    const [durationMaxValue, setDurationMaxValue] = useState(null);
    const handleInput = (e) => {
        setDurationMinValue(e.minValue);
        setDurationMaxValue(e.maxValue);
    };

    const { user } = useContext(UserContext);

    // <=30
    // 30-60
    // >=60
    //
    // A video of exactly 30 would match <= 30, 30-60
    // I don't think clips, snippets are discernable

    const filter = <FontAwesomeIcon icon={faSlidersH} />;

    const initializeFilters = useCallback((parsedQuery) => {

        setUserSlug(parsedQuery['user_slug']);
        setUserSlugList(parsedQuery['user_slug'] ? [parsedQuery['user_slug']] : []);

        if (category === 'exercises') {
            const newTagIds = typeof parsedQuery['tags'] === 'string' ? [Number(parsedQuery['tags'])]
                : parsedQuery['tags']?.map((t) => Number(t));

            if (parsedQuery['em'] === undefined) {
                setExerciseMediaTypes(['yt', 'img', 'anim']);
            } else if (Array.isArray(parsedQuery['em'])) {
                setExerciseMediaTypes(parsedQuery['em']);
            } else {
                setExerciseMediaTypes([parsedQuery['em']]);
            }

            setTagIds(newTagIds?.length ? newTagIds : []);
            if (parsedQuery['group'] !== undefined) {
                setExerciseGroup(parsedQuery['group'] == '1');
            }
        } else if (category === 'workouts') {
            if (parsedQuery['wem'] !== undefined) {
                setWorkoutExternalMedia(parsedQuery['wem'] === '1');
            }
            setWorkoutTimed(parsedQuery['wt'] !== undefined ? parsedQuery['wt'] == '1' : true);
            setWorkoutYouTube(parsedQuery['wyt'] !== undefined ? parsedQuery['wyt'] == '1' : true);
            setWorkoutManual(parsedQuery['wman'] !== undefined ? parsedQuery['wman'] == '1' : true);
            setWorkoutEmpty(parsedQuery['wnull'] !== undefined ? parsedQuery['wnull'] == '1' : true);

        } else if (category === 'external_media') {
            let newDurationMinValue = parsedQuery['min'];
            let newDurationMaxValue = parsedQuery['max'];

            setVideoAudioOnly(parsedQuery['audio'] !== undefined ? parsedQuery['audio'] == '1' : true);
            setVideoLive(parsedQuery['live'] !== undefined ? parsedQuery['live'] == '1' : true);
            setVideoOther(parsedQuery['other'] !== undefined ? parsedQuery['other'] == '1' : true);
            setVideoPlaylist(parsedQuery['playlist'] !== undefined ? parsedQuery['playlist'] == '1' : true);

            if (newDurationMinValue || newDurationMaxValue) {
                setAnyDuration(false);
                setDurationMinValue(newDurationMinValue);
                setDurationMaxValue(newDurationMaxValue);
            }
        }
    }, [category, setAnyDuration, setDurationMinValue, setVideoAudioOnly, setVideoLive,
        setVideoOther, setVideoPlaylist, setWorkoutEmpty, setWorkoutExternalMedia,
        setWorkoutManual, setWorkoutTimed, setWorkoutYouTube, setExerciseGroup,
        setExerciseMediaTypes, setTagIds, setUserSlug, setUserSlugList]);

    // We chose to treat these as individual parameters because some of them may end up being cross-category
    //
    useEffect(() => {
        const parsedQuery = queryString.parse(location.search, { arrayFormat: 'comma' });
        initializeFilters(parsedQuery);
    }, [initializeFilters, location.pathname, location.search]);

    useEffect(() => {
        let searchObj = {};
        if (userSlug) {
            searchObj['user_slug'] = userSlug;
        }
        if (category === 'exercises') {
            if (tagIds?.length) searchObj['tags'] = tagIds;
            if (exerciseGroup) searchObj['group'] = '1';
            searchObj['em'] = exerciseMediaTypes;
        } else if (category === 'workouts') {
            if (workoutExternalMedia !== undefined) {
                searchObj['wem'] = workoutExternalMedia ? '1' : '0';
            }
            searchObj['wyt'] = workoutYouTube ? '1' : '0';
            searchObj['wnull'] = workoutEmpty ? '1' : '0';
            searchObj['wman'] = workoutManual ? '1' : '0';
            searchObj['wt'] = workoutTimed ? '1' : '0';
        } else if (category === 'external_media') {
            if (exerciseMediaTypes?.length) searchObj['em'] = exerciseMediaTypes;
            searchObj['audio'] = videoAudioOnly ? '1' : '0';
            searchObj['live'] = videoLive ? '1' : '0';
            searchObj['other'] = videoOther ? '1' : '0';
            searchObj['playlist'] = videoPlaylist ? '1' : '0';
            if (!anyDuration) {
                searchObj['min'] = durationMinValue;
                searchObj['max'] = durationMaxValue;
            }
        }

        const newSearchQuery = queryString.stringify(searchObj, {
            arrayFormat: 'comma',
        });
        setSearchQuery(newSearchQuery);
    }, [anyDuration, category, durationMinValue, durationMaxValue, exerciseGroup,
        exerciseMediaTypes, setSearchQuery, tagIds, videoAudioOnly, videoLive,
        videoOther, videoPlaylist, userSlug, workoutEmpty,
        workoutExternalMedia, workoutManual, workoutTimed, workoutYouTube,]);


    const setModalIsOpenToTrue = () => {
        setModalIsOpen(true);
    };

    const setModalIsOpenToFalse = () => {
        setModalIsOpen(false);
    };

    function handleAllTagClick() {
        setTagIds([]);
    }

    function handleNoneTagClick() {
        setTagIds([0]);
    }

    function handleReset() {
        setSearchQuery('');
        initializeFilters([]);
    }

    function handleToggleTag(tagId) {
        let newTagIds;

        if (tagIds?.length === 0) {
            newTagIds = system.tags.filter((t) => t.id !== tagId).map((t) => t.id);
        } else if (tagIds?.length === 1 && tagIds[0] === 0) {
            newTagIds = [tagId];
        } else if (tagIds?.includes(tagId)) {
            // turn off last one: automatically switch to none
            if (tagIds?.length === 1) {
                newTagIds = [0];
            } else {
                newTagIds = tagIds.filter((tag_id) => tag_id !== tagId);
            }
            // Enable all: switch to empty tag array
        } else if (tagIds?.length === system.tags.length - 1) {
            newTagIds = [];
        } else {
            newTagIds = [...tagIds];
            newTagIds.unshift(Number(tagId));
        }
        setTagIds(newTagIds);
    }

    function handleAllUserClick() {
        setUserSlug(undefined);
    }

    // Rebuild the location.search from the filters
    function handleSetFilters() {
        const newQuery = {};

        if (category === 'exercises') {

            if (tagIds.length > 0) {
                newQuery['tags'] = tagIds;
            }
            if (exerciseGroup) {
                newQuery['group'] = '1';
            }
            if (exerciseMediaTypes.length === 3) {
                newQuery['em'] = undefined;
            } else if (exerciseMediaTypes.length) {
                newQuery['em'] = exerciseMediaTypes;
            }
        } else if (category === 'workouts') {
            if (workoutEmpty && workoutManual && workoutTimed && workoutYouTube) {
                // do nothing
            } else {
                newQuery['wnull'] = workoutEmpty ? '1' : '0';
                newQuery['wman'] = workoutManual ? '1' : '0';
                newQuery['wt'] = workoutTimed ? '1' : '0';
                newQuery['wyt'] = workoutYouTube ? '1' : '0';
            }

            if (workoutExternalMedia !== undefined) {
                newQuery['wem'] = workoutExternalMedia ? '1' : '0';
            }
        } else if (category === 'external_media') {
            if (videoPlaylist && videoLive && videoOther && videoAudioOnly) {
                // do nothing
            } else {
                newQuery['playlist'] = videoPlaylist ? '1' : '0';
                newQuery['live'] = videoLive ? '1' : '0';
                newQuery['audio'] = videoAudioOnly ? '1' : '0';
                newQuery['other'] = videoOther ? '1' : '0';
            }
        }

        if (userSlug) {
            newQuery['user_slug'] = userSlug;
        }

        if (Object.keys(newQuery).length > 0) {
            const newSearch = queryString.stringify(newQuery, { arrayFormat: 'comma' });

            navigate(`${location.pathname}?${newSearch}`, { replace: true });
        } else {
            navigate(`${location.pathname}`, { replace: true });
        }

        SwsAnalytics.event({
            category: category,
            action: 'Set Filters',
        });

        setModalIsOpenToFalse();
    }

    function renderYouTubeOption(option, label) {
        const hyphenedId = label.toLowerCase().replace(/ /, '-') + '-checkbox';

        return (
            <Form.Check
                checked={youTubeOptions[option]}
                type="checkbox"
                label={label}
                id={hyphenedId}
                onClick={() => {
                    let newYouTubeOptions = Object.assign({}, youTubeOptions);
                    newYouTubeOptions[option] = !youTubeOptions[option];
                    setYouTubeOptions(newYouTubeOptions);
                }}
            />
        );
    }

    function renderCheckbox(checked, label, clickHandler) {
        const hyphenedId = label.toLowerCase().replace(/ /, '-') + '-checkbox';

        return (
            <Form.Check
                checked={checked}
                type="checkbox"
                label={label}
                id={hyphenedId}
                onClick={clickHandler}
            />
        );
    }

    function renderExerciseTagsFilter() {
        const isAll = !tagIds?.length;

        return (
            <Modal.Body>
                <Row>
                    <Col xs="2">
                        <div className="fs-5 mt-1"><a href="#" onClick={handleAllTagClick}>Tags</a></div>
                    </Col>
                    {system.tags.map((tag) => {
                        return (
                            <TagWrapper
                                key={`tag-filter-${tag.id}`}
                                tagName={tag.name}
                                selected={isAll || tagIds.includes(tag.id)}
                                clickHandler={() => setTagIds([tag.id])}
                            >
                                {tag.title}
                            </TagWrapper>
                        );
                    })}
                </Row>
            </Modal.Body>
        );
    }

    function renderExerciseMediaFilter() {
        return (
            <Modal.Body>
                <Row>
                    <Col xs="3">
                        <div className="fs-5 mt-1">
                            <a href="#" onClick={() => setExerciseMediaTypes(['img', 'anim', 'yt'])}>
                                Media
                            </a>
                        </div>
                    </Col>
                    <MediaTypeWrapper itemType="img" label="Image" mediaTypes={exerciseMediaTypes}
                        setMediaTypes={setExerciseMediaTypes}>
                        Image
                    </MediaTypeWrapper>

                    <MediaTypeWrapper itemType="anim" label="Animated" mediaTypes={exerciseMediaTypes}
                        setMediaTypes={setExerciseMediaTypes}>
                        Animated
                    </MediaTypeWrapper>

                    <MediaTypeWrapper itemType="yt" label="YouTube" mediaTypes={exerciseMediaTypes}
                        setMediaTypes={setExerciseMediaTypes}>
                        <YouTubeLogo />
                    </MediaTypeWrapper>
                </Row>
            </Modal.Body>
        );
    }

    // Create a function that shows a nested set of checkboxes in react bootstrap

    function renderExerciseTypeFilter() {
        const groupExerciseIcon = (
            <FontAwesomeIcon icon={faGripHorizontal} color="blue" />
        );

        return (
            <Modal.Body>
                <Row>
                    <Col xs="2">
                        <div className="fs-5 mt-1">
                            <a href="#" onClick={() => setExerciseGroup(undefined)}>Type</a>
                        </div>
                    </Col>
                    <TagWrapper key="single-tag-filter" tagName="Single"
                        selected={!exerciseGroup}
                        clickHandler={() => {
                            setExerciseGroup(false);
                        }}
                    >
                        {Formatter.tagToLabel('Single')}
                    </TagWrapper>

                    <TagWrapper key="group-tag-filter" tagName="Grouped"
                        selected={exerciseGroup}
                        clickHandler={() => {
                            setExerciseGroup(true);
                        }}
                    >
                        {groupExerciseIcon} {Formatter.tagToLabel('Superset')}
                    </TagWrapper>
                </Row>
            </Modal.Body>
        );
    }

    function renderExternalMediaFilter() {
        const showDurationSlider = false;

        return (
            <Modal.Body>
                {showDurationSlider &&
                    <Row>
                        <Col>
                            <Form.Check
                                checked={anyDuration}
                                type="checkbox"
                                label="Any Duration"
                                onClick={() => {
                                    setAnyDuration(!anyDuration);
                                    setDurationMinValue(30);
                                    setDurationMaxValue(1800);
                                }}
                            />
                        </Col>
                    </Row>
                }
                {!anyDuration && (
                    <Row className="pb-4">
                        <MultiRangeSlider
                            baseClassName="sws-multi-range-slider"
                            ruler={false}
                            min={30}
                            max={1800}
                            step={15}
                            minCaption={Formatter.toMMSS(durationMinValue)}
                            maxCaption={Formatter.toMMSS(durationMaxValue)}
                            labels={[':30', '30:00']}
                            minValue={durationMinValue}
                            maxValue={durationMaxValue}
                            onInput={(e) => {
                                handleInput(e);
                            }}
                        />
                    </Row>
                )}
                {/* depressing the same button does not switch it if nothing else is selected */}
                <Row>
                    <Col xs="2">
                        <div className="fs-5 mt-1">
                            <a href="#" onClick={() => {
                                setVideoLive(true);
                                setVideoOther(true);
                                setVideoPlaylist(true);
                                setVideoAudioOnly(true);
                            }}>Media</a>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <TagWrapper
                        key="audio-tag-filter"
                        tagName="audio"
                        selected={videoAudioOnly}
                        clickHandler={() => {
                            setVideoPlaylist(false);
                            setVideoLive(false);
                            setVideoOther(false);
                            setVideoAudioOnly(true);
                        }}>
                        {Formatter.tagToLabel('Audio Only')}
                    </TagWrapper>
                    <TagWrapper
                        key="live-video-tag-filter"
                        tagName="live"
                        selected={videoLive}
                        clickHandler={() => {
                            setVideoPlaylist(false);
                            setVideoOther(false);
                            setVideoAudioOnly(false);
                            setVideoLive(true);
                        }}>
                        {Formatter.tagToLabel('Live Stream')}
                    </TagWrapper>
                    <TagWrapper
                        key="playlist-video-tag-filter"
                        tagName="playlist-video"
                        selected={videoPlaylist}
                        clickHandler={() => {
                            setVideoOther(false);
                            setVideoAudioOnly(false);
                            setVideoLive(false);
                            setVideoPlaylist(true);
                        }}>
                        {Formatter.tagToLabel('Playlist')}
                    </TagWrapper>
                    <TagWrapper
                        key="other-video-tag-filter"
                        tagName="other-video"
                        selected={videoOther}
                        clickHandler={() => {
                            setVideoLive(false);
                            setVideoAudioOnly(false);
                            setVideoPlaylist(false);
                            setVideoOther(true);
                        }}
                    >
                        {Formatter.tagToLabel('Other')}
                    </TagWrapper>
                </Row>
            </Modal.Body>
        );
    }

    function renderWorkoutsFilter() {
        const stopwatchIcon = <FontAwesomeIcon icon={faStopwatch} color="black" />;
        const clipboardIcon = (
            <FontAwesomeIcon icon={faClipboardList} color="#666" />
        );
        const timedFilterLabel = <div>{stopwatchIcon} Timed</div>;
        const manualFilterLabel = <div>{clipboardIcon} Manual</div>;

        return (
            <Modal.Body>
                <Row className="pt-2">
                    <Col>
                        <div className="fs-5 mt-1">
                            Workout Type
                        </div>
                    </Col>
                </Row>
                <div className="workout-checkboxes pt-2 fs-5">
                    <Form.Check
                        checked={workoutEmpty}
                        type="checkbox"
                        label="Empty Workout"
                        id="empty-workout-checkbox"
                        onClick={() => {
                            if (workoutEmpty && !workoutManual && !workoutTimed && !workoutYouTube) {
                                setWorkoutManual(true);
                                setWorkoutTimed(true);
                                setWorkoutYouTube(true);
                            }
                            setWorkoutEmpty(!workoutEmpty);
                        }}
                    />
                    <Form.Check
                        checked={workoutManual}
                        type="checkbox"
                        label={manualFilterLabel}
                        id="manual-workout-checkbox"
                        onClick={() => {
                            if (workoutManual && !workoutEmpty && !workoutTimed && !workoutYouTube) {
                                setWorkoutEmpty(true);
                                setWorkoutTimed(true);
                                setWorkoutYouTube(true);
                            }
                            setWorkoutManual(!workoutManual);
                        }}
                    />
                    <Form.Check
                        checked={workoutTimed}
                        type="checkbox"
                        label={timedFilterLabel}
                        id="timed-filter-checkbox"
                        onClick={() => {
                            if (workoutTimed && !workoutEmpty && !workoutManual && !workoutYouTube) {
                                setWorkoutEmpty(true);
                                setWorkoutManual(true);
                                setWorkoutYouTube(true);
                            }
                            setWorkoutTimed(!workoutTimed);
                        }}
                    />
                    <Form.Check
                        checked={workoutYouTube}
                        type="checkbox"
                        label={<YouTubeLogo />}
                        id="workout-youtube-checkbox"
                        onClick={() => {
                            if (workoutYouTube && !workoutEmpty && !workoutManual && !workoutTimed) {
                                setWorkoutEmpty(true);
                                setWorkoutManual(true);
                                setWorkoutTimed(true);
                            }
                            setWorkoutYouTube(!workoutYouTube);
                        }}
                    />
                    <hr style={{ marginBottom: '.5rem' }} />
                    <Row className="pt-2">
                        <Col xs="2">
                            <div className="fs-5 mt-1">
                                <a href="#" onClick={() => setWorkoutExternalMedia(undefined) }>
                                    Media
                                </a>
                            </div>
                        </Col>
                        <TagWrapper
                            key="you-tube-media-filter"
                            tagName="you-tube-media"
                            selected={[undefined, true].includes(workoutExternalMedia)}
                            clickHandler={() => setWorkoutExternalMedia(true) }>
                            <span style={{ fontSize: '1.2rem' }}><YouTubeLogo /></span>
                        </TagWrapper>
                        <TagWrapper
                            key="no-media-filter"
                            tagName="no-media"
                            selected={[undefined, false].includes(workoutExternalMedia)}
                            clickHandler={() => setWorkoutExternalMedia(false) }>
                            <span style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>No Media</span>
                        </TagWrapper>
                    </Row>
                </div>
            </Modal.Body>
        );
    }

    function renderUserFilters() {
        if (!userSlug && !!userSlugList.length && !user.id) {
            return null;
        }
        // return true if the user is logged in and the user slug is not the same as the first user slug in the list
        const showUserButton = user.slug && (userSlugList[0] !== user.slug);

        return (
            <Modal.Body>
                <Row>
                    <Col xs="2">
                        <div className="fs-5 mt-1">
                            User
                        </div>
                    </Col>
                    <div key="all-user-filter" className="col-sm-4 tag-wrapper">
                        <Button
                            variant="sm"
                            className={userSlug ? 'unselected' : 'selected'}
                            onClick={() => setUserSlug(undefined)}
                        >
                            All
                        </Button>
                    </div>
                    {!!userSlugList.length &&
                        <div key="uf" className="col-sm-4 tag-wrapper">
                            <Button
                                variant="sm"
                                className={(userSlug == userSlugList[0]) ? 'selected' : 'unselected'}
                                onClick={() => {
                                    setUserSlug(userSlugList[0])
                                }}
                            >
                                {userSlugList[0]}
                            </Button>
                        </div>
                    }
                    {showUserButton &&
                        <div key="self-filter" className="col-sm-4 tag-wrapper">
                            <Button
                                variant="sm"
                                className={(userSlug === user.slug) ? 'selected' : 'unselected'}
                                onClick={() => {
                                    setUserSlug(user.slug);
                                }}
                            >
                                {user.slug}
                            </Button>
                        </div>
                    }
                </Row>
            </Modal.Body>
        );
    }

    return (
        <>
            <span className="nav-link ctw-nav">
                <Button
                    variant={location.search.length ? 'dark' : 'light'}
                    onClick={setModalIsOpenToTrue}
                    className="btn-sm"
                >
                    {filter}
                </Button>
            </span>

            <Modal
                id="filters-modal"
                animation={false}
                show={modalIsOpen}
                onHide={() => {
                    return false;
                }}
            >
                <Modal.Header>
                    <Modal.Title>
                        {categoryTitle || Formatter.categoryToLabel(category)} Filter
                    </Modal.Title>
                </Modal.Header>
                {renderUserFilters()}
                {category === 'exercises' && !groupExerciseId && renderExerciseTypeFilter()}
                {category === 'exercises' && !exerciseGroup && renderExerciseMediaFilter()}
                {category === 'exercises' && !exerciseGroup && renderExerciseTagsFilter()}
                {category === 'workouts' && renderWorkoutsFilter()}
                {category === 'external_media' && renderExternalMediaFilter()}
                <Modal.Footer>
                    <div className="d-flex w-100 justify-content-between">
                        <Button onClick={handleReset} variant="danger" className="btn-sm">
                            Reset
                        </Button>
                        {['exercises', 'workouts', 'external_media'].includes(category) && (
                            <SearchCount category={category} searchQuery={searchQuery} />
                        )}
                        <Button
                            variant="primary"
                            onClick={() => handleSetFilters()}
                            className="btn-sm"
                        >
                            Display
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}
