import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGripHorizontal, faStar } from '@fortawesome/free-solid-svg-icons'
import PerformUtils from "../../utils/perform_utils";
import Formatter from "../../utils/formatter";
import { Row, Col } from 'react-bootstrap';
import { UserContext } from "../../providers/user_provider";

export default function ExerciseListItemRowRenderer(props) {
    const {
        buttons, classes, exercise, handleExerciseClick, hidden, imageComponent,
        index, mode, showAuthor, showStars, showWorkoutAttrs,
        workout, workoutPerform
    } = props;
    const { user } = useContext(UserContext);
    let location = useLocation();

    // ToDo: group this into a shared utils

    let workoutAttrs = '';
    const star = <span className='pe-1 text-warning'><FontAwesomeIcon icon={faStar} /></span>;
    const groupIcon = <span className='pe-1'><FontAwesomeIcon icon={faGripHorizontal} color="blue" /></span>;
    const showStar = showStars && exercise.is_featured && user?.is_admin;

    if (showWorkoutAttrs && workoutPerform && workoutPerform.exercises && workoutPerform.exercises[index]) {
        if (workout && workout.synced) {
            const startTime = workoutPerform.exercises[index].start;
            if (startTime) {
                workoutAttrs += Formatter.toHHMMSS(startTime, { showDecimal: true });
            }

        } else if (workoutPerform.timed) {
            const duration = PerformUtils.exerciseDuration(workoutPerform, index);
            if (duration) {
                workoutAttrs += Formatter.toHHMMSS(duration);
            }
        }

        if (workoutPerform.exercises[index].note) {
            if (workoutAttrs.length) {
                workoutAttrs += ' ';
            }
            workoutAttrs += workoutPerform.exercises[index].note;
        }
    }

    const myClasses = classes ? classes : '';
    let allClasses = hidden ? `d-none list-group-item ${myClasses}` : `list-group-item ${myClasses}`;

    let title = exercise.title;
    let mainXs = buttons ? 7 : 8;
    let mainSm = 8;
    let mainMd = 9;

    let imageXs = (mode === 'perform') ? 6 : 4;
    let imageSm = (mode === 'perform') ? 4 : 3;
    let imageMd = (mode === 'perform') ? 3 : 2;

    const queryParams = new URLSearchParams(location.search);
    queryParams.set('user_slug', exercise.user_slug);

    const authorLink = `${location.pathname}?${queryParams.toString()}`;

    return (
        <Row
            className={allClasses}
            onClick={handleExerciseClick}>
            <Col xs={imageXs} sm={imageSm} md={imageMd} className="list-image d-flex justify-content-around">
                {imageComponent}
            </Col>
            <Col xs={mainXs} sm={mainSm} md={mainMd} className="list-item-nuance ps-3">
                <div className="align-right exercise-title short-label ">
                    {showStar && star}
                    {exercise.is_group && groupIcon}
                    {title}
                </div>
                {showWorkoutAttrs && workoutAttrs &&
                    <div>
                        {workoutAttrs}
                    </div>
                }
                {showAuthor && !showWorkoutAttrs && exercise.username && (mode !== 'edit') &&
                    <div className="align-right author">
                        <Link to={authorLink}>by {exercise.username}</Link>
                    </div>
                }
            </Col>
            {buttons}
        </Row>
    );
}