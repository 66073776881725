import React from "react";
import HeartbeatImg from "../common/heartbeat_img";

const ApiLoadingWrapper = (props) => {
    const {isLoading, error} = props;

    // ToDo: trigger isLoading, but show for a minimum duration
    return (
        <>
            {isLoading &&
                <div className="d-flex my-0 w-100 mt-2">
                    <HeartbeatImg/>
                    Loading...
                </div>
            }
            {error &&
                <div id="loading_error">
                    {error}
                </div>
            }
        </>
    )
};

export default ApiLoadingWrapper;
