import React from 'react';
import ReactPlayer from "react-player";
import {hashString} from 'react-hash-string';

export default function SwsVideoPlayer(props) {

    const {
        classes,
        controls,
        forwardRef,
        loop,
        playerShouldBePlaying,
        playerShouldBeMuted,
        playerOnBufferEnd,
        playerOnDuration,
        playerOnEnded,
        playerOnError,
        playerOnPause,
        playerOnPlay,
        playerOnProgress,
        playerOnReady,
        playerOnSeek,
        startSeconds,
        url
    } = props;

    if (!url) return null;

    // Remove start to play clips
    let playerVars = {start: 0};
    let playerUrl = url;

    // remove startSeconds to play clips
    let embedOptions = {startSeconds: (startSeconds || 0)};
    const urlMatchA = url.match(/youtu.be\/([a-zA-Z0-9-]*)/);
    const urlMatchB = url.match(/youtube.com\/watch/);

    let searchParams = (new URL(url)).searchParams;

    if (urlMatchA && urlMatchA[1]) {
        playerUrl = `https://youtube.com/embed/${urlMatchA[1]}`;
    } else if (urlMatchB && searchParams.has('v')) {
        playerUrl = `https://youtube.com/embed/${searchParams.get('v')}`;
    }

    // https://www.youtube.com/watch?v=ewRjZoRtu0Y&list=PLyq2X18RUQBjoSfXfSaIcS7KKuKFUxDGH
    if (searchParams.has("list")) {
        playerVars['list'] = searchParams.get('list');
        //playerUrl = `https://youtube.com/embed/videoseries?list=${playerVars['list']}`;

        if (searchParams.has('index')) {
            playerVars['index'] = searchParams.get('index');
        }
    }

    if (startSeconds) {
        playerVars['start'] = startSeconds;
    } else if (searchParams.has('t')) {
        playerVars['start'] = embedOptions['startSeconds'] = Number(searchParams.get('t'));
    }

    // Attempting to get the clip parameter to appear in the iframe url
    if (searchParams.has('clip')) {
        playerVars['clip'] = searchParams.get('clip');
    }
    if (searchParams.has('clipt')) {
        playerVars['clipt'] = searchParams.get('clipt');
    }
    // Closed Captions
    // playerVars['cc_load_policy'] = '1';

    const key = hashString(url);

    return (
        <ReactPlayer
            key={key}
            ref={forwardRef}
            url={playerUrl}
            loop={loop}
            config={{
                youtube: {
                    playerVars: playerVars,
                    embedOptions: embedOptions
                }
            }}
            controls={controls}
            className={classes}
            onBufferEnd={playerOnBufferEnd}
            onDuration={playerOnDuration}
            onEnded={playerOnEnded}
            onError={playerOnError}
            onPause={playerOnPause}
            onPlay={playerOnPlay}
            onProgress={playerOnProgress}
            onReady={playerOnReady}
            onSeek={playerOnSeek}
            playing={playerShouldBePlaying}
            width="100%"
            height="100%"
            muted={playerShouldBeMuted}
        />
    );
}
