import React, {useContext, useEffect, useState} from 'react';
import {ConfigContext} from "../../utils/config_context";
import {Container, Form} from 'react-bootstrap';
import {UserService} from "../../services/user_service";
import {useForm, useFormState} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import {Link, useNavigate} from "react-router-dom";
import * as Yup from "yup";
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import HeartbeatImg from "../common/heartbeat_img";
import {NavContext} from "../../providers/nav_provider";
import {UserContext} from "../../providers/user_provider";

export default function RegisterPage(props) {

    const { setUser, user } = useContext(UserContext);
    const {afterLoginUrl, setAfterLoginUrl} = useContext(NavContext);

    const config = useContext(ConfigContext);
    let navigate = useNavigate();

    useEffect(() => {
        if (user.id && UserService.authToken) {
            navigate('/');
        }
    });

    const {executeRecaptcha} = useGoogleReCaptcha();

    const handleLogin = () => {
        navigate('/login');
    }

    // password: yup.string().min(6).max(255).required('Password is required').matches(
    //     /^(?=.*[A-Za-z])(?=.*d)(?=.*[@$!%*#?&])[A-Za-zd@$!%*#?&]{8,}$/,
    //     "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    //   ),
    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required('Email is required')
            .max(255)
            .email('Must be a valid email'),
        username: Yup.string()
            .required('Username is required')
            .min(2, 'Username must be at least 2 characters')
            .max(20, 'Username must not exceed 20 characters'),
        password: Yup.string()
            .required('Password is required')
            .min(6, 'Password must be at least 6 characters')
            .max(128, 'Password must not exceed 128 characters'),
        acceptedTos: Yup.bool().oneOf([true], 'You must accept the Terms to register')
    });

    const {
        control,
        register,
        handleSubmit,
        setError,
        formState: {errors}
    } = useForm({
        defaultValues: {
            username: '',
            email: '',
            password: '',
            acceptedTos: false
        },
        mode: 'onBlur',
        resolver: yupResolver(validationSchema),
        reValidateMode: 'onChange'
    });

    const {dirtyFields} = useFormState({control});
    const [serverError, setServerError] = useState(null);
    const [submitting, setSubmitting] = useState(false);

    const submitForm = data => {
        setSubmitting(true);
        UserService.register(data)
            .then(
                (data) => {
                    if (data.errors) {
                        setSubmitting(false);
                        for (const [field, error] of Object.entries(data.errors)) {
                            setError(field, {type: "manual", message: error});
                        }
                    } else if (data.user) {
                        setAfterLoginUrl(null);
                        setUser(data.user);
                        setSubmitting(false);
                        navigate(afterLoginUrl || "/");
                    }
                }
            )
            .catch(err => {
                setSubmitting(false);
                setServerError(err.message);
            });
    };

    const handleReCaptchaVerify = async (data) => {
        if (!config.recaptchaEnabled || !config.recaptchaSiteKey) {
            let newData = {
                user: data,
                'recaptchaToken': ''
            }
            submitForm(newData);
        } else if (!executeRecaptcha) {
            setServerError('Recaptcha failure');
        } else {
            const token = await executeRecaptcha('register');

            let newData = {
                user: data,
                'recaptchaToken': token
            }
            submitForm(newData);
        }
    };

    return (
        <Container className="vw-100 vh-100 d-flex justify-content-center align-items-center">
            <div className="d-flex justify-content-center">
                <div className="register-form">
                    <div className="text-center mb-4">
                        <img alt="fastfit_logo" style={{width: '10rem'}}
                             src="/assets/images/fastfit_gx_watermark_source1.png"/>
                    </div>
                    <Form id="register-user-form" onSubmit={handleSubmit(handleReCaptchaVerify)}>
                        <Form.Group>
                            <label>Email</label>
                            <input
                                autoCapitalize='off'
                                name="email"
                                type="text"
                                {...register('email')}
                                className={`form-control ${(errors.email && dirtyFields.email) ? 'is-invalid' : ''}`}
                            />
                            <div className="invalid-feedback">{dirtyFields.email ? errors.email?.message : ''}</div>
                        </Form.Group>

                        <Form.Group>
                            <label>Username</label>
                            <input
                                name="username"
                                type="text"
                                {...register('username')}
                                className={`form-control ${(errors.username && dirtyFields.username) ? 'is-invalid' : ''}`}
                            />
                            <div className="invalid-feedback">{errors.username?.message}</div>
                        </Form.Group>

                        <Form.Group>
                            <label>Password</label>
                            <input
                                autoCapitalize='off'
                                name="password"
                                type="text"
                                {...register('password')}
                                className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                            />
                            <div className="invalid-feedback">{errors.password?.message}</div>
                        </Form.Group>
                        <div className="form-group form-check mt-2">
                            <input
                                name="acceptedTos"
                                type="checkbox"
                                {...register('acceptedTos')}
                                className={`form-check-input ${
                                    errors.acceptedTos ? 'is-invalid' : ''
                                }`}
                            />
                            <label htmlFor="acceptedTos" className="form-check-label">
                                I have read and agree to the&nbsp;
                                <Link className="link-primary" to="#terms">Terms</Link>
                            </label>
                            <div className="invalid-feedback">{errors.acceptedTos?.message}</div>
                        </div>
                        {serverError &&
                            <Form.Group className="mt-2">
                                <div className={'alert alert-danger'}>{serverError}</div>
                            </Form.Group>
                        }
                        {submitting &&
                            <Form.Group className="d-flex my-0 w-100 mt-2">
                                <HeartbeatImg/>
                            </Form.Group>
                        }
                        <div className="form-group d-flex my-0 w-100 justify-content-between mt-4">
                            <button type="button" onClick={handleLogin}
                                    className="btn btn-secondary float-right">
                                Login
                            </button>
                            <button type="submit" className="btn btn-primary">
                                Register
                            </button>
                        </div>
                    </Form>
                </div>
            </div>
        </Container>
    );
}