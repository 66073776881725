import React from 'react';
import {Nav, Container} from 'react-bootstrap';

const NavWrapper = (props) => {
    const {children, onSelect} = props;

    return (
            <Nav className="nav-top navbar fixed-top navbar-expand navbar-dark py-1 m-0" onSelect={onSelect}>
                <Container fluid>
                    {children}
                </Container>
            </Nav>
    )
}

export default NavWrapper;
