let PerformUtils = {
    systemDefaults() {
        return ({
            sets: 1,
            showCountdown: true,
            showTitle: true,
            timed: true,
            playSounds: true,
            playMidpoint: false,
            round: 30,
            rest: 10,
            exercises: []
        });
    },

    performFromDefaults(defaults) {
        let sets = 1;
        let round = 30;
        let rest = 10;
        if (defaults) {
            sets = defaults.sets ? defaults.sets : sets;
            round = defaults.round ? defaults.round : round;
            rest = (defaults.rest !== undefined) ? defaults.rest : rest;
        }

        return Object.assign({}, {sets: sets, round: round, rest: rest});
    },


    // Exercises: sets
    //    Group Exercise
    //       [sets]
    //          [{Exercise: rest, round only}]

    duration(perform) {
        if ((!perform) || (!perform.timed) || (!perform.exercises)) {
            return null;
        }

        let totalSecs = 0;

        perform.exercises.forEach((exercise, i) => {
            totalSecs = totalSecs + PerformUtils.exerciseDuration(perform, i);
        });

        return totalSecs;
    },

    exerciseDuration(perform, i) {
        let sets = PerformUtils.calcDuration('sets', perform, i)
        let round = 0;
        let rest = 0;
        let totalSecs = 0;

        const exercise = perform.exercises[i];
        const isLast = ((perform.exercises.length - 1) === i);

        if (exercise && exercise.exercises !== undefined) {
            exercise.exercises.forEach((segment, j) => {
                round = PerformUtils.calcDuration('round', perform, i, j)
                rest = PerformUtils.calcDuration('rest', perform, i, j)
                totalSecs += (round + rest) * sets;
            })

        } else {
            round = PerformUtils.calcDuration('round', perform, i)
            rest = PerformUtils.calcDuration('rest', perform, i)
            totalSecs += (round + rest) * sets;
        }

        if (isLast) {
            totalSecs -= rest;
        }

        return totalSecs;
    },

    exerciseSyncStart(index, workout, workoutPerform) {
        if (workout.synced && workoutPerform.exercises &&
            (index < workoutPerform.exercises.length) && workoutPerform.exercises[index].start) {
            return workoutPerform.exercises[index].start;
        } else {
            return null
        }
    },

    // We get group sets by never passing in a segmentIndex for sets, as sets are invalid at that level

    calcDuration(attr, perform, exerciseIndex, segmentIndex) {
        let value = perform[attr];

        if ((exerciseIndex !== undefined) &&
            perform.exercises &&
            ((perform.exercises[exerciseIndex]) !== undefined)) {

            const topExercise = perform.exercises[exerciseIndex];

            if (topExercise[attr] !== undefined) {
                value = topExercise[attr];
            }

            if ((segmentIndex !== undefined) && (topExercise.exercises !== undefined) &&
                (topExercise.exercises[segmentIndex] !== undefined)) {
                const bottomExercise = topExercise.exercises[segmentIndex];

                if (bottomExercise[attr] !== undefined) {
                    value = bottomExercise[attr];
                }
            }
        }
        return value;
    },

    calcGroupSegmentDuration(attr, workoutPerform, groupPerform, segmentIndex) {
        let value = workoutPerform[attr];

        const groupAttr = groupPerform?.[attr];
        const segmentAttr = groupPerform?.segments?.[segmentIndex]?.[attr];

        if ((segmentAttr !== undefined) && (segmentAttr !== null)) {
            value = segmentAttr;
        } else if ((groupAttr !== undefined) && (groupAttr !== null)) {
            value = groupAttr;
        }
        return value;
    },

    // Modifies passed in exercises and workoutPerform based on the internal timings
    sortSynced(exercises, workoutPerform) {
        let sortList = exercises.map((exercise, index) => {
            return {exercise: exercise, perform: workoutPerform.exercises[index]};
        });

        sortList.sort((a, b) => {
            const a_start = a.perform && a.perform.start;
            const b_start = b.perform && b.perform.start;

            try {
                if (a_start && !b_start) {
                    return -1;
                } else if (!a_start && b_start) {
                    return 1;
                } else if (!a_start && !b_start) {
                    return 0;
                } else if (Number(a_start) < Number(b_start)) {
                    return -1;
                } else if (Number(a_start) > Number(b_start)) {
                    return 1;
                } else {
                    return 0;
                }
            } catch (err) {
                return 0;
            }
        });

        sortList.forEach((item, index) => {
            exercises[index] = item.exercise;
            workoutPerform.exercises[index] = item.perform;
        });
    }
};

export default PerformUtils;

