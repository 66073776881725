import React, {useContext} from 'react';
import {Button, Modal} from 'react-bootstrap';
import {NavContext} from "../../providers/nav_provider";

export default function AlertModal() {

    const {
        alertCancelLabel,
        alertConfirmLabel,
        alertConfirmFn,
        alertClasses,
        alertMessage,
        alertTitle,
        setShowAlertModal,
        showAlertModal,
        resetAlert
    } = useContext(NavContext);

    const handleCancel = (event) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        resetAlert();
    }

    const handleCloseAlert = (event) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        setShowAlertModal(false);
    }

    const handleConfirm = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (alertConfirmFn !== undefined) {
            alertConfirmFn();
        }
        resetAlert();
    }

    const noLabel = (alertCancelLabel) ? alertCancelLabel : 'Cancel';
    const yesLabel = (alertConfirmLabel) ? alertConfirmLabel : 'Ok';
    const myClasses = (alertClasses ? alertClasses : 'alert-modal');

    let textClasses = 'd-flex justify-content-center';
    textClasses += alertTitle ? '' : ' pt-2';

    return (
        <Modal contentClassName={myClasses}
               animation={false}
               show={showAlertModal}
               onClick={(event) => handleCancel(event)}
               onHide={(event) => handleCancel(event)}>
            {alertTitle &&
                <>
                    <Modal.Header>
                        <Modal.Title>{alertTitle}</Modal.Title>
                    </Modal.Header>
                    <hr/>
                </>
            }
            <Modal.Body className={textClasses}>
                {alertMessage}
            </Modal.Body>
            <Modal.Footer>
                {(alertConfirmFn !== null) &&
                    <>
                        <Button variant="secondary" onClick={(event) => handleCloseAlert(event)}>{noLabel}</Button>
                        <Button variant="primary" onClick={(event) => handleConfirm(event)}>{yesLabel}</Button>
                    </>
                }
                {alertConfirmFn === null &&
                    <Button variant="primary" onClick={(event) => handleCancel(event)}>Ok</Button>
                }
            </Modal.Footer>
        </Modal>
    );
}