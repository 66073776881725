import React, {useContext, useState} from 'react';
import {Button, Navbar, Form} from 'react-bootstrap';
import {useLocation, useNavigate} from "react-router-dom";
import API from "../../utils/api";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faStar} from "@fortawesome/free-solid-svg-icons";
import {ConfigContext} from "../../utils/config_context";
import EditButton from "../common/edit_button";
import AddButton from "../common/add_button";
import {NavContext} from "../../providers/nav_provider";

export default function NavEditButtons(props) {
    const {
        handleAddButton, handleFeatureButton, handleSave, isDisabled, isFeatured, mode,
        reload, resourceId, resourceName, resourceUrlStub, saveDisabled, showAddButton, showEditButton
    } = props;

    const {dirty, setAlert} = useContext(NavContext);
    const config = useContext(ConfigContext);
    const urlStub = resourceUrlStub || `${resourceName}s`;

    let location = useLocation();
    let navigate = useNavigate();

    const star = <span className='pe-1'><FontAwesomeIcon icon={faStar}/></span>;
    const chevron = <span className='pe-1'><FontAwesomeIcon icon={faChevronLeft}/></span>;

    const handleAlertConfirm = () => {
        navigate('..', {relative: 'path'});
        reload();
    }

    const handleCancel = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (dirty) {
            setAlert({
                title: null, 
                message: 'Unsaved changes will be lost', 
                show: true,
                confirmFn: () => handleAlertConfirm, 
                confirmLabel: 'Yes', 
                cancelLabel: 'No'
            });
        } else {
            navigate(-1);
        }
    };


    const handleDeleteConfirm = () => {
        API.del(`${config.apiBase}/${urlStub}/${resourceId}`)
            .then(result => {
                    navigate(`/${urlStub}/search`)
                }
            )
            .catch(error => {
                alert(error.message)
            });
        return;
    };

    const handleDelete = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setAlert({
            title: null,
            message: `Are you sure you want to delete this ${resourceName}?`,
            confirmFn: () => handleDeleteConfirm,
            show: true
        });
    };

    const handleEditButton = () => {
        navigate(`${location.pathname}/edit?${location.search}`);
    };

    const renderEditNewButtons = () => {
        return (
            <div className="nav-link px-1">
                <Button variant="secondary" className="btn btn-sm"
                        onClick={handleCancel} disabled={isDisabled}>Cancel</Button>
            </div>
        )
    };

    const renderShowButtons = () => {
        return (
            <>
                <div className="nav-link px-1">
                    <Button variant='primary' className="btn btn-sm" disabled={isDisabled}
                            onClick={() => navigate(-1)}>{chevron}</Button>
                </div>
                {handleFeatureButton &&
                    <div className="nav-link px-1">
                        <Button variant={isFeatured ? 'warning' : 'secondary'} className="btn btn-sm"
                                disabled={isDisabled} onClick={handleFeatureButton}>{star}</Button>
                    </div>
                }
                {showEditButton &&
                    <div className="nav-link px-1">
                        <EditButton clickHandler={handleEditButton} disabled={isDisabled}/>
                    </div>
                }
            </>
        );
    };

    return (
        <Form id="nav-edit-buttons-form" className="d-flex w-100 justify-content-between" inline='true'>
            {(mode === 'edit' || mode === 'new') && renderEditNewButtons()}
            {(mode === 'show') && renderShowButtons()}
            {(mode === 'edit') &&
                <div className="nav-link px-1">
                    <Button variant="danger" className="btn btn-sm"
                            onClick={handleDelete} disabled={isDisabled}>Delete</Button>
                </div>
            }
            {showAddButton &&
                <div className="nav-link px-1">
                    <AddButton classes="btn-sm" clickHandler={handleAddButton} isDisabled={isDisabled}/>
                </div>
            }
            {(mode === 'edit' || mode === 'new') &&
                <div className="nav-link px-1">
                    <Button variant="primary" className="btn btn-sm" disabled={saveDisabled || isDisabled}
                            onClick={handleSave}>Save</Button>
                </div>
            }
        </Form>
    );
}
