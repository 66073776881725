import React, {useContext, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser, faCamera} from "@fortawesome/free-solid-svg-icons";
import UserImagesModal from "../modals/user_images_modal";
import {ConfigContext} from "../../utils/config_context";
import {UserContext} from "../../providers/user_provider";

const ProfileImage = (props) => {
    const {isOwnProfile, setShowProfileModal} = props;

    const userIcon = <span style={{fontSize: '4rem'}}><FontAwesomeIcon icon={faUser} className="pe-1"/></span>;
    const cameraIcon = <FontAwesomeIcon icon={faCamera} className="px-1"/>;

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const config = useContext(ConfigContext);
    const {user} = useContext(UserContext);

    const handleModalCloseEvent = () => {
        setModalIsOpen(false);
    }

    return (
        <>
            <div className="card bg-dark text-white">
                {user.thumb &&
                <img alt="profile"
                     className="img-fluid w-100 card-img"
                     src={`${config.s3Basepath}/${user.thumb}`}
                     onClick={(event) => {
                         setShowProfileModal(true);
                     }}/>
                }
                {!user.thumb &&
                <div className="img-fluid w-100 card-img d-flex justify-content-center">
                    {userIcon}
                </div>
                }
                {isOwnProfile &&
                <div className="card-icon-bottom-right" style={{background: 'black'}} onClick={() => setModalIsOpen(true)}>
                    {cameraIcon}
                </div>
                }
            </div>
            {modalIsOpen && <UserImagesModal handleModalClose={handleModalCloseEvent} {...props} /> }
        </>
    )
};

export default ProfileImage;
