export const Config = {
    initialize,
    debug,
    get
};

let innerConfig = {
    apiBase: process.env.REACT_APP_API_BASE,
    apiHost: process.env.REACT_APP_API_HOST,
    debug: process.env.REACT_APP_DEBUG,
    gaCode: process.env.REACT_APP_GA_CODE || 'G-8B0MQ2576C',
    gaDebug: process.env.REACT_APP_GA_DEBUG,
    gaSampleRate: process.env.REACT_APP_GA_SAMPLE_RATE || 100,
    youTubeDebug: process.env.REACT_APP_YOU_TUBE_DEBUG,
    isClipsEnabled: true,
    iosDevice: false,
    maxFileSizes: {
        exerciseImageMb: 30,
        workoutImageMb: 10,
        userImageMb: 10
    },
    recaptchaEnabled: process.env.REACT_APP_RECAPTCHA_ENABLED || false,
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY || null,
    sentry: {
        dsn: process.env.REACT_SENTRY_DSN || 'https://f86951cdf888468f9e9287ea2d7e2a06:c77d6544617f4e938dbd48781e903a25@o1086967.ingest.sentry.io/6099589',
        release: process.env.REACT_SENTRY_RELEASE || 'production-',
        environment: process.env.REACT_SENTRY_ENV || 'production'
    },
    s3Basepath: process.env.REACT_APP_S3_BASEPATH,
    sitename: process.env.REACT_APP_SITENAME || 'FastfitGx',
    swrRetryOffline: process.env.REACT_SWR_RETRY_OFFLINE,
    userManualLink: process.env.REACT_APP_USER_MANUAL_LINK || 'https://docs.google.com/document/d/e/2PACX-1vQ3ID8dX5-gV0q3WLr7eI2QldBZyoG4ldgcYDua6_CGUbIOu6WubDWkyfKdwQi3v-kJ1fQpo8lWM0Nz/pub'
};

const stagingNames = ['staging.fastfitgx.com', 'staging-fastfitgx.herokuapp.com'];

function initialize()  {
    debug("config.initialize() called");
    const hostname = window?.location?.hostname;

    if (hostname === 'localhost') {
        innerConfig.apiBase ||= 'http://localhost:3007/api';
        innerConfig.apiHost ||= 'http://localhost:3007';
        innerConfig.s3Basepath ||= 'http://localhost:3007';
    } else if (stagingNames.includes(hostname)) {
        innerConfig.apiBase ||= 'https://apistaging.fastfitgx.com/api';
        innerConfig.apiHost ||= 'https://apistaging.fastfitgx.com';
        innerConfig.s3Basepath ||= 'https://fastfit-staging.s3.amazonaws.com';
    } else if ((hostname === 'fastfitgx.com') || (hostname === 'www.fastfitgx.com')) {
        innerConfig.apiBase ||= 'https://api.fastfitgx.com/api';
        innerConfig.apiHost ||= 'https://api.fastfitgx.com';
        innerConfig.s3Basepath ||= 'https://fastfit.s3.amazonaws.com';
    }
}

function debug(object) {
    if (innerConfig.debug) {
        console.log(object);
    }
}

function get() {
    return innerConfig;
}
