import React, {useContext, useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import {useLocation, useNavigate} from "react-router-dom";
import {Container, Form} from 'react-bootstrap';
import * as Yup from 'yup';
import queryString from 'query-string';
import {UserService} from '../../services/user_service';
import {Button} from "react-bootstrap";
import {yupResolver} from "@hookform/resolvers/yup";
import HeartbeatImg from "../common/heartbeat_img";
import {NavContext} from "../../providers/nav_provider";
import {UserContext} from "../../providers/user_provider";

export default function PasswordReset(props) {
    let navigate = useNavigate();

    const {setUser, user} = useContext(UserContext);
    const {setAlert} = useContext(NavContext);

    const [serverError, setServerError] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const {search} = useLocation();
    const values = queryString.parse(search);

    useEffect(() => {
        if (user.id && UserService.authToken) {
            setAlert({autoClose: 2000, title: null, message: 'You are logged in', show: true});
            navigate('/');
        }
    });

    const submitForm = (data) => {
        setSubmitting(true);
        setServerError(null);
        UserService.setPassword({
            user: {
                password: data.password,
                password_confirmation: data.password,
                reset_password_token: values.token
            },
            turbo: false
        })
            .then(
                (data) => {
                    setSubmitting(false);
                    if (data.user) {
                        setAlert({autoClose: 3000, title: null, message: 'Your password has been changed, and you are now logged in', show: true});
                        setUser(data.user);
                        UserService.handleUserLoggedIn(data.user, navigate);
                    } else {
                        navigate("/login");
                    }
                }
            )
            .catch(err => {
                    setSubmitting(false);
                    setServerError(err.message)
                }
            );
    }

    const validationSchema = Yup.object()
        .shape(UserService.fieldValidation('password'));

    const {
        register,
        handleSubmit,
        formState: {errors}
    } = useForm({
        defaultValues: {
            password: ''
        },
        mode: 'onBlur',
        resolver: yupResolver(validationSchema),
        reValidateMode: 'onChange'
    });

    return (
        <Container className="vw-100 vh-100 d-flex justify-content-center align-items-center">
            <div className="d-flex justify-content-center">
                <div className="reset-password-form">
                    <div className="text-center mb-4">
                        <img alt="fastfit_logo"
                             src="/assets/images/fastfit_gx_watermark_source1.png"/>
                    </div>
                    <Form id="reset-password-form" onSubmit={handleSubmit(submitForm)}>
                        <Form.Group>
                            <label>Set a new Password:</label>
                            <input
                                name="password"
                                type="text"
                                {...register('password')}
                                className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                            />
                            <div className="invalid-feedback">{errors.password?.message}</div>
                        </Form.Group>
                        {serverError &&
                            <Form.Group className="mt-2">
                                <div className={'alert alert-danger'}>{serverError}</div>
                            </Form.Group>
                        }
                        {submitting &&
                            <Form.Group className="d-flex my-0 w-100 mt-2">
                                <HeartbeatImg/>
                            </Form.Group>
                        }
                        <div className="form-group d-flex my-0 w-100 justify-content-between mt-4">
                            <Button type="submit" className="btn btn-primary">
                                Set Password
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
        </Container>
    )
};