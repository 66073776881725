import {Button} from "react-bootstrap";
import React from "react";
import { useNavigate } from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons";

const EditButton = (props) => {
    const {clickHandler, label, newUrl} = props;
    const pencil = <FontAwesomeIcon icon={faPencilAlt}/>;

    let navigate = useNavigate();

    function handleClick(event) {
        if (clickHandler) {
            event.preventDefault();
            event.stopPropagation();
            clickHandler()
        } else {
            navigate(newUrl);
        }
    }

    return (
        <Button variant="secondary" className="btn btn-sm"
                onClick={handleClick}>{pencil} {label ? label : 'Edit'}</Button>
    )
}

export default EditButton;
