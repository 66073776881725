import React, {useContext, useImperativeHandle, useState} from "react";
import {SwsAnalytics} from "../../services/sws_analytics";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCloudUploadAlt} from "@fortawesome/free-solid-svg-icons";
import {DirectUpload} from "@rails/activestorage";
import ObjectDefaults from "../../utils/object_defaults";
import API from "../../utils/api";
import {ConfigContext} from "../../utils/config_context";

const UploadImage = React.forwardRef((props, ref) => {
    const {children, analyticsCategory, handleNewImage, maxMb, setUploadError, uploadUrl} = props;
    const [uploadImage, setUploadImage] = useState(ObjectDefaults.image());
    const cloudUpload = <FontAwesomeIcon icon={faCloudUploadAlt} className="px-1"/>

    const config = useContext(ConfigContext);

    function analytics(action) {
        SwsAnalytics.event({
            category: analyticsCategory,
            action: action
        });
    }

    // ToDo: send Sentry errors
    useImperativeHandle(ref, () => ({
        clear: () => {
            setUploadImage(ObjectDefaults.image());
        },
        upload: (file) => {
            analytics('Init');

            if (file.size > (maxMb * 1024 * 1024)) {
                setUploadError(`Filesize limited to ${maxMb} mb`);
                return false;
            }

            let tempUploadFile = URL.createObjectURL(file);

            setUploadImage(Object.assign({}, uploadImage, {
                animationState: 'uploading',
                tempUploadFile: tempUploadFile
            }));
            analytics('Start');

            return new Promise((resolve, reject) => {
                const upload = new DirectUpload(
                    file,
                    `${config.apiHost}/rails/active_storage/direct_uploads`,
                    this
                );
                upload.create((error, blob) => {
                    if (error) {
                        analytics('Upload Error');
                    } else {
                        analytics('Upload Success');
                        resolve(blob);
                    }
                });
            }).then(result2 => {
                analytics('Created')
                return API.post(uploadUrl, {...result2});

            }, error => {
                analytics('Creation Failed')
                setUploadError(JSON.stringify(error));

            }).then(data => {
                if (data === undefined) {
                    setUploadError("Image failed to save");
                    analytics('Failed');
                } else {
                    setUploadError('');
                    handleNewImage(data);
                    setUploadImage(ObjectDefaults.image());
                    analytics('Added to User')
                }
            }, error => {
                setUploadError(JSON.stringify(error));
            });
        }
    }));

    // ToDo: pass in a defaultImage to show if an uploadedImage is not there

    // ToDo: test uploading a new image when we already have one

    return (
        <>
            <div className="card">
                {uploadImage.tempUploadFile &&
                    <div className="d-flex justify-content-center bg-dark">
                        <img className="card-img image-preview" alt="upload" src={uploadImage.tempUploadFile}/>
                    </div>
                }
                {uploadImage.tempUploadFile && (uploadImage.animationState === 'uploading') &&
                    <div className="card-icon-bottom-right fadeInAndOut" style={{background: 'black'}}>
                        {cloudUpload}
                    </div>
                }
            </div>
            {!uploadImage.tempUploadFile && children}
        </>
    )
});
export default UploadImage;
