import React from "react";
import TagWrapper from "./tag_wrapper";
import _ from "lodash";

const MediaTypeWrapper = (props) => {
    const { children, itemType, label, mediaTypes, setMediaTypes } = props;

    const handleMediaClick = () => {
        setMediaTypes([itemType]);
    }

    return (
        <TagWrapper tagName={label} selected={(mediaTypes.length === 0) ||
            (Array.isArray(mediaTypes) && mediaTypes.includes(itemType))} clickHandler={handleMediaClick}>
            {children}
        </TagWrapper>
    )
};

export default MediaTypeWrapper;
