import {Config} from '../utils/config';
import HttpTools from '../utils/http_tools';
import * as Yup from "yup";
import AuthenticationError from "../errors/authentication_error";

export const UserService = {
    get authToken() {
        return localStorage.getItem('jwt_token');
    },
    fieldValidation,
    handleUserLoggedIn,
    login,
    logout,
    register,
    sendResetPasswordEmail,
    setPassword,
    setUserValue,
    update
};

function fieldValidation(field) {
    let validation = {}

    switch (field) {
        case 'username':
            validation[field] = Yup.string()
                .required('Username is required')
                .min(2, 'Username must be at least 2 characters')
                .max(20, 'Username must not exceed 20 characters');
            break;
        case 'email':
            validation[field] = Yup.string()
                .required('Email is required')
                .max(255)
                .email('Must be a valid email');
            break;
        case 'password':
            validation[field] = Yup.string()
                .required('Password is required')
                .min(6, 'Password must be at least 6 characters')
                .max(128, 'Password must not exceed 128 characters');
            break;
        case 'description':
            validation[field] = Yup.string().max(255);
            break;
        case 'link':
            validation[field] = Yup.string().url();
            break;
        default:
            validation[field] = null;
    }

    return validation;
}

function getAuthToken() {
    const authToken = UserService.authToken;

    if (!authToken) {
        throw new AuthenticationError();
    }
    return authToken;
}

function handleUserLoggedIn(user, navigate) {
    if (user.numWorkouts === 0) {
        navigate("/workouts");
    } else if (user.slug) {
        navigate(`/users/${user.slug}`);
    } else {
        navigate("/");
    }
}

async function login(data) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(data)
    };

    const response = await fetch(`${Config.get().apiHost}/login`, requestOptions);
    if ([401, 403].indexOf(response.status) !== -1) {
        throw new Error('Invalid email or password');
    }

    try {
        return await HttpTools.handleResponse(response, true);
    } catch (e) {
        if (e instanceof AuthenticationError) {
            throw new Error('Invalid email or password')
        } else {
            throw e;
        }
    }
}

function logout() {
    // remove user from local storage to log user out
    localStorage?.removeItem('currentUser');
    localStorage?.removeItem('jwt_token');
    sessionStorage?.removeItem('new_exercise');
}

async function register(data) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
        body: JSON.stringify(data)
    };

    const response = await fetch(`${Config.get().apiHost}/signup`, requestOptions);

    return await HttpTools.handleResponse(response, true);
}

async function sendResetPasswordEmail(data) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(data)
    };

    const response = await fetch(`${Config.get().apiHost}/password`, requestOptions);
    try {
        return await HttpTools.handleResponse(response, false);
    } catch (e) {
        if (e instanceof AuthenticationError) {
            throw new Error('Invalid email or password');
        } else {
            throw e;
        }
    }
}

async function setPassword(data) {
    const requestOptions = {
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(data)
    };

    const response = await fetch(`${Config.get().apiHost}/password`, requestOptions);
    return await HttpTools.handleResponse(response, true);
}

function setUserValue(userObject) {
    if (userObject?.id) {
        localStorage.setItem('currentUser', JSON.stringify(userObject));
    } else {
        localStorage.removeItem('currentUser');
        localStorage.removeItem('jwt_token');
    }
}

async function update(user_id, data) {
    try {
        const requestOptions = {
            method: 'PATCH',
            headers: {
                'Authorization': getAuthToken(),
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };

        const response = await fetch(`${Config.get().apiBase}/users/${user_id}`, requestOptions);
        const result = await HttpTools.handleResponse(response, false);
        return result;

    } catch (e) {
        if (e instanceof AuthenticationError) {
            UserService.logout();
            window.location.reload(true);
        } else {
            throw e;
        }
    }
}
