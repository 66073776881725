import React, {useContext} from "react";
import {Link} from "react-router-dom";
import {ConfigContext} from "../../utils/config_context";

const UserAvatar = (props) => {
    const {mode, object} = props;
    const href = `/users/${object.user_slug}`;

    const config = useContext(ConfigContext);

    if (!object) return null;

    const renderAvatarName = () => {
        return (
            <img alt="thumb" className="img-fluid avatar-thumb"
                 src={`${config.s3Basepath}/${object.user_thumb}`}/>
        )
    }

    return (
        <>
            <div>
                {object.user_thumb &&
                <div className="d-flex justify-content-end">
                    {(mode === 'edit') && renderAvatarName()}
                    {(mode !== 'edit') && <Link to={href}> {renderAvatarName()} </Link> }
                </div>
                }
            </div>
            <div className="d-flex justify-content-end pt-1">
                {(mode === 'edit') && <h6>{object.username}</h6> }
                {(mode !== 'edit') && <Link to={href}> <h6>{object.username}</h6> </Link> }
            </div>
        </>
    );
};

export default UserAvatar;
