import React, {useContext, useRef, useState} from 'react';
import {Button, Col, Form, Modal, Row} from 'react-bootstrap';
import {ConfigContext} from "../../utils/config_context";
import ApiLoadingWrapper from "../common/api_loading_wrapper";
import AddButton from "../common/add_button";
import ProfileImageWrapper from "../users/profile_image_wrapper";
import {UserService} from "../../services/user_service";
import useSWR from "swr";
import {SwrUtils} from "../../utils/swr_utils";
import {DirectUpload} from "@rails/activestorage";
import * as Sentry from "@sentry/browser";
import {SwsAnalytics} from "../../services/sws_analytics";
import ObjectDefaults from "../../utils/object_defaults";
import API from "../../utils/api";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCloudUploadAlt} from "@fortawesome/free-solid-svg-icons";
import { UserContext } from '../../providers/user_provider';

export default function UserImagesModal(props) {
    const {handleModalClose, mutateUser} = props;

    const user = useContext(UserContext);
    const config = useContext(ConfigContext);
    const [userProfileImages, setUserProfileImages] = useState([]);
    const [currentProfileImageId, setCurrentProfileImageId] = useState(user.current_image_id);
    const [isSaving, setIsSaving] = useState(false);
    const [uploadError, setUploadError] = useState('');
    const hiddenFileInput = useRef(null);
    const cloudUpload = <FontAwesomeIcon icon={faCloudUploadAlt} className="px-1"/>

    const {data, mutate, error, isLoading} =
        useSWR((config.apiBase && user.id) ? `${config.apiBase}/users/${user.id}/images` : null,
            SwrUtils.authFetcher, {...SwrUtils.stdOptions, onSuccess: (data) => setUserProfileImages(data)});

    function handleCancel() {
        handleModalClose();
    }

    function handleDone() {
        if (user.current_image_id !== currentProfileImageId) {
            save();
        }
        handleModalClose();
    }

    function save() {
        setIsSaving(true);

        UserService.update(user.id, {user: {current_image_id: currentProfileImageId}})
            .then(data => {
                mutateUser();
            })
            .catch(err => {
                setUploadError(err.message);
            });
        setIsSaving(false);
        return;
    }

    const imgClasses = "img-responsive align-middle w-100 card-img";

    const mappedItems = userProfileImages?.map((image) => {
        if (!image.thumb) return null;

        let imageSrc = image.thumb;
        if (imageSrc && !imageSrc.match(/http/)) {
            imageSrc = `${config.s3Basepath}/${imageSrc}`;
        }

        return (
            <ProfileImageWrapper>
                {image.aasm_state === 'uploading' &&
                    <>
                        <img alt={`profile-${image.id}`} className={imgClasses} src={imageSrc}/>
                        <div className="card-icon-bottom-right fadeInAndOut" style={{background: 'black'}}>
                            {cloudUpload}
                        </div>
                    </>
                }
                {image.aasm_state !== 'uploading' &&
                    <img alt={`profile-${image.id}`}
                         className={`${imgClasses} ${image.id === currentProfileImageId ? 'selected' : ''}`}
                         src={imageSrc}
                         onClick={(event) => {
                             setCurrentProfileImageId(image.id);
                         }}/>
                }
            </ProfileImageWrapper>
        );
    });

    const handleClick = (event) => {
        setUploadError('');
        setUserProfileImages([...userProfileImages, ObjectDefaults.image2()]);
        hiddenFileInput.current.click();
    }

    function handleImageUpload(file) {

        if (file.size > (config.maxFileSizes.userImageMb * 1024 * 1024)) {
            setUploadError(`Filesize limited to ${config.maxFileSizes.userImageMb} mb`);
            return false;
        }

        return new Promise((resolve, reject) => {
            const upload = new DirectUpload(
                file,
                `${config.apiHost}/rails/active_storage/direct_uploads`,
                this
            );
            const newImage = Object.assign({}, userProfileImages.pop(), {
                thumb: URL.createObjectURL(file),
                aasm_state: 'uploading'
            });

            setUserProfileImages([...userProfileImages, newImage])

            SwsAnalytics.event({
                category: 'Image',
                action: 'Upload'
            });

            upload.create((error, blob) => {
                if (error) {
                    reject(error);
                } else {
                    resolve(blob);
                }
            });
        }).then(blob => {
            // API call to create ExerciseVideo
            SwsAnalytics.event({
                category: 'Image',
                action: 'Create'
            });
            return API.post(`${config.apiBase}/users/${user.id}/images`, {...blob});

        }).then(userImage => {
            setUploadError('');
            let newImage = Object.assign({}, userProfileImages.pop(), userImage);
            setCurrentProfileImageId(newImage.id);

            const newUserProfileImages = [userProfileImages, newImage];
            mutate(newUserProfileImages).then(() => {
                SwsAnalytics.event({
                    category: 'UserImageUpload',
                    action: 'Saved'
                });
            })
        }).catch(error => {
            SwsAnalytics.event({
                category: 'UserImageUpload',
                action: `Error: ${error}`
            });
            setUploadError(error);
        });
    }

    const renderImageUploadButton = () => {
        return (
            <>
                <AddButton clickHandler={handleClick}/>
                <Form.Control
                    type="file"
                    style={{display: 'none'}}
                    ref={hiddenFileInput}
                    id="inputGroupFile01"
                    onChange={e => {
                        handleImageUpload(e.target.files[0])
                    }}
                />
            </>
        );
    }
    const renderError = () => {
        return "An error has occurred.  Please reload the page."
    }

    return (
        <>
            <Modal id="user-images-modal" animation={false} show={true}
                   onHide={() => handleCancel()}
            >
                {error && renderError()}
                {!error &&
                    <>
                        <Modal.Header>
                            <Modal.Title>
                                Choose or Add Profile Image
                            </Modal.Title>
                            <Button className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                    onClick={() => {
                                        handleCancel()
                                    }}/>
                        </Modal.Header>
                        <Modal.Body>
                            <ApiLoadingWrapper
                                error={error}
                                isLoading={isLoading}
                            />
                            <Row>
                                {mappedItems}
                                {uploadError.length > 0 &&
                                    <Col xs={12}>
                                        <div className="d-flex w-100 pt-2 justify-content-center text-danger">
                                            {uploadError}
                                        </div>
                                    </Col>
                                }
                            </Row>
                        </Modal.Body>
                    </>
                }
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCancel}>Cancel</Button>
                    {!error && renderImageUploadButton()}
                    <Button variant="primary" disabled={isSaving} onClick={handleDone}>Ok</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}