import React, {useContext, useState} from 'react';
import {Button, Col, Form, Modal, Row} from 'react-bootstrap';
import HeartbeatImg from "../common/heartbeat_img";
import {UserService} from "../../services/user_service";
import * as Yup from "yup";
import {useForm, useFormState} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {NavContext} from "../../providers/nav_provider";

export default function ForgotPasswordModal(props) {
    const {hide, show} = props;

    const {setAlert} = useContext(NavContext);

    // ready, sending, sent
    const [sendingState, setSendingState] = useState('ready');
    const [serverError, setServerError] = useState(null);

    const validationSchema = Yup.object()
        .shape(UserService.fieldValidation('email'));

    const defaults = {email: null};

    const {
        control,
        register,
        handleSubmit,
        setError,
        formState: {errors}
    } = useForm({
        defaultValues: defaults,
        mode: 'onSubmit',
        resolver: yupResolver(validationSchema),
        reValidateMode: 'onChange'
    });

    const {dirtyFields} = useFormState({control});

    const handleSend = data => {
        setSendingState('sending');
        const userObject = {user: data}

        UserService.sendResetPasswordEmail(userObject, setError)
            .then(
                (data) => {
                    setAlert({autoClose: 3000, title: null, message: 'If you were already registered with the site, a password reset email has been sent', show: true});
                    setSendingState('sent');
                    hide();
                }
            )
            .catch(err => {
                setSendingState('ready');
                setServerError(err.message);
            });
        return;
    }

    return (
        <Modal animation={false} show={show} onHide={() => hide()}>
            <Form id="forgot-password-form" onSubmit={handleSubmit(handleSend)}>
                <Modal.Header>
                    <Modal.Title>Forgot your password?</Modal.Title>
                    <Button className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            onClick={() => hide()}/>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12}>
                            <input name="email"
                                   type="text"
                                   defaultValue=""
                                   {...register('email')}
                                   className={`form-control ${(errors['email'] && dirtyFields['email']) ? 'is-invalid' : ''}`}
                            />
                            <div
                                className="invalid-feedback">{dirtyFields['email'] ? errors['email']?.message : ''}</div>
                        </Col>
                    </Row>
                    <Row>
                        {sendingState === 'sending' &&
                        <div className="d-flex my-0 w-100 mt-2">
                            <HeartbeatImg/>
                        </div>
                        }
                        {serverError && <div>{serverError}</div>}
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <>
                        <Button variant="primary" type="submit">Send me a password reset link</Button>
                        <Button variant="secondary" onClick={() => hide()}>Cancel</Button>
                    </>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}