import {Button, Col, Form, Row} from "react-bootstrap";
import React, {useContext} from "react";
import {UserContext} from "../../providers/user_provider";

export default function WorkoutSelection(props) {
    const {addButtonCallback, isSaving, saveMode, setSaveMode} = props;
    const {currentOrLastWorkout, currentWorkout, setCurrentWorkout, user} = useContext(UserContext);

    // ToDo: change this to an attribute of the current logged in UserContext
    const handleCurrentWorkoutChange = (event) => {

        event.preventDefault();
        const workoutId = Number(event.target.value);
        const newCurrentWorkout = user.lastWorkouts.find(w => (Number(w.id) === workoutId));

        setCurrentWorkout(newCurrentWorkout);
    };

    let options = [];
    const defaultWorkout = currentOrLastWorkout();
    const defaultValue = defaultWorkout ? defaultWorkout.id : undefined;

    if (defaultWorkout) {
        options.push(
            <option key={`workout-option-0`} value={defaultValue}>{defaultWorkout.title}</option>
        );
    }

    user?.lastWorkouts?.forEach((workout, i) => {
        if (workout.id !== defaultValue) {
            options.push(
                <option key={`workout-option-${i + 1}`} value={workout.id}>{workout.title}</option>
            );
        }
    });

    if (options.length === 0) {
        return null;
    }

    if ((saveMode === 'addToWorkout') && defaultWorkout) {
        return (
            <div className="d-flex w-100 text-center">
                Adding to {defaultWorkout.title}
            </div>
        )
    }

    const handleAddExerciseToWorkoutButton = () => {
        setCurrentWorkout(currentOrLastWorkout());
        setSaveMode('addToWorkout');

        if (typeof addButtonCallback === 'function') {
            addButtonCallback();
        }
    };

    return (
        <Form.Group>
            <Row className='pb-2'>
                <Col xs={4}>
                    {(saveMode === 'none') &&
                    <Button variant="success"
                            size="sm"
                            className="mt-1"
                            disabled={isSaving}
                            onClick={handleAddExerciseToWorkoutButton}>
                        Add to
                    </Button>
                    }
                    {(saveMode === 'addToWorkout') && currentWorkout &&
                        <div className="d-flex w-100 text-center">
                            Adding to {currentWorkout.title}
                        </div>
                    }
                </Col>
                <Col xs={8}>
                    <Form.Control as="select"
                                  defaultValue={defaultValue}
                                  onChange={(event) => {
                                      handleCurrentWorkoutChange(event)
                                  }}>
                        {options}
                    </Form.Control>
                </Col>
            </Row>
        </Form.Group>
    );
}