import React from 'react';
import {createRoot} from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {SwsAnalytics} from "./services/sws_analytics";
import {Config} from "./utils/config";
import {ConfigContext} from "./utils/config_context";
import App from "./App";
import ObjectDefaults from "./utils/object_defaults";

const container = document.getElementById('root');
const root = createRoot(container);

Config.initialize();
SwsAnalytics.initialize();

root.render(
    <ConfigContext.Provider value={Config.get()}>
        <App system={ObjectDefaults.system()}/>
    </ConfigContext.Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
