import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

export default function SortableItem(props) {
    const Element = props.element || 'div';
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: props.id, disabled: props.disabled });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const onClick = (event) => {
        if (props.disabled && props.handleSortDisabledClick) {
            props.handleSortDisabledClick(event);
        }
    }

    return (
        <Element ref={setNodeRef} key={`sortable-${props.id}`} className={props.classes} style={style} {...attributes} {...listeners} onClick={onClick}>
            {props.children}
        </Element>
    );
}