import React, { useContext, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import { Col, Modal, Row } from 'react-bootstrap';

import EditProfile from "./edit_profile";
import ApiLoadingWrapper from "../common/api_loading_wrapper";
import ProfileImage from "../common/profile_image";
import WorkoutItem from "../workouts/workout_item";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import ObjectDefaults from "../../utils/object_defaults";
import InfiniteScroll from "react-infinite-scroll-component";
import ExerciseListItem from "../exercises/exercise_list_item";
import ExerciseModal from "../modals/exercise_modal";
import { UserService } from "../../services/user_service";
import { ConfigContext } from "../../utils/config_context";
import useSWR from "swr";
import { SwrUtils } from "../../utils/swr_utils";
import RecordNotFoundError from "../../errors/record_not_found_error";
import { SwsAnalytics } from "../../services/sws_analytics";
import { UserContext } from "../../providers/user_provider";

export default function ProfilePage(props) {
    const config = useContext(ConfigContext);
    const { setUser, user } = useContext(UserContext);

    let { userSlug } = useParams();
    const isOwnProfile = user && (userSlug === user.slug);

    // The profileUser json will have a nested user_image if isOwnProfile is true
    // But it will not be used until EditProfile is loaded

    const pencil = <FontAwesomeIcon icon={faPencilAlt} className="pe-1" />;
    const gotoArrow = <span style={{ fontSize: '1.7rem' }}><FontAwesomeIcon icon={faArrowCircleRight}
        className="pe-1" /></span>;
    const [otherUserProfile, setOtherUserProfile] = useState(ObjectDefaults.profile());
    const [isEdit, setIsEdit] = useState(false);
    const [currentExercise, setCurrentExercise] = useState(null);
    const [showExerciseModal, setShowExerciseModal] = useState(false);
    const [showProfileModal, setShowProfileModal] = useState(false);

    const userProfile = isOwnProfile ? user : otherUserProfile;

    const fetchUrl = isOwnProfile ? `${config.apiBase}/users/${user.id}` : `${config.apiBase}/users/${userSlug}`;

    // ToDo: split this into public profile page and private ones
    const { mutate, error, isLoading } = useSWR(config.apiBase ? fetchUrl : null,
        SwrUtils.authFetcher,
        {
            ...SwrUtils.stdOptions,
            onSuccess: (data) => {
                if (isOwnProfile) {
                    setUser(data?.user);
                } else {
                    setOtherUserProfile(data?.user);
                }
            }
        });

    const mutateUser = () => {
        mutate().then(() => {
            SwsAnalytics.event({
                category: 'UserProfile',
                action: 'Saved'
            });
        }, error => {
            SwsAnalytics.event({
                category: 'UserProfile',
                action: 'Failed in mutation'
            });
        });
    }

    const handleEditClick = (event) => {
        event.preventDefault();
        setIsEdit(true);
    };

    const renderViewProfileTop = () => {
        return (
            <>
                <Row className="profile-row">
                    <Col xs={{ span: 6, offset: 3 }} md={{ span: 4, offset: 4 }}>
                        <ProfileImage isOwnProfile={isOwnProfile}
                            setShowProfileModal={setShowProfileModal}
                            mutateUser={mutateUser}
                            {...props}
                            user={userProfile}
                        />
                    </Col>
                </Row>
                <Row className="profile-row">
                    <Col xs={9}>
                        <div>
                            {user.username}
                        </div>
                    </Col>
                    <Col xs={3}>
                        {isOwnProfile &&
                            <Link href="#" onClick={handleEditClick}>
                                {pencil} Edit
                            </Link>
                        }
                    </Col>
                </Row>
                <Row className="profile-row">
                    <div className="d-flex w-100 justify-content-center">
                        {user.link && <a href={user.link} target="_blank" rel="noreferrer">{user.link}</a>}
                    </div>
                </Row>
                <br />
                <Row className="profile-row">
                    <Col xs={2}>Bio</Col>
                </Row>
                <Row className="profile-row">
                    <Col xs={12}>
                        <div className="d-flex w-100 justify-content-center">
                            {user.description && user.description}
                        </div>
                    </Col>
                </Row>
                <br />
            </>
        )
    }

    const renderExerciseList = () => {
        let mapped_items = userProfile?.lastExercises?.map((exercise, i) => {
            return (
                <ExerciseListItem
                    exercise={exercise}
                    key={`profile-eli-${exercise.id}`}
                    index={i}
                    handleExerciseClick={() => {
                        setCurrentExercise(exercise);
                        setShowExerciseModal(true);
                    }}
                    showAuthor={false}
                    {...props}
                />
            );
        });

        mapped_items = mapped_items ? mapped_items : [];

        return (
            <div className="exercises">
                <Row className="profile-row">
                    <Col xs={10} className="pt-2">
                        Latest Exercises
                    </Col>
                    <Col xs={2}>
                        <Link to={`/exercises/search?user_slug=${userProfile.slug}`}>
                            {gotoArrow}
                        </Link>
                    </Col>
                </Row>
                {(mapped_items.length > 0) &&
                    <InfiniteScroll
                        dataLength={mapped_items.length}
                        hasMore={false}
                        loader={<div className="w-100 text-center">Loading...</div>}
                        className="list-scrollable pb-sm-3"
                    >
                        {mapped_items}
                    </InfiniteScroll>
                }
                <ExerciseModal {...props}
                    handleCloseModal={() => setShowExerciseModal(false)}
                    showExerciseModal={showExerciseModal}
                    exercise={currentExercise}
                />
            </div>
        );
    }

    const renderProfileImageModal = () => {
        if (!showProfileModal || !userProfile?.src) return null;
        const profileSrc = `${config.s3Basepath}/${userProfile.src}`;

        return (
            <Modal show={showProfileModal}
                onClick={() => setShowProfileModal(false)}
                onHide={() => setShowProfileModal(false)}>
                <Modal.Body>
                    <img className="w-100 img-fluid" alt="profile" src={profileSrc} />
                </Modal.Body>
            </Modal>
        );
    }

    const renderWorkoutList = () => {
        let mapped_items = userProfile?.lastWorkouts?.map((workout, i) => {
            return (
                <WorkoutItem
                    item={workout}
                    key={`workout-${workout.id}`}
                    showStars={true}
                    {...props}
                />
            )
        });

        mapped_items = mapped_items ? mapped_items : [];

        return (
            <div className="workouts">
                <Row className="profile-row">
                    <Col xs={10} className="pt-2">
                        Latest Workouts
                    </Col>
                    <Col xs={2}>
                        <Link to={`/workouts/search?user_slug=${userProfile.slug}`}>
                            {gotoArrow}
                        </Link>
                    </Col>
                </Row>
                {(mapped_items.length > 0) &&
                    <InfiniteScroll
                        dataLength={mapped_items.length}
                        hasMore={false}
                        loader={<div className="w-100 text-center">Loading...</div>}
                        className="list-scrollable pb-sm-3"
                    >
                        {mapped_items}
                    </InfiniteScroll>
                }
            </div>
        );
    }

    if (error) {
        const errorMessage = (error instanceof RecordNotFoundError) ? "No such user" : "An error has occurred.  Please reload the page."

        return (
            <div className="top-component">
                {errorMessage}
            </div>
        );
    }

    // ToDo: get rid of this?
    if (isLoading) {
        return (
            <div className="top-component">
                <ApiLoadingWrapper />
            </div>
        )
    }

    return (
        <>
            <div className="top-component">
                {!isOwnProfile && isLoading &&
                    <Row>
                        <Col xs={12}>
                            <ApiLoadingWrapper />
                        </Col>
                    </Row>
                }
                {!isEdit && (
                    <>
                        {renderViewProfileTop()}
                        {(userProfile.numExercises > 0) && renderExerciseList()}
                        {(userProfile.numWorkouts > 0) && renderWorkoutList()}
                    </>
                )}
                {isEdit && <EditProfile
                    setIsEdit={setIsEdit}
                    {...props} />
                }
            </div>
            {isOwnProfile && renderProfileImageModal()}
        </>
    )
};