import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Form, Navbar } from 'react-bootstrap';

import { useLocation, useNavigate, useParams } from "react-router-dom";

import ObjectDefaults from '../../utils/object_defaults';
import API from '../../utils/api';
import FormDataUtils from '../../utils/form_data_utils';
import ExerciseMediaForm from './exercise_media_form';

import NavEditButtons from '../nav/nav_edit_buttons';
import TagsWithModal from '../modals/tags_with_modal';
import GroupExerciseList from './group_exercise_list';
import LandingModal from '../modals/landing_modal';

import PublicToggle from '../common/public_toggle';
import QuestionIcon from '../common/question_icon';
import Information from '../../utils/information';
import ApiLoadingWrapper from '../common/api_loading_wrapper';
import { ConfigContext } from '../../utils/config_context';
import WorkoutItem from '../workouts/workout_item';
import InfiniteScroll from 'react-infinite-scroll-component';
import ExerciseClipMedia from '../exercises/exercise_clip_media';
import UserAvatar from '../common/user_avatar';
import useSWR from 'swr';
import { SwrUtils } from '../../utils/swr_utils';
import RecordNotFoundError from '../../errors/record_not_found_error';
import { NavContext } from "../../providers/nav_provider";
import { UserContext } from "../../providers/user_provider";
import YouTubeLogo from "../common/you_tube_logo";

export default function Exercise(props) {
    const { mode } = props;

    const {user} = useContext(UserContext);
    const config = useContext(ConfigContext);
    const [exercise, setExercise] = useState(ObjectDefaults.exercise());
    const [showLandingModal, setShowLandingModal] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [isPublic, setIsPublic] = useState(true);
    const [exerciseMediumId, setExerciseMediumId] = useState(null);
    const [exerciseMediumType, setExerciseMediumType] = useState(null);
    const [exerciseMediumContentType, setExerciseMediumContentType] = useState(null);
    const [segments, setSegments] = useState([]);
    const [tempUploadFile, setTempUploadFile] = useState(null);
    const [validated, setValidated] = useState(false);

    let navigate = useNavigate();
    let location = useLocation();
    let { exerciseId } = useParams();
    const exerciseForm = React.createRef();

    const { dirty, setAfterLoginUrl, setDirty, setAlert } = useContext(NavContext);

    const {
        error,
        mutate,
        isLoading
    } = useSWR((config.apiBase && exerciseId) ? `${config.apiBase}/exercises/${exerciseId}` : null,
        SwrUtils.authFetcher, {
        ...SwrUtils.stdOptions, onSuccess: (data) => {
            setExercise(data);
            setExerciseMediumId(data.preferred_medium_id);
            setExerciseMediumType(data.preferred_medium_type);
            setIsPublic(data.is_public ? true : false);
            setSegments(data.segments);
        }
    });

    useEffect(() => {
        if (mode === 'new' && !user?.id) {
            setAfterLoginUrl('/exercises/new');
            setAlert({
                autoClose: 3000,
                title: null,
                message: `Please register or login to create a new exercise`,
                show: true
            });
            navigate("/register");
            return;
        }

    }, [mode, navigate, setAfterLoginUrl, setAlert, user])

    useEffect(() => {
        if (mode === 'new') {
            if (!sessionStorage?.getItem('new_exercise')) {
                setAlert({ title: 'New Exercise', message: Information.exerciseNew(), show: true })
                setShowLandingModal(user?.id !== null);
                sessionStorage?.setItem('new_exercise', '1');
            }

            const queryParams = new URLSearchParams(location.search);
            if (queryParams.get('tag')) {
                let newExercise = Object.assign({}, ObjectDefaults.exercise());
                newExercise['tag_ids'] = queryParams.get('tag').map((n) => Number(n));
                setExercise(newExercise);
                setDirty(true);
            }
        }

    }, [location.search, mode, user, setAlert, setDirty]);

    const handleAddExercises = () => {
        navigate(`/exercises/${exercise.id}/add`);
    };

    /* ToDo: put this into an afterEffect function
    const handleAlertConfirm = () => {
        // should change mode to 'show', hopefully retriggering the loading of the exercise

        setTempUploadFile(null);
        navigate(-1);
    }
     */

    const handleChange = () => {
        setDirty(true);
    };

    const handleClearExerciseMediumEvent = () => {
        setExerciseMediumId(null);
    }

    const handleCloseLandingModal = () => {
        setShowLandingModal(false);
    };

    const handleExerciseData = (data) => {
        let newExercise = Object.assign({}, exercise);
        newExercise['clip'] = data;
        if (_.isEmpty(exercise.title) && !_.isEmpty(data.title)) {
            newExercise['title'] = data.title;
        }
        if (_.isEmpty(exercise.description) && !_.isEmpty(data.description)) {
            newExercise['description'] = data.description;
        }
        setExercise(newExercise);
        setDirty(true);
    }

    const handleReload = () => {
        if (exerciseId) {
            mutate(`${config.apiBase}/exercises/${exerciseId}`);
        } else {
            setExercise(ObjectDefaults.exercise());
        }
    }

    const handleSubmit = event => {
        event.preventDefault();

        const form = exerciseForm.current;

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
            return;
        }

        const formExerciseData = new FormData(form);

        if (!exercise.is_group) {
            if (exerciseMediumId) {
                formExerciseData.append('exercise_medium_id', exerciseMediumId.toString());
            } else {
                if (isUploading) {
                    setAlert({
                        title: 'Edit Exercise',
                        message: `Please wait for the ${exerciseMediumType.toString()} upload`,
                        show: true
                    });
                    return;
                } else if (!exercise.clip) {
                    setAlert({ title: 'Edit Exercise', message: 'Please upload a video clip or image', show: true });
                }
            }
        }

        if (exercise?.clip?.id) {
            formExerciseData.append('og_id', exercise.clip.id);
        }

        formExerciseData.set('title', formExerciseData.get('title').replace(/;/g, ''));
        if (segments) {
            formExerciseData.append("segment_ids", segments.map(s => s.id));
        }
        formExerciseData.append("is_public", isPublic ? 'true' : 'false');
        formExerciseData.append("tag_ids", exercise.tag_ids);

        if (exercise.id != null) {
            API.put(`${config.apiBase}/exercises/${exercise.id}`, FormDataUtils.formDataToJson(formExerciseData))
                .then(result => {
                    setDirty(false);
                    navigate(-1);
                    mutate();
                })
                .catch(error => {
                    alert(error.message);
                });
            return;
        }

        API.post(`${config.apiBase}/exercises`, FormDataUtils.formDataToJson(formExerciseData))
            .then(result => {
                setDirty(false);
                navigate(-1);
                mutate();
            })
            .catch(error => {
                alert(error.message);
            });
    };

    const handleTempUploadFile = (file) => {
        setTempUploadFile(file);
    }

    const handleTogglePublic = (event) => {
        if (mode === 'show') return;
        setIsPublic(!isPublic);
        setDirty(true);
    };

    const handleSetTagIds = (newTagIds) => {
        let newExercise = Object.assign({}, exercise);
        newExercise['tag_ids'] = newTagIds.map((n) => Number(n));
        setExercise(newExercise);
        setDirty(true);
    };

    const handleUpdateExerciseMedium = (object) => {
        if (object?.exerciseMediumId) {
            setExerciseMediumId(object.exerciseMediumId)
        }
        if (object?.exerciseMediumType) {
            setExerciseMediumType(object.exerciseMediumType);
        }
        if (object?.exerciseMediumContentType) {
            setExerciseMediumContentType(object.exerciseMediumContentType);
        }
        setDirty(true);
    };

    const renderImageOrOpengraphImage = () => {
        const showOpengraph = !tempUploadFile || exercise?.clip?.id;
        const showImageMediaForm = !exercise?.clip?.id || tempUploadFile;

        return (<>
            {showImageMediaForm && <Form.Group>
                <Form.Label>
                    <h6>Upload Video or Image</h6>
                </Form.Label>
                {(mode !== 'show') && <QuestionIcon onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    setAlert({ title: 'Exercise Media', message: Information.exerciseMediaClip(), show: true });
                }} />}
                <ExerciseMediaForm {...props}
                    exercise={exercise}
                    isUploading={isUploading}
                    tempUploadFile={tempUploadFile}
                    handleTempUploadFile={handleTempUploadFile}
                    setIsUploading={setIsUploading}
                    size="md"
                    exerciseMediumId={exerciseMediumId}
                    exerciseMediumType={exerciseMediumType}
                    exerciseMediumContentType={exerciseMediumContentType}
                    updateExerciseMedium={handleUpdateExerciseMedium} />
            </Form.Group>}
            {showOpengraph && config.isClipsEnabled &&
                <ExerciseClipMedia {...props}
                    classes="w-100 img-responsive"
                    clipMode="video"
                    exercise={exercise}
                    handleExerciseData={handleExerciseData}
                    handleClearExerciseMedium={handleClearExerciseMediumEvent}
                    playerShouldBePlaying={false}
                    preloadedComponent={<h6>Or Paste YouTube Link</h6>}
                    setExercise={setExercise}
                />}
        </>);
    }

    const renderExerciseHeaderChannelInfo = () => {
        const channelTitle = exercise?.clip?.youtube_channel?.title ? ` ${exercise?.clip?.youtube_channel?.title}'s` : ' YouTube';

        return (<>
            <Row className="exercise-title px-2">
                {exercise.title}
            </Row>
            <Row>
                <Col xs={8} className="ps-sm-3 pt-3">
                    {exercise?.clip?.youtube_channel && <table>
                        <tbody>
                            <tr style={{ fontSize: '1rem' }}>
                                <a href={`https://www.youtube.com/channel/${exercise?.clip?.youtube_channel?.channel_id}`}
                                    target="_blank" rel="noreferrer">
                                    <td className="align-middle pe-2">
                                        <div className="text-center" style={{ zIndex: "1", position: "relative" }}>
                                            <YouTubeLogo />
                                        </div>
                                    </td>
                                    <td className="align-middle pe-2">
                                        {channelTitle}
                                    </td>
                                </a>
                            </tr>
                        </tbody>
                    </table>}
                </Col>

                <Col xs={4}>
                    <UserAvatar mode={mode} object={exercise} />
                </Col>
            </Row>
        </>);
    }

    const renderExerciseHeader = () => {
        const titleLabel = exercise.is_group ? "Exercise Group Title" : "Title";

        return (<>
            {renderExerciseHeaderChannelInfo()}
            <Form.Group>
                {mode !== 'show' && <Row>
                    <Col xs={3} className="pt-1">
                        <Form.Label>
                            <h5>
                                {titleLabel}
                            </h5>
                        </Form.Label>
                    </Col>
                    <Col xs={9}>
                        <Form.Control required
                            type="text"
                            name="title"
                            placeholder="Title"
                            onChange={handleChange}
                            defaultValue={exercise.title} />
                        <Form.Control.Feedback type="invalid">
                            Please set an Exercise title
                        </Form.Control.Feedback>
                    </Col>
                </Row>}
            </Form.Group>
            {(mode !== 'show') && <Form.Group controlId="formBasicText6">
                <Form.Label>
                    <h5>Description</h5>
                </Form.Label>
                <Form.Control type="text" as="textarea" rows="3" name="description"
                    placeholder="Description - this will be displayed when exercise is linked to directly"
                    defaultValue={exercise.description}
                    onChange={handleChange} />
            </Form.Group>}
            {(mode === 'show') && exercise.description && <div className="reference w-100">
                {exercise.description}
            </div>}
        </>)
    }


    // ToDo: can we turn this into a common function ?
    if (error) {
        const message = (error instanceof RecordNotFoundError) ? "No such exercise" : "An error has occurred.  Please reload the page."

        return (<div className="top-component">
            {message}
        </div>)
    }

    if (isLoading) {
        return (<div className="top-component">
            <ApiLoadingWrapper />
        </div>)
    }

    if (exerciseId === null && mode !== 'new') return null;

    const hasSegments = Array.isArray(segments) && (segments?.length > 0);
    const userCanEdit = user?.is_admin || (exercise.username === user?.username) || ((exercise.id === null) && (mode === 'new'));
    const showAddButton = exercise.is_group && userCanEdit;
    let showEditButton = user?.id && userCanEdit;

    return (<>
        <div className="top-component">
            <Row className="px-2">
                <Col lg={12}>
                    <Form id="exercise-form" noValidate ref={exerciseForm} validated={validated} onSubmit={handleSubmit}>
                        {renderExerciseHeader()}
                        {!hasSegments && !exercise.is_group && renderImageOrOpengraphImage()}
                        <TagsWithModal
                            {...props}
                            exercise={exercise}
                            tagIds={exercise.tag_ids}
                            setTagIds={handleSetTagIds}
                        />
                        <Form.Group>
                            {(mode !== 'show') && <Row>
                                <Col xs={4}>
                                    <Form.Label>
                                        <h5>Link</h5>
                                    </Form.Label>
                                </Col>
                                <Col xs={8}>
                                    <Form.Control type="text" name="reference_link"
                                        placeholder="Link URL"
                                        onChange={handleChange}
                                        defaultValue={exercise.reference_link} />
                                </Col>
                            </Row>}
                            {(mode === 'show') && exercise.reference_link &&
                                <div className="reference w-100 text-center">
                                    <a href={exercise.reference_link} rel="noreferrer"
                                        target="_blank">{exercise.reference_text ? exercise.reference_text : "Reference"}</a>
                                </div>}
                        </Form.Group>
                        {(mode !== 'show') && <Form.Group>
                            <Row>
                                <Col xs={4}>
                                    <Form.Label>
                                        <h5>Link Text</h5>
                                    </Form.Label>
                                </Col>
                                <Col xs={8}>
                                    <Form.Control type="text" name="reference_text"
                                        placeholder="Link Text"
                                        onChange={handleChange}
                                        value={exercise.reference_text} />
                                </Col>
                            </Row>
                        </Form.Group>}
                        {exercise.is_group && <GroupExerciseList
                            {...props}
                            segments={segments}
                            setSegments={setSegments}
                        />}
                        <PublicToggle handleTogglePublic={handleTogglePublic}
                            isPublic={isPublic}
                            mode={mode} />
                        {exercise.workouts ? (<div className='mx-n3 w-125'>
                            {exercise.workouts.length ? (<Col xs={10}>Workouts:</Col>) : (<></>)}
                            <InfiniteScroll
                                dataLength={exercise.workouts.length}
                                hasMore={false}
                                loader={<div className="w-100 text-center">Loading...</div>}
                                className="list-scrollable pb-sm-3"
                            >
                                {exercise.workouts.map((workout) => (<WorkoutItem
                                    item={workout}
                                    key={`workout-${workout.id}`}
                                    showStars={true}
                                    {...props}
                                />))}
                            </InfiniteScroll>
                        </div>) : (<></>)}
                        <Form.Group>
                            <Row className="my-5" />
                        </Form.Group>
                    </Form>
                </Col>
            </Row>
        </div>
        <Navbar fixed="bottom" bg="dark" className="justify-content-between">
            <NavEditButtons
                {...props}
                dirty={dirty}
                handleAddButton={handleAddExercises}
                showEditButton={showEditButton}
                reload={handleReload}
                resourceName="exercise"
                resourceId={exerciseId}
                showAddButton={showAddButton}
                handleSave={handleSubmit}
            />
        </Navbar>
        {(mode === 'show') && <LandingModal
            item={exercise}
            closeLandingModal={handleCloseLandingModal}
            show={showLandingModal}
            {...props} />}
    </>);

    /*
    <Form.Group controlId="equipment-teaser">
        <Form.Label>
            <h4>
                Equipment
            </h4>
            <span onClick={() => this.handleEquipmentClick()}>None</span>
        </Form.Label>
    </Form.Group>

                            {(mode !== 'show') &&
                            <div className='mt-n5'>
                                <UserAvatar
                                    mode={mode}
                                    object={exercise}
                                />
                            </div>
                            }
    */

}