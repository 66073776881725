import React from 'react';
import {Config} from "../../utils/config";
import {Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCog, faCopy, faFileVideo} from "@fortawesome/free-solid-svg-icons";
import * as Sentry from "@sentry/browser";

class WorkoutShareItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showCopyNotice: false
        };
    };

    async copyImage(event) {
        event.preventDefault();

        try {
            /*
            const {item, location} = this.props;
            const imageUrl = `${location.origin}/${item.summary_image}`;
            const data = await fetch(imageUrl);
            const blob = await data.blob();

            await navigator.clipboard.write([
                ClipboardItem.ClipboardItem({
                    [blob.type]: blob
                })
            ]);

             */

            this.setState({showCopyNotice: true});
            setTimeout(() => {
                this.setState({showCopyNotice: false});
            }, 1500);

        } catch (e) {
            Sentry.captureEvent(e);
        }
    };

    render() {
        const {index, item} = this.props;
        const {showCopyNotice} = this.state;
        const cog = <FontAwesomeIcon icon={faCog} size="3x" spin/>;

        const linkTo = item.is_summary ? `/workouts/${item.workoutId}` : `/workouts/${item.workoutId}/perform`;

        return (
            <Col xs={12} sm={6} md={4} className="card-wrapper">
                <div key={`item-${index}`} className="card border-dark card-emphasis">
                    <div className="d-flex w-100 card-header justify-content-between align-middle">
                        <span className="align-middle">
                            {this.renderStateLabel(item)}
                        </span>
                        <span className="align-middle">
                            {showCopyNotice && <b>Copied</b>}
                            {!showCopyNotice && this.renderCopyLink()}
                        </span>
                    </div>
                    <Link to={linkTo}>
                        {(item.summary_image !== null) &&
                        <img className="card-img-top"
                             alt="summary_image"
                             src={`${Config.get().s3Basepath}/${item.summary_image}`}/>
                        }
                        {(item.summary_image === null) &&
                        <div className="text-center">
                            <div className="p-2 loading-wrapper">
                                {cog}
                            </div>
                            <span className="align-middle">
                                Building video.<br/>
                                Reload to see updates
                            </span>
                        </div>
                        }
                    </Link>
                </div>
            </Col>
        );
    };

    renderCopyLink = () => {
        const copyIcon = <FontAwesomeIcon icon={faCopy} size="2x" color="blue"/>;

        return (
            <Link to="#" onClick={async (event) => {await this.copyImage(event)}}>
                {copyIcon}
            </Link>
        );
    };

    renderStateLabel = (item) => {
        const download = <FontAwesomeIcon icon={faFileVideo} size="2x" color="blue"/>;

        switch (item.aasm_state) {
            case 'idle':
            case 'conversion_in_progress':
                return (
                    <p className="card-text">Building</p>
                );
            case 'conversion_done':
                if (item.video) {
                    return (
                        <a href={`${Config.get().s3Basepath}/${item.video}`} rel="noreferrer" target="_blank" download>{download}</a>
                    );
                }
                return (
                    <p className="card-text">Error</p>
                );
            case 'conversion_error':
                return (
                    <p className="card-text">Error</p>
                );
            default:
                return null;
        }
    };
}

export default WorkoutShareItem;

/*  <Link href="#" onClick={async (url) => {await this.asyncTest(url)}}>  */
/*


 */
