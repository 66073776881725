import React, {useContext, useState} from 'react';
import {Button, Col, Row} from "react-bootstrap";
import UserEditModal from "../modals/user_edit_modal";
import ProfileSection from "./profile_section";
import {UserContext} from "../../providers/user_provider";

const EditProfile = (props) => {
    const {setIsEdit} = props;

    const {user} = useContext(UserContext);
    const [modalEditField, setModalEditField] = useState(null);

    const handleCloseEdit = (event) => {
        event.preventDefault();
        setIsEdit(false);
    }

    return (
        <>
            <Row>
                <Col xs={10} className="mx-auto">
                    <h3 className="ms-1">Edit Profile</h3>
                </Col>
                <Col xs={2}>
                    <Button className="btn-close btn-sm me-1" aria-label="Close"
                            onClick={handleCloseEdit}/>
                </Col>
            </Row>
            <hr style={{marginBottom: '.25rem', marginTop: '.25rem'}}/>
            <Row className="subheader-row pt-2">
                <Col lg={12} className="ms-1">
                    <ProfileSection fieldName="email"
                                    onEdit={(value) => setModalEditField(value)}>
                        {user.email}
                    </ProfileSection>
                    <ProfileSection fieldName="username"
                                    onEdit={(value) => setModalEditField(value)}>
                        {user.username}
                    </ProfileSection>
                    <ProfileSection fieldName="password"
                                    onEdit={(value) => setModalEditField(value)}>
                        ********
                    </ProfileSection>
                    <ProfileSection fieldName="link"
                                    onEdit={(value) => setModalEditField(value)}>
                        {user.link &&
                        <a href={user.link} rel="noreferrer" target="_blank">{user.link}</a>
                        }
                        {!user.link &&
                        <div>You can feature your own website here</div>
                        }
                    </ProfileSection>
                    <ProfileSection fieldName="bio"
                                    colParams={{span: 12}}
                                    onEdit={(value) => setModalEditField(value)}>
                        <p className="m-2">
                            {user.bio && user.bio}
                            {!user.bio && <div>
                                Bio will be automatically displayed when others view your workout
                            </div>}
                        </p>
                    </ProfileSection>
                </Col>
            </Row>
            {modalEditField !== null &&
            <UserEditModal fieldName={modalEditField}
                           fieldType={modalEditField === 'description' ? 'textarea' : 'text'}
                           hide={() => setModalEditField(null)}
                           show={true}
                           {...props}/>
            }
        </>
    );
}

export default EditProfile;