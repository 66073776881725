import request from 'superagent';
import {UserService} from '../services/user_service';
import {Config} from "./config";
import AuthenticationError from "../errors/authentication_error";

let API = {
    get(props) {
        const authToken = UserService.authToken;
        const { host, path, query } = props;
        const url = host ? `${host}${path}` : `${Config.get().apiBase}${path}`;

        return new Promise(function(resolve, reject) {
            request
                .get(url)
                .set('Accept', 'application/json')
                .query(query)
                .set('Authorization', authToken)
                .end(function(err, res) {
                    if (!err && res && res.ok) {
                        resolve(res.body);
                    } else if (res && res.unauthorized) {
                        UserService.logout();
                        window.location.href = '/login';
                    } else if (res && res.error) {
                        reject(res.error);
                    } else {
                        reject('Error');
                    }
                });
        });
    },

    getHtml(props) {
        const authToken = UserService.authToken;
        const { path } = props;
        const url = `${Config.get().apiHost}${path}`;

        return new Promise(function(resolve, reject) {
            request
                .get(url)
                .set('Accept', 'text/html')
                .set('Authorization', authToken)
                .end(function(err, res) {
                    if (!err && res && res.ok && res.text) {
                        resolve(res.text);
                    } else if (res && res.unauthorized) {
                        UserService.logout();
                        window.location.href = '/login';
                    } else if (res && res.error) {
                        reject(res.error);
                    } else {
                        reject('Error');
                    }
                });
        });
    },

    del(url) {
        return new Promise(function(resolve, reject) {
            const authToken = UserService.authToken;

            if (!authToken) {
                reject(new AuthenticationError());
            }

            request
                .del(url)
                .set('Accept', 'application/json')
                .set('Authorization', authToken)
                .end(function(err, res) {
                    if (!err && res && res.ok) {
                        resolve(res.body);
                    } else if (res && res.unauthorized) {
                        UserService.logout();
                        window.location.href = '/login';
                    } else if (res && res.error) {
                        reject(res.error);
                    } else {
                        reject('Error');
                    }
                });
        });
    },

    post(url, params) {
        params = params || {};
        return new Promise(function(resolve, reject) {
            const authToken = UserService.authToken;

            if (!authToken) {
                reject(new AuthenticationError());
            }

            request
                .post(url)
                .send(params)
                .set('Accept', 'application/json')
                .set('Authorization', authToken)
                .set('Content-Type', 'application/json')
                .end(function(err, res) {
                    if (!err && res && res.ok) {
                        resolve(res.body);
                    } else if (res && res.unauthorized) {
                        UserService.logout();
                        window.location.href = '/login';
                    } else if (res && res.text) {
                        try {
                            const errorJSON = JSON.parse(res.text);
                            if (errorJSON.error) {
                                reject(errorJSON.error);
                            } else if (errorJSON.errors) {
                                reject(errorJSON.errors.join('\n'));
                            }
                        } catch {
                            reject('Error');
                        }
                    } else if (res && res.error) {
                        reject(res.error);
                    } else {
                        reject('Error');
                    }
                });
        });
    },

    put(url, params) {
        params = params || {};

        return new Promise(function(resolve, reject) {
            const authToken = UserService.authToken;

            if (!authToken) {
                reject(new AuthenticationError());
            }

            request
                .put(url)
                .send(params)
                .set('Accept', 'application/json')
                .set('Authorization', authToken)
                .set('Content-Type', 'application/json')
                .end(function(err, res) {
                    if (!err && res && res.ok) {
                        resolve(res.body);
                    } else if (res && res.unauthorized) {
                        UserService.logout();
                        window.location.href = '/login';
                    } else if (res && res.text) {
                        try {
                            const errorJSON = JSON.parse(res.text);
                            if (errorJSON.error) {
                                reject(errorJSON.error);
                            } else if (errorJSON.errors) {
                                reject(errorJSON.errors.join('\n'));
                            }
                        } catch {
                            reject('Error');
                        }
                    } else if (res && res.error) {
                        reject(res.error);
                    } else {
                        reject('Error');
                    }
                });
        });
    },
};

export default API;
