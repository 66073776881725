import React, {createContext, useState} from "react";

export const NavContext = createContext("nav");

const NavProvider = ({children}) => {
    const [afterLoginUrl, setAfterLoginUrl] = useState(null);
    const [alertAutoClose, setAlertAutoClose] = useState(null);
    const [alertCancelLabel, setAlertCancelLabel] = useState(null);
    const [alertClasses, setAlertClasses] = useState('');
    const [alertConfirmLabel, setAlertConfirmLabel] = useState(null);
    const [alertConfirmFn, setAlertConfirmFn] = useState(null);
    const [alertMessage, setAlertMessage] = useState('Unsaved changes will be lost');
    const [alertTitle, setAlertTitle] = useState(null);
    const [dirty, setDirty] = useState(false);
    const [showAlertModal, setShowAlertModal] = useState(false);

    const setAlert = (params) => {
        const {autoClose, cancelLabel, classes, confirmFn, confirmLabel, title, message, show} = params;

        setAlertAutoClose(autoClose);
        setAlertCancelLabel(cancelLabel);
        setAlertClasses(classes);
        setAlertConfirmFn(confirmFn);
        setAlertConfirmLabel(confirmLabel);
        setAlertTitle(title);
        setAlertMessage(message);
        setShowAlertModal(show);
    }

    const resetAlert = () => {
        setAlertAutoClose(null);
        setAlertCancelLabel(null);
        setAlertClasses('');
        setAlertConfirmLabel(null);
        setAlertConfirmFn(null);
        setAlertMessage('Unsaved changes will be lost');
        setAlertTitle(null);
        setShowAlertModal(false);
    }

    return (
        <NavContext.Provider value={{
            dirty, setDirty,
            afterLoginUrl, setAfterLoginUrl,
            alertAutoClose, setAlertAutoClose,
            alertCancelLabel, setAlertCancelLabel,
            alertConfirmFn, setAlertConfirmFn,
            alertClasses, setAlertClasses,
            alertConfirmLabel, setAlertConfirmLabel,
            alertMessage, setAlertMessage,
            alertTitle, setAlertTitle,
            showAlertModal, setShowAlertModal,
            setAlert,
            resetAlert
        }}>
            {children}
        </NavContext.Provider>
    )
}

export default NavProvider;