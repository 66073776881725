import {Button} from "react-bootstrap";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlusCircle} from "@fortawesome/free-solid-svg-icons";

const AddButton = (props) => {
    const {classes, clickHandler, isDisabled, label} = props;
    const plusIcon = <FontAwesomeIcon icon={faPlusCircle}/>;
    const buttonText = label || 'Add';

    return (
            <Button variant="success" className={classes}
                    onClick={clickHandler} disabled={isDisabled}>{plusIcon} {buttonText}</Button>
    )
};

export default AddButton;
