import React, {useContext} from 'react';
import {ConfigContext} from "../../utils/config_context";

const ExerciseThumb = (props) => {
    const {exercise, isPlaying, isPreview} = props;
    const config = useContext(ConfigContext);

    let imageSrc = `${config.s3Basepath}/${exercise.thumb_image}`;
    let classes = 'img-responsive w-100';

    if (isPreview) {
        classes += ' preview';
    }
    if (isPlaying) {
        imageSrc = exercise.standard_animation !== null ?
            `${config.s3Basepath}/${exercise.standard_animation}` : "/assets/images/gears-sm.gif";
    }

    return (
        <div>
            <img src={imageSrc} className={classes} alt="Exercise"/>
        </div>
    );
}

export default ExerciseThumb;