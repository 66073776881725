import React, { useContext } from 'react';
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCopy,
    faEllipsis,
    faLink,
    faMinusCircle,
    faGripLines,
    faGripHorizontal,
    faStar
} from '@fortawesome/free-solid-svg-icons'
import { Col, Dropdown, DropdownButton } from 'react-bootstrap';
import ExerciseCard from "../exercises/exercise_card";
import ExerciseGroupImageCarousel from "../exercises/exercise_group_image_carousel";
import ExerciseAnimatedImage from "../exercises/exercise_animated_image";
import ExerciseClipMedia from "../exercises/exercise_clip_media";
import { UserContext } from "../../providers/user_provider";
import PerformUtils from "../../utils/perform_utils";
import Formatter from "../../utils/formatter";

export default function WorkoutExerciseItem(props) {
    const {
        exercise, handleExerciseDuplicateClick,
        handleExerciseDeleteClick,
        handleExerciseClick, handleExerciseSyncButtonClick,
        index, isGiphyView, isSortMode, isSynced, mode,
        showAuthor, showStars, showWorkoutAttrs,
        workout, workoutPerform
    } = props;

    const { currentWorkout, user } = useContext(UserContext);
    let { groupExerciseId } = useParams();

    const star = <span className='pe-1 text-warning'><FontAwesomeIcon icon={faStar} /></span>;
    const groupIcon = <span className='pe-1'><FontAwesomeIcon icon={faGripHorizontal} color="blue" /></span>;
    const showStar = showStars && exercise.is_featured && user?.is_admin;

    const renderDropdown = () => {
        let ellipsisIcon = <span className="ellipsis-icon" style={{ fontSize: '1.5rem' }}><FontAwesomeIcon icon={faEllipsis} /></span>;
        let deleteIcon = <span><FontAwesomeIcon icon={faMinusCircle} /></span>;
        let copyIcon = <span><FontAwesomeIcon icon={faCopy} /></span>;

        return (
            <Col xs={1} className="mt-2 align-self-center ps-0">
                <Dropdown data-bs-theme="dark">
                    <DropdownButton id={`exercise-dropdown-${index}`} title={ellipsisIcon} bsPrefix="sws-dropdown-button">
                        <Dropdown.Item as="button" onClick={() => handleExerciseDeleteClick(index)}>
                            <div className="d-flex justify-content-between"><span>Delete</span>{deleteIcon}</div>
                        </Dropdown.Item>
                        <Dropdown.Item as="button" onClick={() => handleExerciseDuplicateClick(index)}>
                            <div className="d-flex justify-content-between"><span>Duplicate</span>{copyIcon}</div>
                        </Dropdown.Item>
                    </DropdownButton>
                </Dropdown>
            </Col>
        );
    }

    const renderGiphyExercise = (imageComponent, buttons) => {
        return (
            <div className="cursor-pointer" onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                handleExerciseClick(index);
            }}>
                <ExerciseCard imageComponent={imageComponent} index={index} exercise={exercise}>
                    <div className="symbol-card-top-right" style={{ zIndex: '500' }}>
                        {buttons}
                    </div>
                </ExerciseCard>
            </div>
        );
    }

    const renderExerciseRowContents = (imageComponent, buttons) => {

        let workoutAttrs = '';

        if (mode === 'perform') {
            buttons = null;
        } else if (workout?.synced) {
            if (mode === 'edit') {
                if (isSynced) {
                    buttons = renderDropdown();
                } else {
                    buttons = renderSyncButton();
                }
            }
        } else if (['index', 'profile', 'show'].includes(mode) && (groupExerciseId || currentWorkout)) {
            buttons = renderDropdown();
        } else if (mode === 'edit') {
            if (isSortMode) {
                buttons = renderHandlebars();
            } else {
                buttons = renderDropdown();
            }
        }

        if (showWorkoutAttrs && workoutPerform && workoutPerform.exercises && workoutPerform.exercises[index]) {
            if (workout && workout.synced) {
                const startTime = workoutPerform.exercises[index].start;
                if (startTime) {
                    workoutAttrs += Formatter.toHHMMSS(startTime, { showDecimal: true });
                }

            } else if (workoutPerform.timed) {
                const duration = PerformUtils.exerciseDuration(workoutPerform, index);
                if (duration) {
                    workoutAttrs += Formatter.toHHMMSS(duration);
                }
            }

            if (workoutPerform.exercises[index].note) {
                if (workoutAttrs.length) {
                    workoutAttrs += ' ';
                }
                workoutAttrs += workoutPerform.exercises[index].note;
            }
        }

        let title = exercise.title;
        let mainXs = buttons ? 7 : 8;
        let mainSm = 8;
        let mainMd = 9;

        let imageXs = (mode === 'perform') ? 6 : 4;
        let imageSm = (mode === 'perform') ? 4 : 3;
        let imageMd = (mode === 'perform') ? 3 : 2;

        return (
            <>
                <Col xs={imageXs} sm={imageSm} md={imageMd} className="list-image d-flex justify-content-around">
                    {imageComponent}
                </Col>
                <Col xs={mainXs} sm={mainSm} md={mainMd} className="list-item-nuance ps-3">
                    <div className="align-right exercise-title short-label ">
                        {showStar && star}
                        {exercise.is_group && groupIcon}
                        {title}
                    </div>
                    {showWorkoutAttrs && workoutAttrs &&
                        <div>
                            {workoutAttrs}
                        </div>
                    }
                    {showAuthor && !showWorkoutAttrs && exercise.username && (mode !== 'edit') &&
                        <div className="align-right author">
                            by {exercise.username}
                        </div>
                    }
                </Col>
                {buttons}
            </>
        );
    }

    const renderHandlebars = () => {
        let handleIcon = <span><FontAwesomeIcon icon={faGripLines} /></span>;
        return (
            <Col xs={1} className=" align-self-center d-flex justify-content-center">
                <div className="my-auto">
                    {handleIcon}
                </div>
            </Col>
        );
    }

    const renderSyncButton = () => {
        let syncIcon = <span className="sync-icon"><FontAwesomeIcon icon={faLink} /></span>;
        return (
            <Col xs={1} className="right-button-wrapper">
                <div className="my-auto">
                    <Link to="#" onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        handleExerciseSyncButtonClick(index);
                    }}>
                        {syncIcon}
                    </Link>
                </div>
            </Col>
        );
    };

    if (!exercise) return null;

    let imageComponent = (<ExerciseAnimatedImage animate={isGiphyView}
        classes='card-img'
        exercise={exercise}
        onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            handleExerciseClick(index)
        }}
        size="sm" />);
    if (exercise.clip) {
        imageComponent = (
            <ExerciseClipMedia
                {...props}
                mode="show"
                clipMode="image"
                classes="w-100 img-responsive card-img"
                playerShouldBePlaying={false}
            />
        );

    } else if (exercise.is_group) {
        imageComponent = (
            <ExerciseGroupImageCarousel
                {...props}
                handleImageClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    handleExerciseClick(index);
                }}
            />
        );
    }

    let buttons = null;

    if (isGiphyView) {
        return (renderGiphyExercise(imageComponent, buttons));
    } else {
        return (renderExerciseRowContents(imageComponent));
    }
}