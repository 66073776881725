import React, {useContext, useEffect, useState} from 'react';
import {Button, Modal, Form, Col} from 'react-bootstrap';
import ExerciseModalRenderer from "./exercise_modal_renderer";
import GroupExerciseModalRenderer from "./group_exercise_modal_renderer";
import PerformUtils from "../../utils/perform_utils";
import 'react-alice-carousel/lib/alice-carousel.css';
import UserAvatar from '../common/user_avatar';
import {useNavigate} from "react-router-dom";
import {NavContext} from "../../providers/nav_provider";

// Description: https://docs.google.com/document/d/14f4I3X9fU8jHgFpFzj9OfZFTmqjmR06hMDMra_cFwjc/edit
//
// Flow Diagram: https://docs.google.com/drawings/d/1irEcVpbGoQdZfVPfX4_zVbUTk_3U5ry8V5pUDpydhPo/edit

export default function WorkoutExerciseEditModal(props) {
    const {
        exercise, exerciseIndex, handleClearSyncButton, handleCloseModal, handleDeleteButton,
        mode, setWorkout, setWorkoutPerform, showExerciseModal, workout, workoutPerform
    } = props;

    const {setDirty} = useContext(NavContext);

    // When invoked by ExerciseList (part of a workout), props.workoutPerform are manipulated and used as is.
    // They may be saved later when the whole workout is saved.

    // When invoked by TopList, props.workoutPerform are copied to state.localWorkoutPerform where
    // they may be manipulated and saved.

    //     uri:  /workouts/:workoutId/edit
    //           exercisePerform => manipulated before saving
    //           exerciseIndex - the index of the exercise being referenced in workoutPerform

    const [exercisePerform, setExercisePerform] = useState({});

    const navigate = useNavigate();

    useEffect(() => {
        if (exerciseIndex !== null && workoutPerform?.exercises?.[exerciseIndex]) {
            const newExercisePerform = workoutPerform.exercises[exerciseIndex];

            setExercisePerform(newExercisePerform);
        }
        return (() => {
            setExercisePerform(null);
        })

    }, [workoutPerform?.exercises, exerciseIndex]);

    const isFormValid = () => {
        return true;
    };

    const handleDone = () => {
        handleCloseModal();
    };

    const handleDetail = () => {
        navigate(`/exercises/${exercise.id}`);
    };

    const handleSetExercisePerform = (newExercisePerform) => {
        setDirty(true);
        setExercisePerform(newExercisePerform);
    }

    // ToDo: move footer buttons into a props function, passed in by the context
    const renderFooterButtons = () => {
        return (
            <div className="my-0 d-flex w-100 justify-content-between">
                {(mode !== 'edit') &&
                    <Button variant="info" size="sm" onClick={(event) => {
                        handleDetail(event)
                    }}>Detail...</Button>
                }

                {handleDeleteButton &&
                    <Button variant="danger" size="sm" onClick={(event) => {
                        handleDeleteButton(event);
                        handleDone();
                    }}>Delete</Button>
                }

                {handleClearSyncButton && workout && workout.synced &&
                    <Button variant="success" size="sm" onClick={(event) => {
                        handleClearSyncButton(event);
                        handleDone();
                    }}>Clear Sync</Button>
                }

                {workout && !workout.synced &&
                    <Button variant="success" size="sm" onClick={(event) => {
                        handleDone();
                    }}>Cancel</Button>
                }

                <div className="justify-content-end">
                    {renderSaveDoneButton()}
                </div>
            </div>
        );
    };
    const renderSaveDoneButton = () => {
        return (
            <Button variant="primary" size="sm" disabled={!isFormValid()}
                    onClick={handleSaveAndDone}>Done</Button>
        )
    };

    const handleSaveAndDone = () => {
        let newWorkoutPerform = Object.assign({}, workoutPerform);
        if (newWorkoutPerform.exercises && newWorkoutPerform.exercises[exerciseIndex]) {
            newWorkoutPerform.exercises[exerciseIndex] = exercisePerform;
        }
        setWorkoutPerform(newWorkoutPerform);
        if (setWorkout) {
            let newWorkout = Object.assign({}, workout);
            newWorkout.total_secs = PerformUtils.duration(workoutPerform);
            setWorkout(newWorkout);
        }
        handleDone();
    };


    // ToDo: simplify adding exercises to workouts, or provide all options?
    // Since the "+" is the simple way, why not have the exercise modal be more complicated?
    //    But then, you have to handle the exercises => workout add situation.
    //    Should we set the workout added to as the "global context" (/workouts/workoutId/exercises) ?

    if (exercise === null || exercise === undefined) return null;

    return (
        <Modal id="exercise-modal" animation={false} key="exercise-modal-inner" show={showExerciseModal}
               onHide={() => {
                   handleDone()
               }}>
            <Modal.Header>
                <Modal.Title>{exercise.title}</Modal.Title>
                <div className="w-100">
                    <UserAvatar mode={mode} object={exercise}/>
                </div>
                <Button className="btn-close" data-bs-dismiss="modal"
                        onClick={() => {
                            handleDone()
                        }}/>
            </Modal.Header>
            <Modal.Body>
                {exercise.reference_link &&
                    <div className="reference w-100 text-center">
                        Ref: <a href={exercise.reference_link} target="_blank"
                                rel="noreferrer">{exercise.reference_link}</a>
                    </div>
                }
                {!exercise.is_group && <ExerciseModalRenderer
                    {...props}
                    active={showExerciseModal}
                    exercisePerform={exercisePerform}
                    setExercisePerform={handleSetExercisePerform}/>
                }
                {exercise.is_group && <GroupExerciseModalRenderer
                    {...props}
                    active={showExerciseModal}
                    exerciseIndex={exerciseIndex}
                    exercisePerform={exercisePerform}
                    setExercisePerform={handleSetExercisePerform}/>
                }
            </Modal.Body>
            <Modal.Footer>
                {renderFooterButtons()}
            </Modal.Footer>
        </Modal>
    );
}