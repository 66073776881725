import React from 'react';
import {Link, useLocation} from "react-router-dom";

const TopNavLinkWrapper = (props) => {
    const {children, handleClick, link} = props;
    let location = useLocation();
    const active = location.pathname.startsWith(link);
    const classes = (active ? 'nav-active' : 'nav-item') + " flex-column";

    return (
        <li className={classes}>
            <Link to={link} onClick={event => {
                    active && handleClick && handleClick(event)
            }} className='nav-link p-0 m-0'>
                {children}
            </Link>
        </li>
    );
};

export default TopNavLinkWrapper;
