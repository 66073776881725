import SwsVideoPlayer from "./sws_video_player";
import React, {useState} from "react";
import {Button, Col, Row} from 'react-bootstrap';

export default function ClipPlayTest(props) {

    const playlist = [
        'https://www.youtube.com/embed/8LJ3Q3Fsrzs?clip=UgkxEwWnlgduMnmkxDrOBwEABCIoykELUgd6&clipt=EOHtFBivlxU',
        'https://www.youtube.com/embed/YPLopuFxz-0?clip=UgkxlShSHL7mbEbxUPpwUIv7yQnbFrc1GX3Z&amp;clipt=EI-ADxj1sw8',
        'https://www.youtube.com/embed/2Vprklw8cu8?clip=Ugkx-YX4sikfPmfD6UiP2fRZF5a393kYFnAp&amp;clipt=EJjeDhjmhw8'
    ]

    const [playerUrl, setPlayerUrl] = useState(playlist[0]);
    // null, playing, paused, done
    const [playState, setPlayState] = useState(null);
    const [playerShouldBePlaying, setPlayerShouldBePlaying] = useState(false);
    const [totalPlayingSecondsState, setTotalPlayingSecondsState] = useState(0);
    const [clipPlayingSecondsState, setClipPlayingSecondsState] = useState(0);

    // Local variables that are visible from within a closure - react variables are not.
    let totalPlayingSeconds = 0;
    let clipPlayingSeconds = 0;
    let playlistIndex = 0;
    let timer = null;

    function onPlayerBufferEnd() {
    }

    function onPlayerDuration() {
    }

    function onPlayerEnded() {
    }

    function onPlayerPlay() {
    }

    function onPlayerProgress() {
    }

    function onPlayerReady() {
    }

    // This only handles play, not pause currently
    function handlePlayPauseButton(event) {
        event.preventDefault();
        setPlayerShouldBePlaying(true);
        if (timer) {
            clearTimeout(timer);
        }
        // Create a closure that manipulates the playing, advancing of the player based upon user interaction.
        // React variables do not change within this closure, but changes to them may be triggered.
        timer = setInterval(() => {
            if (playState === 'done') return;

            setPlayState('playing');
            clipPlayingSeconds = clipPlayingSeconds + 1;
            totalPlayingSeconds = totalPlayingSeconds + 1;

            if (clipPlayingSeconds > 10) {
                clipPlayingSeconds = 0;
                playlistIndex = playlistIndex + 1;
                if (playlistIndex < playlist.length) {
                    setPlayerUrl(playlist[playlistIndex]);
                } else {
                    setPlayState('done')
                    setPlayerShouldBePlaying(false);
                    clearInterval(timer);
                }
            }

            setClipPlayingSecondsState(clipPlayingSeconds);
            setTotalPlayingSecondsState(totalPlayingSeconds);
        }, 1000);
        return false;
    }

    let playLabel = '';
    switch (playState) {
        case 'playing':
            playLabel = 'Pause';
            break;
        case 'paused':
            playLabel = 'Play';
            break;
        case 'done':
            playLabel = 'Done';
            break;
        default:
            playLabel = 'Play';
    }

    return (
        <div>
            <h2>Clip Player Test</h2>
            <h6>Total Sec: {totalPlayingSecondsState}</h6>
            <h6>Clip Sec: {clipPlayingSecondsState}</h6>
            {(playState === null) &&
            <Button variant="primary" onClick={handlePlayPauseButton}>{playLabel}</Button>
            }
            {(playState === 'done') &&
            <h3>Done</h3>
            }
            {(playState !== 'done') &&
            <Row>
                <Col xs={12} style={{height: '300px'}}>
                    <SwsVideoPlayer
                        classes="react-player"
                        playerOnBufferEnd={onPlayerBufferEnd}
                        playerOnDuration={onPlayerDuration}
                        playerOnEnded={onPlayerEnded}
                        playerOnPlay={onPlayerPlay}
                        playerOnProgress={onPlayerProgress}
                        playerOnReady={onPlayerReady}
                        playerShouldBePlaying={playerShouldBePlaying}
                        playerShouldBeMuted={true}
                        url={playerUrl}
                    />
                </Col>
            </Row>
            }
        </div>
    )
}