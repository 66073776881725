import { Form, Row } from "react-bootstrap";
import React from "react";

const PublicToggle = (props) => {
    const { handleTogglePublic, isPublic, mode } = props;

    return (
        <Form.Group>
            <Row className="public-row">
                <div className="custom-control custom-switch">
                    {['new', 'edit'].includes(mode) &&
                        <>
                            <input type="checkbox"
                                readOnly={mode === 'show'}
                                onChange={handleTogglePublic}
                                className="custom-control-input"
                                checked={isPublic}
                                id="customSwitchPublic"
                            />
                            <label className="custom-control-label" htmlFor="customSwitchPublic">
                                <h5 className="mx-2">Publicly searchable</h5>
                            </label>
                        </>
                    }
                    {mode === 'show' &&
                        <h6>{!isPublic && (<>Not </>)}Publicly searchable</h6>
                    }
                </div>
            </Row>
        </Form.Group>
    )
};

export default PublicToggle;
