import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { ConfigContext } from '../../utils/config_context';
import { Row, Col, Form, Button, Container, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import WorkoutExerciseList from './workout_exercise_list';
import API from "../../utils/api";
import FormDataUtils from "../../utils/form_data_utils";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faChevronLeft, faClipboardList, faCopy, faLink, faPencilAlt, faQuestionCircle, faStopwatch
} from '@fortawesome/free-solid-svg-icons'
import PerformUtils from "../../utils/perform_utils";
import PerformControls from "../perform_controls";
import SwsVideoPlayer from '../players/sws_video_player';
import { faYoutube } from "@fortawesome/free-brands-svg-icons";

import LandingModal from "../modals/landing_modal";
import Information from "../../utils/information";
import PublicToggle from "../common/public_toggle";
import QuestionIcon from "../common/question_icon";
import TagWrapper from "../common/tag_wrapper";
import NavEditButtons from "../nav/nav_edit_buttons";
import WorkoutDescription from "./workout_description";
import TextareaAutosize from 'react-textarea-autosize';
import * as Sentry from "@sentry/browser";
import { NavContext } from "../../providers/nav_provider";

import ReferenceLink from "../common/reference_link";
import YouTubeLogo from "../common/you_tube_logo";
import { UserContext } from "../../providers/user_provider";
import WorkoutVideoSelector from "./workout_video_selector";
import WorkoutExternalMediaList from './workout_external_media_list';

export default function Workout(props) {

    const {
        exercises,
        handleCopy,
        mode,
        setIsPerform,
        setUserWorkouts,
        setWorkout,
        setWorkoutPerform,
        userWorkouts,
        workout,
        workoutImage,
        workoutPerform
    } = props;

    const { dirty, setAfterLoginUrl, setDirty, setAlert } = useContext(NavContext);
    const { setCurrentWorkout, user } = useContext(UserContext);

    let { workoutId } = useParams();
    const config = useContext(ConfigContext);

    // null, blank, landingImage, video
    const [currentVideo, setCurrentVideo] = useState(null);
    const [firstPlay, setFirstPlay] = useState(true);
    const [isPublic, setIsPublic] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [isDeepEditMode, setIsDeepEditMode] = useState(false);
    // Only possible when !workout.synced
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);
    const [isVideoBuffering, setIsVideoBuffering] = useState(false);
    const [lastMainLink, setLastMainLink] = useState(null);
    const [mainPlayerSecs, setMainPlayerSecs] = useState(null);
    const [navigateUrl, setNavigateUrl] = useState(null);
    const [validated, setValidated] = useState(false);
    const [videoPlaylistId, setVideoPlaylistId] = useState(null);

    let navigate = useNavigate();
    let location = useLocation();
    const timed = workout?.workout_type === 'timed';

    useEffect(() => {
        if (workout?.id) {
            setIsPublic(workout.is_public);
            if (['you_tube_video', 'you_tube_playlist'].includes(workout?.workout_type) && workout.exercises.length) {
                const firstExercise = workout.exercises[0];
                if ((firstExercise?.preferred_medium_type === 'yt_video') && firstExercise?.clip?.url) {
                    setCurrentVideo(firstExercise.clip.url);
                } else {
                    setCurrentVideo(null);
                }
            } else {
                setCurrentVideo(null);
            }
        }

    }, [workout])

    useEffect(() => {
        if (navigateUrl) {
            setNavigateUrl(null);
            navigate(navigateUrl);
        }
    }, [navigateUrl, setNavigateUrl, navigate]);

    useEffect(() => {
        let updates = {};

        if (currentVideo && (currentVideo !== lastMainLink)) {
            const urlMatch = currentVideo.match(/youtu.be\/([a-zA-Z0-9]*)/);
            let searchParams = (new URL(currentVideo)).searchParams;

            if (urlMatch && urlMatch[1]) {
                if (searchParams.has('t')) {
                    // ToDo: store start time, for each thing
                    // setMainStart(Number(searchParams.get('t')));
                }
            } else if (currentVideo.match(/youtube.com/)) {
                if (searchParams.has('list')) {
                    const newPlaylistId = searchParams.get('list')
                    if (newPlaylistId && (newPlaylistId !== videoPlaylistId)) {
                        setVideoPlaylistId(searchParams.get('list'));
                    }
                } else {
                    setVideoPlaylistId(null);
                }
            }
            setLastMainLink(currentVideo);
        }

    }, [currentVideo, lastMainLink, videoPlaylistId, workout])

    let workoutFormRef = useRef(null);
    let reactPlayerRef = useRef(null);
    let promptRef = useRef(null);

    let classNames = `top-component workout-${mode}`;

    if (mode === 'new' && !user.id) {
        setAfterLoginUrl('/workouts/new');
        setAlert({
            autoClose: 3000, title: null, message: `Please register or login to create a new workout`, show: true
        });
        navigate("/register");
    }

    if (['edit', 'show', 'new'].includes(mode)) {
        classNames += ' bottom-nav-shown';
    }

    const userCanEdit = user?.id && (user.is_admin || (workout.username === user.username));
    const isOwnWorkout = user?.id && (workout.username == user.username);
    let showEditButton = workout && (userCanEdit || ((workout.id == null) && (mode === 'new')));
    const pencil = <FontAwesomeIcon icon={faPencilAlt} className="pe-1" />;
    const copyIcon = <FontAwesomeIcon icon={faCopy} />;
    const showFeatureButton = workout && user && user.is_admin;
    const chevron = <span className='pe-1'><FontAwesomeIcon icon={faChevronLeft} /></span>;
    const showStartButton = (workout.exercises.length > 0) || (currentVideo !== null);

    const handleAddExercises = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (workout?.id && !dirty) {
            setCurrentWorkout(workout);
            navigate(`/exercises/search`);
            return;
        }
        handleSubmit(event, '/exercises/search');
    };

    const handleAddMedia = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (workout?.id && !dirty) {
            setCurrentWorkout(workout);
            navigate('/external_media/search');
            return;
        }

        handleSubmit(event, '/external_media/search');
    };

    const handleChange = (event) => {
        let newWorkout = Object.assign({}, workout);

        if (event.target.name === 'title') {
            newWorkout['title'] = event.target.value;
        } else if (event.target.name === 'synced_link') {
            newWorkout['video'] = event.target.value;
            newWorkout['total_secs'] = null;
        } else if (event.target.name === 'video') {
            newWorkout['video'] = event.target.value;
        } else if (event.target.name === 'reference_link') {
            newWorkout['reference_link'] = event.target.value;
        } else if (event.target.name === 'reference_text') {
            newWorkout['reference_text'] = event.target.value;
        }

        setDirty(true);
        setWorkout(newWorkout);
    };

    const handleTogglePerformAttr = (attr) => {
        let newWorkoutPerform = Object.assign({}, workoutPerform);
        newWorkoutPerform[attr] = !workoutPerform[attr];

        setWorkoutPerform(newWorkoutPerform);
    };

    const handleWorkoutShare = async (event) => {
        event.preventDefault();

        try {
            const workoutUrl = `${location.origin}/workouts/${workout.id}`;

            await navigator.clipboard.writeText(workoutUrl);
            setAlert({ autoClose: 1500, title: 'Sharing', message: 'Workout URL copied to clipboard', show: true });

        } catch (e) {
            Sentry.captureEvent(e);
        }
    };

    const handleClearAllSyncs = (event) => {
        event.preventDefault();
        event.stopPropagation();

        let newWorkoutPerform = Object.assign({}, workoutPerform);
        newWorkoutPerform.exercises.forEach((e) => {
            if (e && e.start) e.start = undefined;
        });

        setWorkoutPerform(newWorkoutPerform);
    };

    const handleFeatureButton = (event) => {
        event.preventDefault();
        event.stopPropagation();

        handleUpdateWorkout({ is_featured: !workout.is_featured });
    };

    const handleSubmit = (event, afterUpdateUrl) => {
        if (isSaving) return;

        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        const form = workoutFormRef.current;

        if (form.checkValidity() === false) {
            //event.stopPropagation();
            setValidated(true);
            return;
        }

        setIsSaving(true);

        const formWorkoutData = new FormData(form);
        const newTitle = formWorkoutData.get('title')?.replace(/;/g, '');
        if (newTitle) {
            formWorkoutData.set('title', newTitle);
        }
        formWorkoutData.append('is_public', isPublic);

        const exercise_ids = exercises?.map(e => e.id);
        const external_media_ids = workout?.external_media?.map(m => m.id);

        let formObject = Object.assign(FormDataUtils.formDataToObject(formWorkoutData), {
            workout_type: workout.workout_type,
            exercise_ids: exercise_ids,
            external_media_ids: external_media_ids,
            is_featured: workout.is_featured,
            perform: workoutPerform,
            total_secs: workout.total_secs,
            workout_image_id: workoutImage.id
        });
        const workoutJson = JSON.stringify(formObject);

        if (workout.id != null) {

            API.put(`${config.apiBase}/workouts/${workout.id}`, workoutJson)
                .then(result => {
                    const newUserWorkouts = userWorkouts.map((wrkout) => {
                        if (wrkout.id === workout.id) {
                            return result;
                        } else {
                            return wrkout;
                        }
                    });
                    setValidated(false);

                    setUserWorkouts(newUserWorkouts);
                    setIsSaving(false);
                    setDirty(false);
                    setWorkout(result);

                    if (afterUpdateUrl) {
                        setCurrentWorkout(result);
                        setNavigateUrl(afterUpdateUrl);
                    } else {
                        navigate(`/workouts/${result.id}`)
                    }
                })
                .catch(error => {
                    alert(error.message);
                    setIsSaving(false);
                });
            return;
        }

        API.post(`${config.apiBase}/workouts`, workoutJson)
            .then(result => {
                let newUserWorkouts = JSON.parse(JSON.stringify(userWorkouts));
                newUserWorkouts.push(result);

                setIsSaving(false);
                setValidated(false);

                setUserWorkouts(newUserWorkouts);

                setDirty(false);
                setIsSaving(false);
                setWorkout(result);
                if (afterUpdateUrl) {
                    setNavigateUrl(afterUpdateUrl);
                } else {
                    setCurrentWorkout(result);
                    navigate(`/exercises/search`);
                }
            })
            .catch(error => {
                alert(error.message);
                setIsSaving(false);
            });
    };

    const handleSetMediaItems = (items) => {
        let newWorkout = Object.assign({}, workout);
        newWorkout.external_media = items;
        setWorkout(newWorkout);
    };

    const handleTogglePublic = (event) => {
        event.preventDefault();
        if ((mode !== 'edit') && (mode !== 'new')) return;

        setIsPublic(!isPublic);
    };

    const handleToggleSynced = () => {
        if (workout) {
            handleUpdateWorkout({ synced: (!workout.synced || false) });
        }
    };

    const handleUpdateWorkout = (updates) => {
        let newWorkout = Object.assign({}, workout, updates);
        setWorkout(newWorkout);
    };

    const handleUpdateWorkoutPerform = (updates) => {
        let newWorkoutPerform = Object.assign({}, workoutPerform, updates);
        setWorkoutPerform(newWorkoutPerform);
    };

    const renderExercises = () => {
        return (
            <WorkoutExerciseList {...props}
                classes="workout-edit"
                handleAddExercises={handleAddExercises}
                isOwnWorkout={isOwnWorkout}
                mainPlayerSecs={mainPlayerSecs}
                setDirty={setDirty}
                setIsDeepEditMode={setIsDeepEditMode}
                userCanEdit={userCanEdit} />
        );
    };

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            href=""
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
            &#x25bc;
        </a>
    ));

    const renderExternalMediaList = () => {
        return (<WorkoutExternalMediaList
            {...props}
            handleAddMedia={handleAddMedia}
            isOwnWorkout={isOwnWorkout}
            items={workout?.external_media}
            setDirty={setDirty}
            setIsDeepEditMode={setIsDeepEditMode}
            setItems={handleSetMediaItems}
            userCanEdit={userCanEdit}
        />)
    }

    // map workouts to

    const renderEditWorkout = () => {
        return (<>
            <Row className="subheader-row pt-2">
                <Col lg={12}>
                    <Form.Group controlId="formTitleText">
                        <Row>
                            <Col xs={3} className='pt-1'>
                                <Form.Label>
                                    <div className="form-section">
                                        Title
                                    </div>
                                </Form.Label>
                            </Col>
                            <Col xs={9}>
                                <Form.Control required
                                    className="py-1"
                                    type="text"
                                    name="title"
                                    placeholder="Title"
                                    onChange={handleChange}
                                    value={workout.title || ''} />
                                <Form.Control.Feedback type="invalid">
                                    Please set a title
                                </Form.Control.Feedback>
                            </Col>
                        </Row>
                    </Form.Group>
                    {renderEditableWorkoutType()}
                    {workout.synced && <Form.Group>
                        <Row>
                            <Col xs={4} className="ps-3 pt-1">
                                <Form.Label>
                                    YouTube
                                </Form.Label>
                            </Col>
                            <Col xs={8}>
                                <Form.Control required
                                    type="text"
                                    name="synced_link"
                                    placeholder="Video Link"
                                    onChange={handleChange}
                                    value={currentVideo || ''} />
                                <Form.Control.Feedback type="invalid">
                                    Please set a YouTube video
                                </Form.Control.Feedback>
                            </Col>
                        </Row>
                    </Form.Group>}
                    {timed && <>
                        <Row>
                            <Col>
                                <div className="form-section pt-2" style={{ fontWeight: 'bold' }}>Timing Defaults:
                                </div>
                            </Col>
                        </Row>
                        <PerformControls
                            defaults={PerformUtils.systemDefaults()}
                            perform={workoutPerform}
                            readOnly={mode === 'show'}
                            showRoundRest={true}
                            showSets={true}
                            updatePerform={handleUpdateWorkoutPerform}
                        />
                    </>}
                    <Row>
                        <WorkoutDescription
                            handleChange={handleChange}
                            myKey="edit-description-ta"
                            readOnly={false}
                            workout={workout}
                        />
                    </Row>
                    <Form.Group controlId="formBasicText2">
                        <Form.Label>
                            <div className="form-section">Prompt</div>
                        </Form.Label>
                        <TextareaAutosize
                            key="prompt-ta"
                            className="textarea"
                            placeholder="This text will be automatically displayed when others view your workout"
                            defaultValue={workout.prompt}
                            name="prompt"
                            maxLength="512"
                            readOnly={false}
                            style={{ width: '100%', border: 'none' }}
                            ref={promptRef}
                            wrap="soft"
                        />
                    </Form.Group>
                    {!workout.synced && <>
                        <Form.Group controlId="formBasicText5">
                            <Row>
                                <Col xs={10}>
                                    <Form.Control type="text" name="reference_link"
                                        className="py-1"
                                        placeholder="Link URL"
                                        onChange={handleChange}
                                        value={workout.reference_link || ''} />
                                </Col>
                                <Col xs={2} className="ps-0 ms-0">
                                    <QuestionIcon onClick={(event) => {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        setAlert({
                                            title: renderInfoModalTitle('workoutLink'),
                                            message: renderInfoModalBody('workoutLink'),
                                            classes: 'help-modal',
                                            show: true
                                        })
                                    }} />
                                </Col>
                            </Row>
                        </Form.Group>
                        {workout.reference_link && <Form.Group controlId="formBasicText4">
                            <Row>
                                <Col xs={4}>
                                    <Form.Label>
                                        <h6>Link Text</h6>
                                    </Form.Label>
                                </Col>
                                <Col xs={8}>
                                    <Form.Control type="text" name="reference_text"
                                        placeholder="Reference"
                                        onChange={handleChange}
                                        value={workout.reference_text || ''} />
                                </Col>
                            </Row>
                        </Form.Group>}
                    </>}
                    <PublicToggle handleTogglePublic={handleTogglePublic}
                        isPublic={isPublic}
                        mode={mode} />
                    {timed && renderTimedWorkoutSettings()}
                </Col>
            </Row>
        </>);
    };

    const renderEditableWorkoutType = () => {
        const ytIcon = <FontAwesomeIcon icon={faYoutube} color="red" />;
        const clipboardIcon = <FontAwesomeIcon icon={faClipboardList} color="#666" />;
        const stopwatchIcon = <FontAwesomeIcon icon={faStopwatch} color="black" />;
        const linkedIcon = <FontAwesomeIcon icon={faLink} />;

        return (<Form.Group controlId="workoutType">
            <Form.Label>
                <div className="form-section">
                    <Link onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        setAlert({
                            title: renderInfoModalTitle('workoutType'),
                            message: renderInfoModalBody('workoutType'),
                            classes: 'help-modal',
                            show: true
                        })
                    }}>Type</Link>
                </div>
            </Form.Label>
            <Row>
                <TagWrapper tagName="Linked" selected={workout.synced} clickHandler={() => {
                    if (!workout.synced) {
                        handleUpdateWorkout({ synced: true, timed: false });
                        handleUpdateWorkoutPerform({ timed: false });
                    }
                    setValidated(false);
                }}>
                    <YouTubeLogo />
                </TagWrapper>
                <TagWrapper tagName="Timed" selected={workoutPerform.timed} clickHandler={() => {
                    if (!workoutPerform.timed) {
                        handleUpdateWorkout({ synced: false, timed: true })
                        handleUpdateWorkoutPerform({ timed: true });
                    }
                    setValidated(false);
                }}>
                    {stopwatchIcon} Timed
                </TagWrapper>
                <TagWrapper tagName="Manual" selected={!workout.synced && !workoutPerform.timed}
                    clickHandler={() => {
                        handleUpdateWorkoutPerform({ timed: false });
                        handleUpdateWorkout({ timed: false, synced: false })
                        setValidated(false);
                    }}>
                    {clipboardIcon} Manual
                </TagWrapper>
            </Row>
        </Form.Group>);
    };

    const renderInfoModalTitle = (infoModalType) => {
        const modalTitle = (() => {
            switch (infoModalType) {
                case "performDefaults":
                    return "Perform Defaults";
                case "workoutExercises":
                    if (workout.synced) {
                        return "Synced Exercises";
                    } else if (workout.timed) {
                        return "Timed Exercises";
                    } else {
                        return "Exercises";
                    }
                case "workoutLink":
                    return "Workout Link";
                case "workoutType":
                    return "Workout Type";
                default:
                    return null;
            }
        })();

        return (<>
            <FontAwesomeIcon icon={faQuestionCircle} className="pe-1" />
            {modalTitle}
        </>);
    };

    const renderInfoModalBody = (infoModalType) => {
        switch (infoModalType) {
            case 'performDefaults':
                return Information.performDefaults();
            case 'workoutExercises':
                if (workout.synced) {
                    return Information.syncedExercises();
                } else if (workout.timed) {
                    return Information.timedExercises();
                } else {
                    return Information.workoutExercises();
                }
            case 'workoutLink':
                return Information.workoutLink();
            case 'workoutType':
                return Information.workoutTypes();
            default:
                return null;
        }
    };

    const renderShowWorkout = () => {
        if (!workout) return null;

        return (<div className="list-header">
            {workout.description && <WorkoutDescription
                myKey="show-description-ta"
                readOnly={true}
                workout={workout}
            />}
            <ReferenceLink item={workout} />
        </div>);
    };

    const reactPlayerWrapper = (url, classes) => {
        return (<>
            {url && <div className={classes}>
                <SwsVideoPlayer
                    classes="react-player"
                    controls={true}
                    forwardRef={reactPlayerRef}
                    onBufferEnd={playerOnBufferEnd}
                    onDuration={(duration) => {
                        playerOnDuration(duration)
                    }}
                    playerOnEnded={() => setIsVideoPlaying(false)}
                    playerOnPause={() => setIsVideoPlaying(false)}
                    playerOnPlay={() => {
                        if (firstPlay) {
                            setIsVideoPlaying(false);
                            setFirstPlay(false);
                            setMainPlayerSecs(0);
                        } else {
                            setIsVideoPlaying(true);
                        }
                    }}
                    playerOnProgress={reactPlayerOnProgress}
                    playerOnReady={reactPlayerOnReady}
                    playerShouldBePlaying={isVideoPlaying}
                    url={url}
                />
            </div>}
        </>)
    };

    const renderTimedWorkoutSettings = () => {
        if (!workout || !workoutPerform) return null;

        const defaults = PerformUtils.systemDefaults();

        const playSounds = (workoutPerform && workoutPerform.playSounds !== undefined) ? workoutPerform.playSounds : defaults.playSounds;

        return (<Form.Group>
            <Form.Label>
                <div className="form-section">
                    <Link onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        setAlert({
                            title: renderInfoModalTitle('performDefaults'),
                            message: renderInfoModalBody('performDefaults'),
                            classes: 'help-modal',
                            show: true
                        })
                    }}>Perform Defaults</Link>
                </div>
            </Form.Label>
            <Row className="pt-2 ps-1">
                <Col xs={6}>
                    <Form.Group controlId="formBasicCheckbox3">
                        <div className="custom-control custom-switch">
                            <input type="checkbox"
                                onChange={() => {
                                    handleTogglePerformAttr('playSounds')
                                }}
                                className="custom-control-input"
                                checked={playSounds || ""}
                                id="customSwitch3"
                            />
                            <label className="custom-control-label h6" htmlFor="customSwitch3">
                                Bells
                            </label>
                        </div>
                    </Form.Group>
                </Col>
            </Row>
        </Form.Group>);
    };

    const playerOnBufferEnd = () => {
        setIsVideoBuffering(false);
    };

    const playerOnDuration = (duration) => {
        const workoutDuration = duration - PerformUtils.exerciseSyncStart(0, workout, workoutPerform)
        setDirty(false);
        handleUpdateWorkout({ total_secs: workoutDuration });
    };

    const reactPlayerOnProgress = (progress) => {

        if (!isVideoBuffering && progress && progress.playedSeconds) {
            setMainPlayerSecs(progress.playedSeconds);
        }
    };

    const reactPlayerOnReady = () => {
        if (firstPlay && reactPlayerRef && reactPlayerRef.current) {
            let start = PerformUtils.exerciseSyncStart(0, workout, workoutPerform);
            start = start ? start : 0;

            reactPlayerRef.current.seekTo(start);
        }
    };

    const renderBottomNav = () => {

        return (<>
            {(mode === 'show') &&
                <Navbar fixed="bottom" bg="dark" className="justify-content-between tight-padding">
                    <Form id="workout-form" className="d-flex w-100 justify-content-between" inline='true'>
                        <Nav.Link href="#">
                            <Button variant='primary' className="btn btn-sm"
                                onClick={() => navigate(-1)}>{chevron}</Button>
                        </Nav.Link>
                        {user.id && <Nav.Link href="#">
                            <Button variant="info" className="btn btn-sm"
                                onClick={handleCopy}>{copyIcon} Copy</Button>
                        </Nav.Link>}
                        {showEditButton && <Nav.Link href="#">
                            <Button variant="secondary" className="btn btn-sm"
                                onClick={(event) => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    navigate(`/workouts/${workoutId}/edit`);
                                }}>
                                {pencil} Edit
                            </Button>
                        </Nav.Link>}
                        {showStartButton && <Nav.Link href="#">
                            <Button variant="success" className="btn btn-sm"
                                onClick={() => setIsPerform(true)}>Start</Button>
                        </Nav.Link>}
                    </Form>
                </Navbar>}
            {((mode === 'new') || (mode === 'edit')) &&
                <Navbar fixed="bottom" bg={isDeepEditMode ? 'light' : 'dark'} className="justify-content-between">
                    {isSaving &&
                        <div className="nav-link px-2 text-light">
                            Saving...
                        </div>
                    }

                    {!isSaving &&
                        <NavEditButtons
                            {...props}
                            handleAddButton={handleAddExercises}
                            handleFeatureButton={showFeatureButton ? handleFeatureButton : null}
                            handleSave={handleSubmit}
                            isDisabled={isDeepEditMode}
                            isFeatured={workout.is_featured}
                            saveDisabled={isSaving}
                            showEditButton={showEditButton}
                            resourceName="workout"
                            resourceId={workoutId}
                        />
                    }
                </Navbar>
            }
        </>
        )
    }

    const renderModals = () => {
        if (mode === 'show') {
            return (
                <LandingModal item={workout} {...props} />
            )
        }
    }

    if ((workout == null) || ((mode === 'show') && (workout.id == null))) return "";

    // Problem with exercises is that it could be pre-persisted, meaning that workout.exercises != exercises

    // BUT NO, the perform object should be entirely up to date with regard to timings, that was the whole point of
    // the refactor IN THE FIRST PLACE!!!

    // But, perform may be updated before workout is saved...


    /* Only render nav for show, because it has the feature button */
    return (<>
        <Container fluid={true} className={classNames}>
            <Form noValidate ref={workoutFormRef} validated={validated} onSubmit={handleSubmit}>
                <WorkoutVideoSelector
                    workoutImage={workout?.image}
                    {...props}
                />
                <div className='form-section flex-grow-1'>{workout.title}</div>
                {(mode === 'show') && renderShowWorkout()}
                {['new', 'edit'].includes(mode) && renderEditWorkout()}

            </Form>
            {renderExercises()}
            {renderExternalMediaList()}
            {renderBottomNav()}
            {renderModals()}
        </Container>
    </>);

}