import {Button} from "react-bootstrap";
import React from "react";

const TagWrapper = (props) => {
    const {classes, clickHandler, onDelete, selected, tagName} = props;

    let buttonClasses = `tag-name px-1 py-1`;

    if (selected) {
        buttonClasses += " selected";
    } else {
        buttonClasses += " unselected";
    }

    const wrapperClasses = classes ? `${classes} tag-wrapper` : `tag-wrapper`;

    return (
        <div key={`tag-wrapper-${tagName}`} className={wrapperClasses}>
            <Button key={`tag-${tagName}`} variant="sm" className={buttonClasses} onClick={clickHandler}>
                {props.children}
            </Button>
        </div>
    )
};

export default TagWrapper;
